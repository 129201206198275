/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React base styles
// Material Dashboard 2 PRO React helper functions

const stepLabel = {
  styleOverrides: {
    label: {

      maxWidth:'100%'
      // marginTop: `${pxToRem(8)} !important`,
      // fontWeight: fontWeightRegular,
      // fontSize: size.xs,
      // color: "#9fc9ff",
      // textTransform: "uppercase",
      // "&.Mui-active": {
      //   fontWeight: `${fontWeightRegular} !important`,
      //   color: `${rgba(white.main, 0.8)} !important`,
      // },
      // "&.Mui-completed": {
      //   fontWeight: `${fontWeightRegular} !important`,
      //   color: `${rgba(white.main, 0.8)} !important`,
      // },
    },
  },
};

export default stepLabel;
