import React from "react"
import { Box, Card, CardActionArea } from "@mui/material"
import MDTypography from "../../../components/MDTypography"
import PaidIcon from "@mui/icons-material/Paid"

const DiscussionSuggestionsCard = ({ title, amount, isAddition, onClick }) => {
  return (
    <Card
      sx={{ width: "100%", cursor: "pointer", height: "100%" }}
      onClick={onClick}
    >
      <CardActionArea sx={{ height: "100%", padding: 1 }}>
        <Box display="flex" flexDirection="column" alignItems="flex-start" overflow={'auto'} justifyContent={"flex-start"}
             sx={{ height: "100%", paddingY:1}}>
          <MDTypography variant="h5" color={isAddition ? "primary" : "error"} display={"flex"} justifyContent={"center"} alignItems={"center"}
                        gap={1} sx={{ textAlign: "center", width: "100%" }}>
            {amount}
            <PaidIcon color="primary" sx={{ marginBottom: 0.2 }} />
          </MDTypography>
          <MDTypography variant="h6" color="textPrimary" fontSize={"0.9rem"}
                        sx={{ textAlign: 'center', width: "100%" }}
          >
            {title}
          </MDTypography>
        </Box>
      </CardActionArea>
    </Card>
  )
}

export default DiscussionSuggestionsCard
