import { Form, Formik } from "formik"
import { Box } from "@mui/material"
import FormikInput from "../../../components/FormikInput"
import DataTable from "../../../components/DataTable"
import React, { useEffect, useRef, useState } from "react"
import { useApi, useDebouncedInput } from "../../../services/helpers"
import MDBox from "../../../components/MDBox"
import MDButton from "../../../components/MDButton"

const dataTableModel = {
  columns: [
    { Header: "Course", accessor: "course.name", disableOrdering: true },
    { Header: "Lesson", accessor: "name", disableOrdering: true },
    { Header: "# Questions", accessor: "questions", disableOrdering: true },
    { Header: "# Tasks", accessor: "task_groups", disableOrdering: true },
    { Header: "", accessor: "actions", disableOrdering: true },
  ],
  rows: [],
}

const lessonRowActions = (item, selectLessonToImport) => {
  return (
    <MDBox display={"flex"}>
      <MDButton color="primary"
                onClick={() => {
                  selectLessonToImport(item)
                }}>
        Import
      </MDButton>
    </MDBox>
  )
}

const renderLessonTableRow = (item, selectLessonToImport) => {
  item.actions = (lessonRowActions(item, selectLessonToImport))
  return item
}

const LessonBankList = ({ selectedLessonId, selectLessonToImport }) => {
  const api = useApi()
  const formikRef = useRef()
  const [search, setSearchDebounced] = useDebouncedInput({ debounceTime: 300 })
  const [courseId, setCourseId] = useState(null)
  const [courses, setCourses] = useState([])
  const [datatable, setDatatable] = useState({ ...dataTableModel })

  const getTeacherCourses = () => {
    api.getTeacherCourses().handle({
      onSuccess: (res) => {
        setCourses(res.data)
      },
      errorMessage: "Error getting courses",
    })
  }

  const searchTeacherLessons = (courseId, search) => {
    const params = {
      course: courseId,
      search: search,
    }
    api.searchLessons(params).handle({
      onSuccess: (response) => {
        setDatatable({
          ...dataTableModel,
          rows: response.data.map((item) =>
            renderLessonTableRow(item, selectLessonToImport),
          ),
        })
      },
      errorMessage: "Error retrieving lessons.",
    })


  }
  //
  useEffect(() => {
    getTeacherCourses()
  }, [])
  useEffect(() => {
    searchTeacherLessons(courseId, search)
  }, [search, courseId])
  //
  return (
    <Formik
      initialValues={{ search: "", course: "", lesson: "" }}
      innerRef={formikRef}
      validateOnBlur={false}
      checked={false}
    >
      {({ values, errors, handleSubmit, setFieldValue, isValid, dirty, resetForm }) => {
        return (
          <Form style={{ width: "100%", height: "100%" }}>
            <Box display={"flex"} flexDirection={"row"} flexWrap="wrap" mt={1} gap={1} justifyContent={"space-between"}
                 alignItems={"center"} padding={1}>
              <FormikInput
                name={"search"}
                onChange={(ev) => {
                  setSearchDebounced(ev.target.value)
                }}
                label={"Search"}
                width={{ xs: "100%", md: "32%" }}
              />
              <FormikInput
                type={"autocomplete"}
                fieldName={"course"}
                options={courses}
                accessKey={"name"}
                onChange={(course) => {
                  setCourseId(!!course?.id ? course.id : null)
                }}
                width={{ xs: "45%", md: "32%" }}
                label={"Filter by Course"}

              />
            </Box>
            <Box display={"flex"} mt={1} overflow={"scroll"} maxHeight={"70vh"}>
              <DataTable
                table={datatable}
              />
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

export default LessonBankList