import Draggable from "react-draggable";
import {Resizable} from "re-resizable";
import {ButtonGroup, IconButton, Typography} from "@mui/material";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import MDBox from "../../components/MDBox";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import QRCode from "react-qr-code";
import scan_me from "../../assets/images/scan_me.png";
import React, {useState} from "react";
import {observer} from "mobx-react";
import {useStores} from "../../models";
import Button from "@mui/material/Button";


const MeetingDisplayToolbar = observer(({}) => {
    const rootStore = useStores();
    const { qrX, qrY } = rootStore;

    const [isBubble, setIsBubble] = useState(true);
    const [mySize, setMySize] = useState({width: 320, height: 320});
    const [isAnimating, setIsAnimating] = useState(false);
    const [dragDisabled, setDragDisabled] = useState(false);
    const [showScanMe, setShowScanMe] = useState(true);

    const qrAnimation = 'width 0.1s linear, height 0.1s linear'
    //const qrAnimation = 'width 0.1s ease-in-out, height 0.1s ease-in-out'

    const onDragStop = (evt, { x, y }) => {
        rootStore?.setQRPosition(x, y);
    };


    return (
        <Draggable
            axis="both"
            bounds="body"
            handle=".handle"
            defaultPosition={{x: 200, y: 200}}
            // position={null}
            grid={[5, 5]}
            scale={1}
            disabled={dragDisabled}
        >
                        <MDBox
                            bgColor={"#6A994E"}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderTopLeftRadius: "30px",
                                borderTopRightRadius: "30px",
                                height: "60px",
                                width: "100%",
                            }}
                            className="handle"
                            >
                            <Typography color="white.main" fontWeight="bold" textOverflow={"ellipsis"}
                                                        overflow={"hidden"}>
                                                ATTENDANCE
                                            </Typography>
            <ButtonGroup variant="contained" aria-label="Basic button group">
                <Button>One</Button>
                <Button>Two</Button>
                <Button>Three</Button>
            </ButtonGroup>
                        </MDBox>
            {/*<Resizable*/}
            {/*    lockAspectRatio={true}*/}
            {/*    onResizeStart={onResizeStart}*/}
            {/*    onResizeStop={onResizeStop}*/}
            {/*    onResize={onResize}*/}
            {/*    {...qrProps[isBubble]}*/}
            {/*>*/}
            {/*    {isBubble ?*/}

            {/*        <IconButton*/}
            {/*            style={{*/}
            {/*                margin: '5px',*/}
            {/*                color: 'white'*/}
            {/*            }}*/}
            {/*            size={"large"}*/}
            {/*            onClick={() => {*/}
            {/*                setIsBubble(false)*/}
            {/*                setIsAnimating(true)*/}
            {/*                setTimeout(() => {*/}
            {/*                    setIsAnimating(false)*/}
            {/*                }, 200)*/}
            {/*            }}*/}

            {/*        >*/}
            {/*            <WorkspacePremiumIcon/>*/}
            {/*        </IconButton> :*/}
            {/*        isAnimating ? null : (<>*/}
            {/*            <MDBox*/}
            {/*                bgColor={"#6A994E"}*/}
            {/*                sx={{*/}
            {/*                    display: "flex",*/}
            {/*                    alignItems: "center",*/}
            {/*                    justifyContent: "center",*/}
            {/*                    borderTopLeftRadius: "30px",*/}
            {/*                    borderTopRightRadius: "30px",*/}
            {/*                    height: "60px",*/}
            {/*                    width: "100%",*/}
            {/*                }}*/}
            {/*                className="handle"*/}
            {/*            >*/}
            {/*                <Typography color="white.main" fontWeight="bold" textOverflow={"ellipsis"}*/}
            {/*                            overflow={"hidden"}>*/}
            {/*                    ATTENDANCE*/}
            {/*                </Typography>*/}
            {/*                {showScanMe && <IconButton*/}
            {/*                    style={{*/}
            {/*                        margin: '5px',*/}
            {/*                        color: 'white',*/}
            {/*                        position: "absolute",*/}
            {/*                        right: 0,*/}
            {/*                        top: 0,*/}
            {/*                    }}*/}
            {/*                    size={"large"}*/}
            {/*                    onClick={() => setIsBubble(true)}*/}
            {/*                >*/}
            {/*                    <RemoveCircleOutlineIcon/>*/}
            {/*                </IconButton>}*/}
            {/*            </MDBox>*/}
            {/*            <MDBox display={"flex"} alignItems={"center"} justifyContent={"center"} height={"100%"}*/}
            {/*                   flexDirection={"column"} style={{*/}
            {/*                padding: "10px",*/}
            {/*            }} className="handle">*/}
            {/*                hola*/}
            {/*                {showScanMe && <img src={scan_me} alt="scan_me" style={{*/}
            {/*                    // width: "70%",*/}
            {/*                    objectFit: "cover",*/}
            {/*                    minHeight: "90px",*/}
            {/*                    paddingBottom: "60px",*/}
            {/*                    margin: "5px",*/}
            {/*                    height: "30%",*/}
            {/*                }}/>}*/}
            {/*            </MDBox></>)}*/}
            {/*</Resizable>*/}
        </Draggable>
    )
})

export default MeetingDisplayToolbar;
