
import home from "../../assets/icons/dashboard.svg";
import {useNavigate} from "react-router-dom";


// const navigate = useNavigate();

const courseRoutes = [
  {
    name: "Home page",
    key: "home",
  },
  {
    name: "Profile",
    key: "profile",
  },
  {
    name: "Lessons\n",
    key: "edit",
  },
  {
    name: "Add meeting\n",
    key: "calendar",
  },
  {
    name: "Students\n",
    key: "enrolment",
  },
  {
    name: "Course history\n",
    key: "history",
  },
];

export {courseRoutes};


