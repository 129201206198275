import { useNavigate } from "react-router-dom";

// Material Dashboard 2 PRO React components

import * as Yup from "yup";
import { useApi } from "../../services/helpers";
// Image
import pxToRem from "../../assets/theme/functions/pxToRem";
import { ROUTES } from "../../services/constants";

import { useRef, useState } from "react";

import { Form, Formik } from "formik";
import FormikInput from "../../components/FormikInput";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

const ForgotPasswordForm = () => {
  const formikRef = useRef();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const api = useApi();

  const forgotPassword = (data) => {
    setLoading(true);
    api.forgotPassword(data.email).handle({
      onSuccess: (_result) => {
        navigate(ROUTES.LOGIN);
      },
      successMessage: "Password reset e-mail has been sent.",
      errorMessage: "Error recovering password",
      onFinally: () => setLoading(false),
    });
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
  });

  const initialValues = {
    email: "",
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={forgotPassword}
    >
      {({ errors, isValid, dirty, handleSubmit }) => (
        <Form style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <FormikInput name="email" label="Email" type="email" errors={errors} />
          <MDBox mt={5} mb={1} textAlign="center" display="flex" gap={3} mx={3} flexDirection="row">
            <MDButton
              variant="contained"
              color="white"
              onClick={(e) => {
                e.preventDefault();
                navigate(ROUTES.LOGIN);
              }}
              sx={{ width: pxToRem(280) }}
            >
              Cancel
            </MDButton>
            <MDButton
              variant="contained"
              color="primary"
              loading={loading}
              disabled={loading || !isValid || !dirty}
              onClick={() => handleSubmit}
              sx={{ width: pxToRem(280) }}
              type="submit"
            >
              Send
            </MDButton>
          </MDBox>
        </Form>
      )}
    </Formik>
  );
};
export default ForgotPasswordForm;
