import * as React from "react";

import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import math from "../../assets/images/math.png";
import { Box, Grid, IconButton } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import clock from "../../assets/icons/clock.svg";

import { useNavigate } from "react-router-dom";

const ProductCard = ({ course }) => {
  const navigate = useNavigate();
  return (
    <Card
      sx={{ height: "275px", cursor: "pointer", "&:hover": { boxShadow: 4 } }}
      onClick={() => navigate("1")}
    >
      <CardMedia component="img" height="194px" image={math} alt="Paella dish" sx={{ margin: 0 }} />
      <CardContent sx={{ px: 0 }}>
        <Grid container>
          <Grid item xs={6} pl={2}>
            <Typography
              variant="body2"
              color={(theme) => theme.palette.primary.main}
              fontWeight="bold"
            >
              Math
            </Typography>
          </Grid>
          <Grid item xs={6} pr={2}>
            <Typography
              textAlign="right"
              variant="body2"
              color={(theme) => theme.palette.primary.main}
            >
              Virtual
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" px={1} flexDirection="row" justifyContent="flex-end">
              <IconButton>
                <img src={clock} alt="clock" />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export const AddCourseCard = () => {
  const navigate = useNavigate();
  return (
    <Card
      onClick={() => navigate("new")}
      sx={{
        height: "275px",
        cursor: "pointer",
        "&:hover": { boxShadow: 4 },
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          height: "100%",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AddRoundedIcon
          fontSize="100"
          sx={{
            fontSize: "100px",
            color: "rgba(28, 39, 76, 0.25)",
          }}
        />
      </CardContent>
    </Card>
  );
};
export default ProductCard;
