import React from "react";
import { observer } from "mobx-react";

import MDBox from "../../components/MDBox";
import SignupForm from "./signup-form";
import { Container } from "@mui/material";
import MDTypography from "components/MDTypography";
import bgImage from '../../assets/images/background/background.png';
const sideBar = require("../../assets/images/background/sidebar.png");
const logo = require("../../assets/images/logos/logo.png");

function SignUp() {
  return (
    <MDBox
      sx={{
        overflowX: "hidden",
        overflowY: "hidden",
        justifyItems: "center",
      }}
    >
      <MDBox
        style={{
          width: "100%",
          height: "100%",
          position: "fixed",
          zIndex: -1,
        }}
      >
        <img
          alt="background"
          src={sideBar}
          style={{ height: "100vh", width: "10%", objectFit: "cover" }}
        />
        <img alt="side" src={bgImage} style={{ height: "100vh", width: "90%" }} />
      </MDBox>
      <Container
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <MDBox
          display="flex"
          flexDirection="column"
          maxHeight="90vh"
          bgColor="white"
          p={4}
          borderRadius="xl"
          sx={{
            width:{xs:'100%', sm:'442px'},
            margin: "auto",
              overflowX: 'auto',
            backgroundColor: "white",
          }}
        >
          <img
            src={logo}
            style={{ display: "block", margin: "auto", marginBottom: 25 }}
            alt="logo"
          />
          <MDTypography color="primary" fontSize={{xs:'20px', md:"24px"}} variant="h4" fontWeight="bold" textAlign="center" mb={1}>
            Create your account
          </MDTypography>
          <SignupForm />
        </MDBox>
      </Container>
    </MDBox>
  );
}

export default observer(SignUp);
