import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import VideoCallIcon from '@mui/icons-material/VideoCall'
import { Box, Grid, IconButton } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import React from 'react'
import editIcon from '../../../assets/icons/edit.svg'
import MDButton from '../../../components/MDButton'

export const LessonItemHeader = ({
  lesson,
  handleEdit,
  setSelectedLesson,
  setShowInstantMeetingModal,
  hiddenXs,
  setCollapsed,
  collapsed,
}) => {
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={'space-between'}
        my={1}
        borderBottom="1px solid rgba(0, 0, 0, 0.25)"
        width={'100%'}
      >
        <Grid
          container
          alignItems={'center'}
          justifyContent={'space-between'}
          wrap={'nowrap'}
          paddingBottom={1}
        >
          <Grid item container xs={5} sm={4} md={4} direction="row" wrap={'nowrap'}>
            <MDTypography
              display={'content'}
              sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              fontSize={{ xs: '24px', md: '30px' }}
              variant="h3"
              color="primary"
              fontWeight="regular"
              title={lesson.name}
            >
              {lesson.name}
            </MDTypography>
            <IconButton onClick={handleEdit} color="primary">
              <MDBox component={'img'} src={editIcon} alt="edit" />
            </IconButton>
          </Grid>
          <Grid
            item
            container
            xs={6}
            sm={3}
            md={7}
            direction="row"
            justifyContent="end"
            wrap="nowrap"
            alignItems={'center'}
            gap={1}
          >
            <MDButton
              variant="contained"
              onClick={() => {
                setSelectedLesson(lesson)
                setShowInstantMeetingModal(true)
              }}
              minWidthButton={{ xs: '50px', md: '155px' }}
              sx={{
                boxShadow: 2,
                '&:hover': { boxShadow: 3 },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {hiddenXs && <VideoCallIcon />}
              {!hiddenXs && (
                <MDBox color="secondary" display={'flex'} flexWrap={'nowrap'} alignItems={'center'}>
                  <MDTypography
                    sx={{ textWrap: 'nowrap', width: '120px' }}
                    variant="caption"
                    color="secondary"
                    fontWeight="bold"
                  >
                    Instant meeting
                  </MDTypography>
                  <VideoCallIcon />
                </MDBox>
              )}
            </MDButton>
            <IconButton onClick={() => setCollapsed(!collapsed)}>
              {collapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <MDTypography
        flex={1}
        fontSize={{ xs: '18px', md: '20px' }}
        variant="h5"
        color="secondary"
        fontWeight="regular"
      >
        {lesson.description}
      </MDTypography>
    </>
  )
}
