import { Box, Button, Card, Grid, Typography } from '@mui/material'
import { QuestionStats } from './charts'
import PaidIcon from '@mui/icons-material/Paid'
import paperPlane from '../../assets/icons/PaperPlaneTilt.svg'
import React, { useEffect } from 'react'
import { useApi } from '../../services/helpers'
import { useParams } from 'react-router-dom'
import { MathJax } from 'better-react-mathjax'
import MDTypography from '../../components/MDTypography'
import moment from 'moment'

export const QuestionsTab = ({ questions, stats }) => {
  const { courseId, meetingId } = useParams()
  const api = useApi()

  const askQuestion = (question) => {
    api.askQuestion(meetingId, question.id).handle({
      successMessage: 'Question sent!',
      errorMessage: 'Error sending question',
    })
  }

  const statts = stats?.understandings || []

  return (
    <Grid container mt={3}>
      <Grid container item xs={12} sm={10} md={4} lg={3} direction="column" wrap={'nowrap'}>
        <Grid item flexBasis={'0 !important'} xs={12}>
          <Typography color="primary" fontWeight="bold">
            Completed Questions
          </Typography>
        </Grid>
        <Grid item xs={12} py={2}>
          {statts?.map((stat, index) => {
            const date = moment(stat.created)
            //
            return (
              <QuestionStats
                heigth={'100px'}
                key={stat.id}
                title={`${statts.length - index}. ${stat.prompt} (${date.fromNow()})`}
                data={stat}
              />
            )
          })}
        </Grid>
      </Grid>

      <Grid container item xs={12} md={8} direction={'column'} wrap={'nowrap'}>
        <Grid
          item
          flexBasis={'0 !important'}
          xs={12}
          sx={{ borderLeft: { xs: 'none', md: '1px solid rgba(0, 0, 0, 0.25)' } }}
        >
          <Typography color="primary" fontWeight="bold" ml={2}>
            Questionnaire
          </Typography>
        </Grid>
        <Grid
          container
          item
          direction={'row'}
          xs={12}
          py={2}
          sx={{ borderLeft: { xs: 'none', md: '1px solid rgba(0, 0, 0, 0.25)' } }}
          alignContent={'flex-start'}
        >
          {questions?.map((question, index) => (
            <Grid key={question.id} item xs={12} px={2} py={2} md={4} minHeight={'200px'}>
              <Card sx={{ p: 3, height: '100%', justifyContent: 'space-between' }}>
                <MDTypography fontSize={{ xs: '16px', md: '20px' }} color={'#000000DE'}>
                  <MathJax>{`${index + 1}. ${question.prompt}`}</MathJax>
                </MDTypography>
                <Box
                  display="flex"
                  flexDirection="row"
                  flexWrap="nowrap"
                  alignItems="center"
                  borderTop="1px solid lightgray"
                  pt={2}
                  justifyContent={'space-between'}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    flexWrap="nowrap"
                    alignItems="center"
                    justifyContent={'space-between'}
                    // mt={1}
                    // pt={1}
                    gap={1}
                  >
                    <Typography fontSize={{ xs: '16px', md: '20px' }}>
                      {question.reward || question.max_reward}
                    </Typography>
                    <PaidIcon color="primary" />
                  </Box>
                  <Box display="flex" flex={1} ml={1} alignItems="center" justifyContent={'end'}>
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      onClick={() => askQuestion(question)}
                    >
                      <img src={paperPlane} alt={'sent'} />
                    </Button>
                  </Box>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}
