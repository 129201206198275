/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import { useMaterialUIController } from "context";

export default styled(Drawer)(({ theme, ownerState }) => {
  const { boxShadows, transitions, breakpoints, functions } = theme;
  const { transparentSidenav, miniSidenav } = ownerState;
  const [controller] = useMaterialUIController();
  const sidebarWidth = 105;
  const sidebarWidthMobile = 105;
  const { xxl } = boxShadows;
  const { pxToRem } = functions;
console.log(breakpoints.down(765))
  let backgroundValue = controller.layout === "student" ? "#386641" : "#2F3E46";

  // styles for the sidenav when miniSidenav={false}
  const drawerOpenStyles = () => ({
    [breakpoints.up(764)]:{
     
      background: backgroundValue,
      borderRadius: 0,
      margin: 0,
      height: "100vh",
      width: sidebarWidth,
      transform: "translateX(0)",
      transition: transitions.create(["transform", 'width'], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.shorter,
      })},
  
      [breakpoints.down(765)]: {
        zIndex: 2,
        background: backgroundValue,
        boxShadow: transparentSidenav ? "none" : xxl,
        left: "0",
        margin: 0,
        borderRadius: 0,
        height: "100vh",
        width: sidebarWidthMobile,
        transform: "translateX(0)",
        transition: transitions.create(["width", "background-color"], {
          easing: transitions.easing.sharp,
          duration: transitions.duration.enteringScreen,
        }),
      },
    });

  // styles for the sidenav when miniSidenav={true}
  const drawerCloseStyles = () => ({
    background: backgroundValue,
    height: "100vh",
    borderRadius: 0,
    margin: 0,
    transform: `translateX(${pxToRem(-320)})`,
    transition: transitions.create("transform", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up("xl")]: {
      boxShadow: transparentSidenav ? "none" : xxl,
      left: "0",
      width: pxToRem(96),
      overflowX: "hidden",
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.shorter,
      }),
    },
  });

  return {
    "& .MuiDrawer-paper": {
      boxShadow: xxl,
      border: "none",

      ...(miniSidenav ? drawerCloseStyles() : drawerOpenStyles()),
    },
  };
});
