import {Box, Icon} from "@mui/material";
import MDBox from "../../components/MDBox";
import React, {useRef, useState} from "react";
import {Fullscreen, FullscreenExit, Launch, ScreenRotation} from "@mui/icons-material";
import {resizeViewPort} from "../../services/helpers";
import {useNavigate} from "react-router-dom";


export const LaptopFrame = ({ children, toggleOrientation, orientation }) => {
    const windowSize  = orientation ?
        {'maxHeight':'680px', 'maxWidth':'500px'} : //portrait
        {'maxHeight':'400px', 'maxWidth':'700px'} //landscape
    const [useInsideWindow, setUseInsideWindow] = useState(true)
    const upDivRef = useRef(null);
    const downDivRef = useRef(null);
    const isPopup = (window.opener && window.opener !== window)
    const navigate = useNavigate();


    const minSize = (portrait) => {
        if (portrait) {
            return [500, 680]
            // return {'height':'600px', 'width':'500px'}
        }else{
            return [600, 400]
            // return {'height':'400px', 'width':'600px'}
        }
    }

    const resizeWindow = () => {
    console.log('resizeWindow')
        const contentWidth = downDivRef.current.clientWidth
        const contentHeight = downDivRef.current.clientHeight + upDivRef.current.clientHeight
        console.log('rz', contentWidth, contentHeight)
        if (isPopup) {
            resizeViewPort(contentWidth, contentHeight)
        }else{
            window.open(
                window.location.href,
                '_blank',
                `width=${contentWidth},height=${contentHeight},popup=yes,scrollbars=no`
            )
        }
    }

    const convertToPopup = () => {
        window.open(
            window.location.href,
            '_blank',
            `width=${windowSize.maxWidth},height=${windowSize.maxHeight},popup=yes,scrollbars=no`
        )
        navigate('/')
    }

    const makeRoom = () => {
        if (!isPopup) return

        const viewport = [window.innerWidth, window.innerHeight]

        const newMinSize = minSize(!orientation)

        const newWidth = Math.max(viewport[0], newMinSize[0])
        const newHeight = Math.max(viewport[1], newMinSize[1])

        resizeViewPort(newWidth, newHeight)
    }

    return <Box width={'100%'} height={'100vh'} minWidth={'500px'} minHeight={'400px'} display={'flex'} justifyContent={'center'}  alignItems={'center'} flexDirection={'column'}>
        <Box
            {...( useInsideWindow ? windowSize: {})}
            {...styles.toolbarContainer}
            ref={upDivRef}
        >
            <MDBox
                sx={styles.iconButtons}
                onClick={() => {
                    makeRoom()
                    toggleOrientation()
                }}
            >
                <ScreenRotation />
            </MDBox>

            <MDBox
                sx={styles.iconButtons}
                 onClick={() => setUseInsideWindow(!useInsideWindow)}
            >
                <Fullscreen />
            </MDBox>

            {(useInsideWindow && isPopup) && <MDBox
                sx={styles.iconButtons}
                 onClick={resizeWindow}
            >
                <FullscreenExit />
            </MDBox>}

            {!isPopup && <MDBox
                sx={styles.iconButtons}
                onClick={convertToPopup}
            >
                <Launch />
            </MDBox>}

        </Box>
        <Box
            width={'100%'}
            height={'100%'}
            {...( useInsideWindow ? windowSize: {})}
            ref={downDivRef}
        >
        {children}
        </Box>
    </Box>
}


const styles = {
    toolbarContainer: {
        flexDirection:'row',
        display:'flex',
        width: '100%',
        justifyContent:'end',
        padding: '5px',
        paddingBottom: '10px',
        borderBottom:"1px solid gray"
    },
    iconButtons: {
        boxShadow: 2,
        backgroundColor: "#fff",
        width: "40px",
        height: "40px",
        p: "8px",
        borderRadius: "50%",
        marginLeft: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    }
}
