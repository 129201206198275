import React from "react";
import { observer } from "mobx-react";

import MDBox from "../../components/MDBox";
import ForgotPasswordForm from "./forgot-password-form";
import { Container } from "@mui/material";
import MDTypography from "components/MDTypography";
import bgImage from '../../assets/images/background/background.png';
const sideBar = require("../../assets/images/background/sidebar.png");
const logo = require("../../assets/images/logos/logo.png");

function ForgotPassword() {
  return (
    <MDBox
      sx={{
        overflowX: "hidden",
        overflowY: "hidden",
        justifyItems: "center",
      }}
    >
      <MDBox
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: -1,
        }}
      >
        <img
          alt="background"
          src={sideBar}
          style={{ height: "100vh", width: "10%", objectFit: "cover" }}
        />

        <img alt="side" src={bgImage} style={{ height: "100vh", width: "90%" }} />
      </MDBox>
      <Container
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <MDBox
          // maxWidth="332px"
          bgColor="white"
          p={4}
          borderRadius="xl"
          sx={{
            width:{xs:'100%', sm:'442px'},
            margin: "auto",
            backgroundColor: "white",
          }}
        >
          <img
            src={logo}
            style={{ display: "block", margin: "auto", marginBottom: 50 }}
            alt="logo"
          />
          <MDTypography color="primary" variant="h4" fontWeight="bold" textAlign="center" mb={4}>
            Recover your account
          </MDTypography>
          <MDTypography variant="body2" textAlign="center" color="black" mb={4}>
            Enter your email to recover your account.
          </MDTypography>

          <ForgotPasswordForm />
        </MDBox>
      </Container>
    </MDBox>
  );
}

export default observer(ForgotPassword);
