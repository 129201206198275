import mixpanel from 'mixpanel-browser';
mixpanel.init('5cd25213defaec080e18070f55fa7a14', {
    track_pageview: true,
    persistence: "localStorage",
});

let env_check = process.env.NODE_ENV === 'production';

let actions = {
    identify: (id) => {
        if (env_check) mixpanel.identify(id);
    },
    alias: (id) => {
        if (env_check) mixpanel.alias(id);
    },
    track: (name, props) => {
        if (env_check) mixpanel.track(name, props);
    },
    people: {
        set: (props) => {
            if (env_check) mixpanel.people.set(props);
        },
    },
    reset: () => {
        if (env_check) mixpanel.reset();
    },
};

export const setupMixpanelOnRootStore = (rootStore) => {
    const loginStore = rootStore.loginStore;
    if (loginStore.id){
        actions.identify(loginStore.id);
        actions.people.set({
            $email: loginStore.email,
            $name: loginStore.name,
            $teacher: !! loginStore.teacher,
            $student: !! loginStore.student,
            // $last_login: new Date(),
        });
    }else{
        actions.reset();
    }
}

export const mixpanelLogin = (loginStore) => {
    if (loginStore.id){
        actions.identify(loginStore.id);
        actions.people.set({
            $email: loginStore.email,
            $name: loginStore.name,
            $teacher: !! loginStore.teacher,
            $student: !! loginStore.student,
            // $last_login: new Date(),
        });
    }else{
        actions.reset();
    }
}

export const Mixpanel = actions;
