import React, {useContext, useEffect, useState} from "react";
import PageLayout from "components/PageLayout";
import { Navigate } from "react-router-dom";
import { ROUTES } from "services/constants";
import thumbsUp from "../../assets/icons/thumbs-up.svg";
import handsUp from "../../assets/icons/hands-up.svg";
import thumbsDown from "../../assets/icons/thumbs-down.svg";
import {observer} from "mobx-react";
import { Box, Grid, Icon, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import {RealtimeContext} from "../../services/streaming/provider";

const Meeting = observer(() => {
  const {
    startCapture, sharedStream, markServer, isStreaming,
    enableWebsocket, publish, subscribe, unsubscribe
  } = useContext(RealtimeContext);
  const [showStream , setShowStream] = useState(true)
  const videoRef = React.useRef(null)

  useEffect(() => {
    // if (showStream){
    //   console.log(sharedStream)
    //     videoRef.current.srcObject = sharedStream
    // }
  },[showStream, sharedStream])

  useEffect(() => {
    markServer()
  },[markServer])

  return (
    <PageLayout
      // teacherComponent={<TeachersPresentation />}
      // studentComponent={<Navigate to={ROUTES.NOT_FOUND} />}
    >

      <Box display="flex" height="100vh">
        <Box width="200px" px={1} display="flex" flexDirection="column">
          <Box
              border="1px solid rgba(255, 255, 255, 0.20)"
              py={1}
              m={1}
              borderRadius={2}
              display="flex"
              justifyContent="space-around"
          >
            <IconButton sx={{ boxShadow: 2, backgroundColor: "#46BA74" }} onClick={() => { startCapture() }}>
              <Icon>
                <img src={thumbsUp} alt="toilet-paper" />
              </Icon>
            </IconButton>
            <IconButton sx={{ boxShadow: 2, backgroundColor: "#FFF74D" }} onClick={() => {setShowStream(!showStream)}}>
              <Icon>
                <img src={handsUp} alt="toilet-paper" />
              </Icon>
            </IconButton>
            <IconButton sx={{ boxShadow: 2, backgroundColor: "#FF5964" }}>
              <Icon>
                <img src={thumbsDown} alt="toilet-paper" />
              </Icon>
            </IconButton>
          </Box>
        </Box>
        <Box width="200px" px={1} display="flex" flexDirection="column">
          <Box
              border="1px solid rgba(255, 255, 255, 0.20)"
              py={1}
              m={1}
              borderRadius={2}
              display="flex"
              justifyContent="space-around"
          >
            <IconButton sx={{ boxShadow: 2, backgroundColor: "#46BA74" }} onClick={() => { enableWebsocket() }}>
              <Icon>
                <img src={thumbsUp} alt="toilet-paper" />
              </Icon>
            </IconButton>
            <IconButton sx={{ boxShadow: 2, backgroundColor: "#FFF74D" }} onClick={() => {subscribe('hola')}}>
              <Icon>
                <img src={handsUp} alt="toilet-paper" />
              </Icon>
            </IconButton>
            <IconButton sx={{ boxShadow: 2, backgroundColor: "#FF5964" }} onClick={() => {publish('hola', `${+new Date()}`)}}>
              <Icon>
                <img src={thumbsDown} alt="toilet-paper" />
              </Icon>
            </IconButton>
            <IconButton sx={{ boxShadow: 2, backgroundColor: "#FF5964" }} onClick={() => {subscribe('hola')}}>
            <Icon>
              <img src={thumbsDown} alt="toilet-paper" />
            </Icon>
          </IconButton>
          </Box>
        </Box>
        <Box width="200px" px={1} display="flex" flexDirection="column">
          {/*{showStream ? <video ref={videoRef} autoPlay playsInline width={800} height={640} style={{backgroundColor: "black"}}></video> : null}*/}
          {/*{(showStream && sharedStream) ? <Icon>*/}
          {/*  <img src={thumbsDown} alt="toilet-paper" />*/}
          {/*</Icon> : null}*/}
        </Box>
      </Box>



    </PageLayout>
  );
});

export default Meeting;
