import {Box, Grid, Icon, IconButton, Typography} from "@mui/material";
import {buttonArrowsRender, buttonXZBARender} from "./horizontalButtons";
import {Paid} from "@mui/icons-material";
import React, {useState} from "react";
import toiletPaper from "../../../assets/images/papelH.png";
import flag from "../../../assets/images/flag.png";
import thumbsUp from "../../../assets/images/like.png";
import handsUp from "../../../assets/images/stop.png";
import thumbsDown from "../../../assets/images/dislike.png";
import logo from "../../../assets/images/logos/logo.png";
import {useApi} from "../../../services/helpers";
import {STUDENT_FEEDBACK, STUDENT_FLAGS} from "../../../services/constants";
import {ACTIVE_BUTTON_COLOR, INACTIVE_BUTTON_COLOR} from "../common_subscreens";
import MDBox from "../../../components/MDBox";
import {useParams} from "react-router-dom";
import {MathJax} from "better-react-mathjax";


export const HorizontalMultipleChoiceScreen = ({XZBA_OPTIONS, ARROW_OPTIONS, prompt, reward}) =>{
    const [selectedOptions, setSelectedOptions] = useState(null);
    const handleOptionClick = (option) => {
        setSelectedOptions(option);
    };
    const handleClosedClick = () => {
        setSelectedOptions(null);
    };
    return (
        <Grid item container spacing={2}>
            <Grid item xs={12}>
                <Typography textAlign="center" fontWeight={"600"} mb={2} color={"#FFC130"}>
                    <MathJax>{prompt}</MathJax>
                </Typography>
            </Grid>
            {selectedOptions ?(
                    <MDBox
                        width='100%'
                        margin={'0px 0px 0px 18px'}
                        border={selectedOptions?.color ? `2px solid ${selectedOptions.color}` : "2px solid #0B0F08"}
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={'center'}
                        gap={2}
                        onClick={handleClosedClick}
                        borderRadius="10px"
                        p={2}
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width="50px"
                            height="50px"
                            p={1}
                            bgcolor={selectedOptions?.color}
                            borderRadius="100%"
                        >
                            <Typography textAlign="center" fontWeight={600} color={'#babdb7 !important'} verticalAlign={'center'} sx={{alignSelf:'normal', color:'white !important', textShadow:' -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;'}}>
                                {selectedOptions?.button}
                            </Typography>
                        </Box>
                        <Typography p={2} variant="body2" fontSize={12} color={"#fff"}>
                            <MathJax>{selectedOptions?.text}</MathJax>
                        </Typography>
                    </MDBox>
                )
                : (
                    <>
            <Grid item container columnSpacing={2} px={2} ml={0}>
                {XZBA_OPTIONS?.map((option, index) => buttonXZBARender(option, index, handleOptionClick))}
            </Grid>
            <Grid item container columnSpacing={2} px={2} ml={0}>
                {ARROW_OPTIONS?.map((option, index) => buttonArrowsRender(option, index, handleOptionClick))}
            </Grid>
                    </>
                    )}
            {!!reward &&<Grid item container>
                <Grid
                    container
                    item
                    xs={12}
                    mx={2}
                    p={1}
                    bgcolor={"#3A532B"}
                    sx={{ borderRadius: 2, height: "40px" }}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Typography fontWeight={"600"} variant="body2" color={"#fff"}>
                        Reward
                    </Typography>
                    <MDBox display={"flex"} justifyContent={"center"} alignItems={"center"} gap={1}>
                        <Typography fontWeight={"600"} variant="body2" color={"#fff"}>
                            {reward}
                        </Typography>
                        <Paid color="white" />
                    </MDBox>
                </Grid>
            </Grid>}
        </Grid>
    )
}

export const HorizontalActionIcons1 = ({flags, padComponent}) => {
    const { meetingId} = useParams()
    const api = useApi();

    const sendFeedback = (feedback) => {
        api.sendMeetingFeedback(meetingId, feedback).handle({
            successMessage: "Feedback sent",
            errorMessage: "Error sending feedback",
        })
    }

    const manageFlag = (flag) => {
        api.manageFlag(meetingId, flag, !flags[flag]).handle({
          //  successMessage: "Message sent",
            errorMessage: "Error sending message",
        })
    }

    return (<Box
        width="35%"
        px={1}
        display="flex"
        flexDirection="column"
        justifyContent={"space-between"}
    >
        <Box
            border="1px solid rgba(255, 255, 255, 0.20)"
            py={1}
            m={1}
            // flexWrap={'wrap'} //Verificar con el cambio nuevo
            borderRadius={2}
            display="flex"
            justifyContent="space-around"
        >
            <MDBox
            sx={{
              boxShadow: 2,
              backgroundColor: flags[STUDENT_FLAGS.THUMBS_UP] ? ACTIVE_BUTTON_COLOR : INACTIVE_BUTTON_COLOR,
              width: "40px",
              height: "40px",
              p: "8px",
              borderRadius: "50%",
            }}
            onClick={() => manageFlag(STUDENT_FLAGS.THUMBS_UP)}
          >
            <MDBox component={"img"} src={thumbsUp} width={"auto"} alt='thumbs-up' />
          </MDBox>
             <MDBox
            sx={{
              boxShadow: 2,
              backgroundColor: flags[STUDENT_FLAGS.RAISE_HAND] ? ACTIVE_BUTTON_COLOR : INACTIVE_BUTTON_COLOR,
              width: "40px",
              height: "40px",
              p: "8px",
              borderRadius: "50%",
            }}
            onClick={() => manageFlag(STUDENT_FLAGS.RAISE_HAND)}
          >
            <MDBox component={"img"} src={handsUp} width={"auto"} alt='thumbs-up' />
          </MDBox>
            <MDBox
            sx={{
              boxShadow: 2,
              backgroundColor: flags[STUDENT_FLAGS.THUMBS_DOWN] ? ACTIVE_BUTTON_COLOR : INACTIVE_BUTTON_COLOR,
              width: "40px",
              height: "40px",
              p: "8px",
              borderRadius: "50%",
            }}
            onClick={() => manageFlag(STUDENT_FLAGS.THUMBS_DOWN)}
          >
            <MDBox component={"img"} src={thumbsDown} width={"auto"} alt='thumbs-up' />
          </MDBox>
        </Box>
        <MDBox display={"flex"} justifyContent={"center"}>
            {padComponent}
        </MDBox>
        <MDBox
            component={"img"}
            src={logo}
            style={{
                width: "60%",
                display: "block",
                margin: "auto",
                marginTop: 20,
                marginBottom: 10,
            }}
            alt="logo"
        />
    </Box>)
}

export const HorizontalActionIcons2 = ({flags, padComponent}) => {
    const { meetingId} = useParams()
    const api = useApi();
    const manageFlag = (flag) => {
        api.manageFlag(meetingId, flag, !flags[flag]).handle({
           // successMessage: "Message sent",
            errorMessage: "Error sending message",
        })
    }

    return (<Box
        width="30%"
        px={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
    >
        <Box py={1} my={1} display="flex" justifyContent="space-between" width="100%" height="55px">
        <MDBox
            sx={{
              boxShadow: 2,
              backgroundColor: flags[STUDENT_FLAGS.BATHROOM] ? ACTIVE_BUTTON_COLOR : INACTIVE_BUTTON_COLOR,
              width: "40px",
              height: "40px",
              p: "8px",
              borderRadius: "50%",
            }}
            onClick={() => manageFlag(STUDENT_FLAGS.BATHROOM)}
          >
            <MDBox component={"img"} src={toiletPaper} width={"auto"} alt='thumbs-up' />
          </MDBox>
            <MDBox
            sx={{
              boxShadow: 2,
              backgroundColor: flags[STUDENT_FLAGS.FLAG] ? ACTIVE_BUTTON_COLOR : INACTIVE_BUTTON_COLOR,
              width: "40px",
              height: "40px",
              p: "8px",
              borderRadius: "50%",
            }}
            onClick={() => manageFlag(STUDENT_FLAGS.FLAG)}
          >
            <MDBox component={"img"} src={flag} width={"auto"} alt='thumbs-up' />
          </MDBox>
        </Box>
        <Box flex={1} display="flex" alignItems="center" justifyContent="center">
            {padComponent}
        </Box>
        <Box py={1} my={1} display="flex" height="55px"></Box>
    </Box>)
}

