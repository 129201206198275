import React, { useEffect } from "react";
import { observer } from "mobx-react";

import MDBox from "../../components/MDBox";
import LoginForm from "./login-form";
import { Container } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useLoginStore } from "services/helpers";
import { useNavigate } from "react-router-dom";
import bgImage from '../../assets/images/background/background.png';
const sideBar = require("../../assets/images/background/sidebar.png");
const logo = require("../../assets/images/logos/logo.png");

function SignIn() {
  const loginStore = useLoginStore();
  const navigate = useNavigate();
  useEffect(() => {
    if (loginStore.isLoggedIn) {
      navigate("/");
    }
  }, [loginStore]);

  return (
    <MDBox
    sx={{
      overflowX: "hidden",
      overflowY: "hidden",
      justifyItems: "center",
    }}>
      <MDBox
        style={{
          overflowX: "hidden",
          overflowY: "hidden",
          position: "fixed",
          zIndex: -1,
            width: "100%",
        }}
      >
        <img
          alt="background"
          src={sideBar}
          style={{ height: "100vh", width: "10%", objectFit: "cover" }}
        />

        <img alt="side" src={bgImage} style={{ height: "100vh", width: "90%" }} />
      </MDBox>
      <Container
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <MDBox
          // maxWidth="332px"
          maxHeight="90vh"
          bgColor="white"
          p={4}
          borderRadius="xl"
          sx={{
            width:{xs:'100%', sm:'442px'},
            margin: "auto",
              overflowX: "auto",
            backgroundColor: "white",
            alignConten:'center',
            justifyContent:'center',
            display:'flex',
            flexDirection:'column'
          }}
        >
          <img
            src={logo}
            style={{ display: "block", margin: "auto", marginBottom: 50 }}
            alt="logo"
          />
          <MDTypography color="primary" fontSize={{xs:'24px', md:"30px"}} variant="h3" fontWeight="bold" textAlign="center" mb={4}>
            Sign in
          </MDTypography>
          <LoginForm />
        </MDBox>
      </Container>
    </MDBox>
  );
}

export default observer(SignIn);
