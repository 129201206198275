import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useStores } from "../../models";
import { ROUTES } from "../../services/constants";
import { useMaterialUIController, setLayout } from "context";
import { LAYOUT_TYPES } from "context";

const Logout = () => {
  const rootStore = useStores();
  const { loginStore } = rootStore;
  const [, dispatch] = useMaterialUIController();

  useEffect(() => {
    setLayout(dispatch, LAYOUT_TYPES.PAGE);
    loginStore.reset();
  }, [dispatch, loginStore]);

  return <Navigate to={ROUTES.LOGIN} />;
};

export default Logout;
