import {useEffect, useRef, useState} from "react";
import {rtcConfig} from "../constants";


export const addiceeveents = (peerConnection: RTCPeerConnection, myid: string, other_id: string, channel: BroadcastChannel, imServer: boolean) => {
    peerConnection.addEventListener('icecandidate', (event) => {
        console.log('ice candidate', event, imServer ? '(server)' : '(client)')
        if (event.candidate !== null) {
            channel.postMessage({type: 'ice', data: event.candidate.toJSON(), id: myid, original_id: other_id})
        }else{
            console.log('ice candidate list complete')
        }
    })
    peerConnection.addEventListener('icegatheringstatechange', (event) => {
        console.log('icegatheringstatechange', event, imServer ? '(server)' : '(client)')
    })
    peerConnection.addEventListener('iceconnectionstatechange', (event) => {
        console.log('iceconnectionstatechange', event, imServer ? '(server)' : '(client)')
    })

    peerConnection.addEventListener('icecandidateerror', (event) => {
        console.log('ice candidate error', event, imServer ? '(server)' : '(client)')
    })
}


export const add_other_events_listeners = (peerConnection: RTCPeerConnection, imServer: boolean) => {
    const evts = ['datachannel', 'negotiationneeded', 'signalingstatechange', 'track', 'connectionstatechange']
    const added = evts.map((event_name) => {
        const listener = (event: Event) => {
            console.log('DEBUG: ', event_name, event, imServer ? '(server)' : '(client)')
        }
        peerConnection.addEventListener(event_name, listener)
        return {event_name, listener}
    })
    return () => {
        added.forEach(({event_name, listener}) => {
            peerConnection.removeEventListener(event_name, listener)
        })
    }
}
