import { Box, CircularProgress, IconButton, Typography } from "@mui/material"
import Checkbox from '@mui/material/Checkbox'
import React, {useMemo, useRef, useState} from 'react'
import MDBox from '../../../components/MDBox'
import {GAME_MESSAGE_TYPES, QUESTION_TYPES} from '../../../services/constants'
import {
    ChatBubbleSection,
    CorrectAnswerScreen,
    ExtraReward,
    ForcedDeduction,
    LockScreen,
    OpenQuestionQueryScreen,
    TopBar,
    WrongAnswerScreen,
} from '../common_subscreens'
import {ArrowPad, LetterButtons} from '../pads'
import {HorizontalActionIcons1, HorizontalActionIcons2, HorizontalMultipleChoiceScreen,} from './horizontal_subscreens'
import {FileUpload, Paid} from "@mui/icons-material"
import AnimationData from "../../../assets/Lottie/AnimationConfetti.json";
import {RewardNotification} from "../../meeting/reward_notification";
import Lottie from "react-lottie";

export const HorizontalGameboyView = ({
                                          meeting,
                                          meetingId,
                                          balance,
                                          latestQuestion,
                                          rewardNotification,
                                          setRewardNotification,
                                          latestTaskGroup,
                                          submitTask = () => {
                                          },
                                          reward,
                                          prompt,
                                          engagementAnswered,
                                          userInteraction,
                                          XZBA_OPTIONS,
                                          ARROW_OPTIONS,
                                          screenOrientation,
                                          flags,
                                          messageShowing,
                                          viewHeight,
                                          taskImages,
                                          taskImageFileInputRef,
                                          handleImageChange,
                                          loading,
                                          submittingTasksLoading
                                      }) => {
    const handleCheckSubmit = (taskId) => {
        const image = taskImages[taskId];
        submitTask(meetingId, taskId, image)
    }

  const notificationDisplay = useMemo(() => {
    if (!rewardNotification) return
    const AnimationOptions = {
      loop: false,
      autoplay: true,
      animationData: AnimationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return (
      <MDBox
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        // alignItems={"center"}
        width={"100%"}
        height={"100%"}
        position={"relative"}
      >
        <RewardNotification
          showModal={!!rewardNotification}
          title={rewardNotification?.student}
          message={rewardNotification?.message}
          handleClose={() => setRewardNotification(null)}
        />
        <Lottie
          options={AnimationOptions}
          height={'100%'}
          width={'100%'}
          style={{
            zIndex: -1,
            position: 'absolute',
          }}
        />
      </MDBox>
    )
  }, [rewardNotification,])

    const questionDisplay = useMemo(() => {
        if (!latestQuestion) return

        if (!engagementAnswered || !latestQuestion.question) {
            return <LockScreen/>
        }

        if (latestQuestion.question.question_type === QUESTION_TYPES.MULTIPLE_CHOICE) {
            return (
                <HorizontalMultipleChoiceScreen
                    prompt={prompt}
                    reward={reward}
                    XZBA_OPTIONS={XZBA_OPTIONS}
                    ARROW_OPTIONS={ARROW_OPTIONS}
                />
            )
        }

        if (latestQuestion.question.question_type === QUESTION_TYPES.OPEN) {
            return (
                <OpenQuestionQueryScreen
                    prompt={prompt}
                    reward={reward}
                    userInteraction={userInteraction}
                />
            )
        }
    }, [latestQuestion, prompt, reward, XZBA_OPTIONS, ARROW_OPTIONS, userInteraction])

    const taskGroupDisplay = useMemo(() => {
        if (!latestTaskGroup) return <></>
        const {name = '', description = '', tasks = []} = latestTaskGroup
        //
        return (
            <MDBox sx={{padding: 2}}>

                <Typography color={"secondary"} fontWeight={"600"} fontSize={14} textAlign={"center"} mb={1}>
                    Complete the following tasks to earn class bucks
                </Typography>
                <Typography color={'#FFC130'} fontWeight={'600'} fontSize={20}>
                    {name}
                </Typography>
                <MDBox display={'flex'} flexDirection={'column'} gap={2}>
                    {tasks.map((task, tidx) => {
                        const {completed, completed_by_teacher} = task
                        const totallyCompleted = !!completed && !!completed_by_teacher
                        const isSubmitting = submittingTasksLoading.includes(task.id)
                        //
                        return (
                            <Box key={`task-${tidx}`} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                              {!!isSubmitting &&
                                <MDBox width={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"} minHeight={"100px"}>
                                  <CircularProgress />
                                </MDBox>}
                              {!isSubmitting && <>
                                <Box>
                                    <Box display={'flex'} alignItems={'center'}>
                                        <Checkbox
                                            edge="start"
                                            checked={task.completed}
                                            tabIndex={-1}
                                            disabled={loading}
                                            onChange={() => (!task.completed ? handleCheckSubmit(task.id) : null)}
                                            inputProps={{'aria-label': 'controlled'}}
                                        />
                                        <Typography
                                            color={'primary'}
                                            sx={totallyCompleted ? {textDecoration: "line-through"} : {}}
                                        >
                                            {task.name}
                                        </Typography>
                                        {taskImages[task.id] &&
                                            <Typography color="secondary" fontSize={"small"} ml={1}>
                                                (Image selected)
                                            </Typography>}
                                        <IconButton
                                            component="label"
                                            size="small"
                                        >
                                            <FileUpload color="secondary"/>
                                            <input
                                                type="file"
                                                hidden
                                                ref={el => taskImageFileInputRef.current[tidx] = el}
                                                onChange={(e) => handleImageChange(task.id, e)}
                                            />
                                        </IconButton>
                                    </Box>
                                    <Typography color={'#FFF'} fontSize={'small'}>
                                        {task.description}
                                    </Typography>
                                </Box>
                                {/* REWARD */}
                                {!task.completed &&
                                    <Box display={"flex"} alignItems={"center"} gap={0.5}>
                                        <Typography color={"#FFF"} fontWeight={"bold"} fontSize={"medium"}
                                                    marginTop={0.5}>
                                            {task.reward}
                                        </Typography>
                                        <Paid color="primary"/>
                                    </Box>}
                                {!!task.completed &&
                                    <Box display={"flex"} alignItems={"center"} gap={0.5}>
                                        <Typography color={`${totallyCompleted ? "primary" : "#FFC130"}`}
                                                    fontWeight={"bold"} fontSize={"medium"} marginTop={0.5}>
                                            {task.reward}
                                        </Typography>
                                        <Paid color="primary"/>
                                    </Box>}
                              </>}
                            </Box>
                        )
                    })}
                </MDBox>
            </MDBox>
        )
    }, [handleCheckSubmit, latestTaskGroup])

    const messageDisplay = useMemo(() => {
        if (!messageShowing) return

        switch (messageShowing.type) {
            case GAME_MESSAGE_TYPES.CORRECT_RESPONSE:
                return (
                    <CorrectAnswerScreen
                        rewardAmount={messageShowing.reward}
                        bonusAmount={messageShowing.bonus}
                    />
                )
            case GAME_MESSAGE_TYPES.INCORRECT_RESPONSE:
                return (
                    <WrongAnswerScreen
                        horizontal={true}
                        reward={messageShowing.reward}
                        bonusAmount={messageShowing.bonus}
                    />
                )
            case GAME_MESSAGE_TYPES.DEDUCTION:
                return <ForcedDeduction reason={messageShowing.reason} amount={messageShowing.amount}/>
            case GAME_MESSAGE_TYPES.EXTRA_REWARD:
                return <ExtraReward reason={messageShowing.reason} amount={messageShowing.amount}/>
            default:
                return null
        }
    }, [messageShowing])

    return (
        <Box display="flex" height={viewHeight}>
            {!latestTaskGroup && (
                <HorizontalActionIcons1
                    flags={flags}
                    padComponent={<ArrowPad userInteraction={userInteraction}/>}
                />
            )}
            <Box
                width={'100%'}
                height="100%"
                display="flex"
                flexDirection="column"
                borderLeft="1px solid gray"
                borderRight="1px solid gray"
                p={2}
            >
                {((!questionDisplay && !messageDisplay) || !engagementAnswered) && (
                    <TopBar meeting={meeting} balance={balance} flags={flags}/>
                )}
                <MDBox
                    height={'100%'}
                    bgcolor={'#0B0F08'}
                    border={'1px solid #151D0F'}
                    sx={{overflowY: 'auto'}}
                >
                    {notificationDisplay}
                    {questionDisplay}
                    {taskGroupDisplay}
                    {messageDisplay}
                    {!latestTaskGroup && !latestQuestion && !messageShowing && !notificationDisplay && <LockScreen/>}
                </MDBox>
                <ChatBubbleSection/>
            </Box>
            {!latestTaskGroup && (
                <HorizontalActionIcons2
                    flags={flags}
                    padComponent={<LetterButtons userInteraction={userInteraction}/>}
                />
            )}
        </Box>
    )
}
