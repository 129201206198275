import { Box, FormControlLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material";
import FormikInput from "components/FormikInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import ModalItem from "components/ModalItem";
import { Form, Formik, useFormikContext } from "formik";
import { useEffect, useRef, useState } from "react";

export const QUESTION_TYPES = {
  MULTIPLE_CHOICE: "multiple_choice",
  OPEN_ENDED: "open_ended",
  SLIDING_SCALE: "sliding_scale",
};
const QUESTION_TYPES_LIST = [
  { key: QUESTION_TYPES.MULTIPLE_CHOICE, label: "Multiple Choice" },
  { key: QUESTION_TYPES.OPEN_ENDED, label: "Open Ended" },
  { key: QUESTION_TYPES.SLIDING_SCALE, label: "Sliding Scale" },
];
const QuestionModal = ({ showModal, handleClose, onCreated }) => {
  const formikRef = useRef();
  const onSubmit = (values) => {
    onCreated(values);
  };
  return (
    <ModalItem
      scrollable={false}
      open={showModal}
      closeOnClickOutside={false}
      height="70vh"
      width="500px"
      title="Add Item"
      handleClose={handleClose}
    >
      <Formik
        innerRef={formikRef}
        initialValues={{ type: undefined, question: "" }}
        validateOnBlur={false}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, errors, handleSubmit }) => (
          <Form style={{ width: "100%" }}>
            <Grid container>
              <Grid xs={12} mt={3}>
                <MDTypography color="dark" fontSize={24} flexDirection="row" mr={2}>
                  Type of question
                </MDTypography>
                <RadioGroup
                  row
                  name="repeat-options-group"
                  sx={{ px: 1 }}
                  value={values.type}
                  onChange={(e) => setFieldValue("type", e.target.value)}
                >
                  {QUESTION_TYPES_LIST.map((option) => (
                    <FormControlLabel
                      key={option.key}
                      value={option.key}
                      control={<Radio />}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              </Grid>
              <Grid xs={12} mt={3}>
                <MDTypography color="dark" fontSize={24} flexDirection="row" mr={2}>
                  Classbucks Value
                </MDTypography>
                <FormikInput name="value" placeholder="Value" errors={errors} fullWidth />
              </Grid>
              {values.type === QUESTION_TYPES.MULTIPLE_CHOICE && <MultipleChoiceQuestion />}
              {values.type === QUESTION_TYPES.OPEN_ENDED && <OpenEndedQuestion />}
              {values.type === QUESTION_TYPES.SLIDING_SCALE && <SlidingScaleQuestion />}
              <Grid xs={12} mt={3} display="flex" justifyContent="center">
                <MDButton color="primary" onClick={handleSubmit}>
                  Save
                </MDButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </ModalItem>
  );
};

const OpenEndedQuestion = () => {
  const { errors } = useFormikContext();
  return (
    <>
      <Grid xs={12} mt={3}>
        <MDTypography color="dark" fontSize={24} flexDirection="row" mr={2}>
          Enter the question
        </MDTypography>
        <FormikInput name="question" placeholder="Question" errors={errors} fullWidth />
      </Grid>
    </>
  );
};

const SlidingScaleQuestion = () => {
  const { errors } = useFormikContext();
  return (
    <>
      <Grid xs={12} mt={3}>
        <MDTypography color="dark" fontSize={24} flexDirection="row" mr={2}>
          Enter the question
        </MDTypography>
        <FormikInput name="question" placeholder="Question" errors={errors} fullWidth />
        <Box display="flex" gap={3}>
          <Box flex={1}>
            <MDTypography color="dark" fontSize={20} flexDirection="row" mr={2}>
              From
            </MDTypography>
            <FormikInput name="from" type="number" errors={errors} fullWidth />
          </Box>
          <Box flex={1}>
            <MDTypography color="dark" fontSize={20} flexDirection="row" mr={2}>
              To
            </MDTypography>
            <FormikInput name="to" type="number" errors={errors} fullWidth />
          </Box>
        </Box>

        <MDTypography color="dark" fontSize={20} flexDirection="row" mr={2}>
          Step
        </MDTypography>
        <FormikInput name="step" type="number" errors={errors} fullWidth />
      </Grid>
    </>
  );
};
const MultipleChoiceQuestion = () => {
  const { errors, setFieldValue, values } = useFormikContext();
  return (
    <>
      <Grid xs={12} mt={3}>
        <MDTypography color="dark" fontSize={24} flexDirection="row" mr={2}>
          Enter The Multiple Choice Question
        </MDTypography>
        <FormikInput name="question" placeholder="Question" errors={errors} fullWidth />
      </Grid>
      <Grid xs={12}>
        {values.options?.map((_, i) => (
          <OptionField
            i={i}
            onChange={(value) => {
              values.options[i] = value;
              setFieldValue("options", values.options);
            }}
            checked={values.answer === i}
            onSelectOption={() => setFieldValue("answer", i)}
          />
        ))}
      </Grid>
      <Grid item xs={12}>
        <MDButton
          onClick={() => setFieldValue("options", values.options ? [...values.options, ""] : [""])}
        >
          Add Option
        </MDButton>
      </Grid>
    </>
  );
};
export const OptionField = ({ i, checked, onChange, onSelectOption }) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    onChange(value);
  }, [value]);
  return (
    <Box display="flex">
      <TextField
        fullWidth
        variant="standard"
        sx={{ p: 2 }}
        value={value}
        placeholder={`Option ${i + 1}`}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      <Radio checked={checked} onClick={onSelectOption} />
    </Box>
  );
};
export default QuestionModal;
