import {PlayArrow} from "@mui/icons-material"
import {Grid, Icon} from "@mui/material"
import MDButton from "components/MDButton"
import MDTypography from "components/MDTypography"
import moment from "moment"
import React, {useEffect, useRef, useState} from "react"
import {useParams} from "react-router-dom"
import {fireBeep} from "../../services/beep_service"
import {BARS, CLASS_MODES, CONNECTED_FLAG, STUDENT_FLAGS, THERMOMETER,} from "../../services/constants"
import {useApi} from "../../services/helpers"
import {RealtimeContext} from "../../services/streaming/provider"
import {LeaderboardTab} from "./LeaderboardTab"
import {ManagementTab} from "./ManagementTab"
import {QuestionsTab} from "./QuestionsTab"
import {TaskGroupsTab} from "./TaskGroupsTab"
import {StudentsTab} from "./StudentsTab"
import {DiscussionsTab} from "./DiscussionsTab";

const TeachersAdminPanel = () => {
    const api = useApi()
    const {courseId, meetingId} = useParams()
    const [selectedTab, setSelectedTab] = useState("students")
    const [students] = useState([])
    const [selectedStudent, setSelectedStudent] = useState(null)
    const [subscribable, setSubscribable] = useState(false)

    const {
        enableWebsocket,
        publish,
        subscribe,
        unsubscribe,
        addMessageListener,
        removeMessageListener,
    } = React.useContext(RealtimeContext)
    const [attendanceStatus, setAttendanceStatus] = useState(null)
    const [flags, setFlags] = useState({})

    const attendace_topic = `meeting/${meetingId}/attendance_update`
    const asks_topic = `meeting/${meetingId}/asks`
    const tasks_topic = `meeting/${meetingId}/tasks`
    const engagement_topic = `meeting/${meetingId}/auto_engagement`
    const start_topic = `meeting/${meetingId}/start`
    const end_topic = `meeting/${meetingId}/end`
    const mode_topic = `meeting/${meetingId}/mode`
    const stats_topic = `meeting/${meetingId}/stats`
    const student_tasks_topic = `meeting/${meetingId}/student_tasks`
    const navigate_penalty_topic = `meeting/${meetingId}/navigate_penalty_on`

    const [meeting, setMeeting] = useState(null)
    const [questions, setQuestions] = useState(null)
    const [taskGroups, setTaskGroups] = useState(null)
    // const [discussions, setDiscussions] = useState(null)
    const [taskGroupsLoading, setTaskGroupsLoading] = useState(false)
    const balances = useRef({})
    const [balances_state, setBalances] = useState({})
    const [autoEngagement, setAutoEngagement] = useState(false)
    const [meetingStart, setMeetingStart] = useState(null)
    const [meetingEnd, setMeetingEnd] = useState(null)
    const [stats, setStats] = useState([])
    const [studentTasks, setStudentTasks] = useState([])
    const [studentTasksLoading, setStudentTasksLoading] = useState(false)
    const [statsUpdate, setStatsUpdate] = useState(+new Date())
    //
    const [classMode, setClassMode] = React.useState(CLASS_MODES.QUESTIONS)
    const [navigatePenaltyOn, setNavigatePenaltyOn] = React.useState(null)
    const classModeIsQuestions = classMode === CLASS_MODES.QUESTIONS
    const classModeIsTasks = classMode === CLASS_MODES.TASKS
    const classModeIsDiscussion = classMode === CLASS_MODES.DISCUSSION

    const toggleAutoEngagement = () => {
        const action = autoEngagement ? "continue_engagement" : "pause_engagement"
        const successMessage = autoEngagement
            ? "Engagement auto measurement paused"
            : "Engagement auto measurement enabled"
        api.meetingAction(meetingId, action).handle({
            successMessage: successMessage,
            errorMessage: "Error toggling auto engagement",
        })
    }

    const pauseAutoEngagement = () => {
        const action = "continue_engagement"
        api.meetingAction(meetingId, action).handle({
            // successMessage: successMessage,
            errorMessage: "Error pausing auto engagement",
        })
    }

    const continueAutoEngagement = () => {
        const action = "pause_engagement"
        api.meetingAction(meetingId, action).handle({
            // successMessage: successMessage,
            errorMessage: "Error continuing auto engagement",
        })
    }

    const getCourseMeetingByCourse = () => {
        api.getSpecificCourseMeetingByCourse(courseId, meetingId).handle({
            onSuccess: (res) => {
                setMeeting(res.data)
            },
            errorMessage: "Error getting meeting",
        })
    }

    const courseLessonQuestions = () => {
        if (meeting.lesson === null) {
            setQuestions(null)
            return
        }

        api.listCourseLessonQuestions(courseId, meeting.lesson?.id).handle({
            onSuccess: (res) => {
                setQuestions(res.data)
            },
            errorMessage: "Error getting questions",
        })
    }



    const courseLessonTaskGroups = () => {
        if (meeting.lesson === null) {
            setTaskGroups(null)
            return
        }
        setTaskGroupsLoading(true)
        api.listCourseLessonTaskGroups(courseId, meeting.lesson?.id).handle({
            onSuccess: (res) => {
                setTaskGroups(res.data)
            },
            errorMessage: "Error getting task groups",
            onFinally: () => setTaskGroupsLoading(false),
        })
    }

    const getStats = () => {
        api.meetingStats(meetingId).handle({
            onSuccess: (res) => {
                setStats(res.data)
                console.log("meeting stats", res.data)
            },
        })
    }

    const getStudentTasks = () => {
        setStudentTasksLoading(true)
        api.meetingStudentTasks(meetingId).handle({
            onSuccess: (response) => {
                setStudentTasks(response.data)
            },
            onFinally: () => setStudentTasksLoading(false),
        })
    }

    useEffect(() => {
        if (classMode === CLASS_MODES.QUESTIONS) {
            getStats()
        } else {
            getStudentTasks()
        }
    }, [classMode])

    useEffect(() => {
        getCourseMeetingByCourse()
        courseAttendanceStatus()
    }, [])

    useEffect(() => {
        if (meeting !== null) {
            courseLessonQuestions()
            courseLessonTaskGroups()
            // courseMeetingDiscussions()
        }
    }, [meeting])

    useEffect(() => {
        if (!subscribable || !attendanceStatus) return

        const students = (attendanceStatus?.present || []).concat(attendanceStatus?.absent || [])
        students.forEach((studentReg) => {
            subscribe(`student/${studentReg.student.user_id}/balance`)
            subscribe(`meeting/${meetingId}/${studentReg.student.user_id}/status`)
            subscribe(`meeting/${meetingId}/${studentReg.student.user_id}/bars`)
            subscribe(`meeting/${meetingId}/${studentReg.student.user_id}/thermometer`)
            Object.values(STUDENT_FLAGS).forEach((flag) => {
                subscribe(`meeting/${meetingId}/${studentReg.student.user_id}/flag/${flag}`)
            })
        })
    }, [subscribable, attendanceStatus, subscribe])

    const courseAttendanceStatus = () => {
        api.getAttendanceStatus(parseInt(meetingId)).handle({
            onSuccess: (res) => {
                setAttendanceStatus(res.data)
            },
            errorMessage: "Error getting attendance status",
        })
    }

    const triggerEngagement = () => {
        api.triggerEngagement(parseInt(meetingId)).handle({
            successMessage: "Engagement measurement requested",
            errorMessage: "Error requesting engagement measurement",
        })
    }

    useEffect(() => {
        const listener = (message) => {
            if (message.type === "welcome") {
                // console.log("Subscribing to attendance update", attendace_topic)
                subscribe(attendace_topic)
                subscribe(asks_topic)
                subscribe(tasks_topic)
                subscribe(engagement_topic)
                subscribe(start_topic)
                subscribe(end_topic)
                // console.log("Subscribing to amode_topic", mode_topic)
                subscribe(mode_topic)
                subscribe(stats_topic)
                subscribe(student_tasks_topic)
                // console.log("Subscribing to navigate_penalty_topic", navigate_penalty_topic)
                subscribe(navigate_penalty_topic)
                setSubscribable(true)
            } else if (message?.type === "message") {
                const topic = message?.topic
                const payload = message?.payload
                //
                if (topic === attendace_topic) {
                    // console.log("Received attendance update flag")
                    courseAttendanceStatus()
                } else if (topic === asks_topic) {
                    // console.log("Received asks question:", payload)
                } else if (topic === stats_topic) {
                    getStats()
                    setStatsUpdate(+new Date())
                } else if (topic === student_tasks_topic) {
                    getStudentTasks()
                } else if (/^student\/(\d+)\/balance$/.test(topic)) {
                    const [, studentId] = /^student\/(\d+)\/balance$/.exec(topic)
                    balances.current[studentId] = JSON.parse(payload)
                    setBalances({...balances.current})
                    // console.log("Received balance update", balances.current)
                } else if (topic === engagement_topic) {
                    setAutoEngagement(payload === "true")
                } else if (topic === start_topic) {
                    setMeetingStart(moment(payload).utc(true))
                } else if (topic === mode_topic) {
                    setClassMode(message.payload)
                } else if (topic === navigate_penalty_topic) {
                    setNavigatePenaltyOn(message.payload)
                } else if (topic === end_topic) {
                    setMeetingEnd(moment(payload).utc(true))
                } else if (/^meeting\/(\d+)\/(\d+)\/flag\/(\d+)$/.test(topic)) {
                    const [, meetingId2, studentId2, flag] = /^meeting\/(\d+)\/(\d+)\/flag\/(\d+)$/.exec(
                        topic,
                    )
                    if (meetingId2 === meetingId) {
                        const studentId = parseInt(studentId2)
                        const iFlag = parseInt(flag)
                        const value = JSON.parse(payload)
                        // console.log("Received flag:", flag, payload);
                        // setFlags(flags.concat(flag))
                        // setFlags((flags) => ({...flags, [parseInt(studentId2)]: {JSON.parse(payload)}))
                        setFlags((flags) => {
                            const newFlags = {...flags}
                            newFlags[studentId] = {...(newFlags[studentId] || {}), [iFlag]: value}
                            return newFlags
                        })
                        if (value) fireBeep()
                    } else {
                        // console.log("Received flag for another meeting", meetingId2, meetingId);
                    }
                } else if (/^meeting\/(\d+)\/(\d+)\/status$/.test(topic)) {
                    const [, meetingId2, studentId2] = /^meeting\/(\d+)\/(\d+)\/status$/.exec(topic)
                    if (meetingId2 === meetingId) {
                        const studentId = parseInt(studentId2)
                        const value = JSON.parse(payload)
                        // console.log("Received flag:", flag, payload);
                        // setFlags(flags.concat(flag))
                        // setFlags((flags) => ({...flags, [parseInt(studentId2)]: {JSON.parse(payload)}))
                        setFlags((flags) => {
                            const newFlags = {...flags}
                            newFlags[studentId] = {...(newFlags[studentId] || {}), [CONNECTED_FLAG]: value}
                            return newFlags
                        })
                        if (value) fireBeep()
                    } else {
                        // console.log("Received flag for another meeting", meetingId2, meetingId);
                    }
                } else if (/^meeting\/(\d+)\/(\d+)\/bars$/.test(topic)) {
                    const [, meetingId2, studentId2] = /^meeting\/(\d+)\/(\d+)\/bars$/.exec(topic)
                    if (meetingId2 === meetingId) {
                        const studentId = parseInt(studentId2)
                        const value = JSON.parse(payload)
                        console.log("Received bars:", studentId, value)
                        setFlags((flags) => {
                            const newFlags = {...flags}
                            newFlags[studentId] = {...(newFlags[studentId] || {}), [BARS]: value}
                            return newFlags
                        })
                    }
                } else if (/^meeting\/(\d+)\/(\d+)\/thermometer$/.test(topic)) {
                    const [, meetingId2, studentId2] = /^meeting\/(\d+)\/(\d+)\/thermometer$/.exec(topic)
                    if (meetingId2 === meetingId) {
                        const studentId = parseInt(studentId2)
                        const value = JSON.parse(payload)
                        console.log("Received thermometer:", studentId, value)
                        setFlags((flags) => {
                            const newFlags = {...flags}
                            newFlags[studentId] = {...(newFlags[studentId] || {}), [THERMOMETER]: value}
                            return newFlags
                        })
                    }
                }
            }
        }

        addMessageListener(listener)
        enableWebsocket()
        return () => {
            setSubscribable(false)
            removeMessageListener(listener)
        }
    }, [addMessageListener, attendace_topic, enableWebsocket, removeMessageListener, subscribe])

    const toggleNavigatePenalty = (penaltyEnabled) => {
        const action = penaltyEnabled ? 'toggle_penalty_on' : 'toggle_penalty_off';
        api.meetingAction(meetingId, action).handle({
            successMessage: `Navigate Away Penalty ${penaltyEnabled ? 'enabled' : 'disabled'}`,
            errorMessage: `Error ${penaltyEnabled ? 'enabling' : 'disabling'} Navigate Away Penalty`,
        });
    };

    return (
        <>
            <Grid
                container
                display="flex"
                py={1}
                gap={2}
                borderBottom="1px solid rgba(0, 0, 0, 0.25)"
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Grid container item xs={12} md={3}>
                    <MDTypography flex={6} variant="h3" color="primary" fontWeight="regular">
                        Class Management
                    </MDTypography>
                </Grid>
                <Grid
                    container
                    item
                    gap={2}
                    xs={12}
                    md={"auto"}
                    direction={{xs: "column", md: "row"}}
                    justifyContent={"end"}
                >
                    <MDButton
                        circular
                        variant="contained"
                        onClick={() => triggerEngagement()}
                        color={"primary"}
                    >
                        Measure Engagement Now
                    </MDButton>
                    <MDButton
                        circular
                        variant="contained"
                        onClick={() => toggleAutoEngagement()}
                        color={autoEngagement ? "primary" : "secondary"}
                        // style={{ display: 'flex', alignItems: 'center' }}
                    >
                        {autoEngagement ? (
                            <PlayArrow sx={{fontSize: 19}}/>
                        ) : (
                            <Icon sx={{fontSize: 19}}>pause</Icon>
                        )}
                        Engagement Auto Measurement: {autoEngagement ? "ON" : "OFF"}
                    </MDButton>
                </Grid>
                {/* =============== TABS =============== */}
                <Grid
                    container
                    item
                    gap={2}
                    xs={12}
                    md={"auto"}
                    direction={"row"}
                    height={"fit-content"}
                    wrap={"nowrap"}
                    justifyContent={{xs: "space-between", md: "flex-end"}}
                >
                    <MDButton
                        variant="contained"
                        onClick={() => setSelectedTab("management")}
                        color={selectedTab === "management" ? "primary" : undefined}
                    >
                        Management
                    </MDButton>
                    <MDButton
                        variant="contained"
                        onClick={() => setSelectedTab("students")}
                        color={selectedTab === "students" ? "primary" : undefined}
                    >
                        Students
                    </MDButton>
                    {questions && classModeIsQuestions && (
                        <MDButton
                            variant="contained"
                            onClick={() => setSelectedTab("questions")}
                            color={selectedTab === "questions" ? "primary" : undefined}
                        >
                            Questions
                        </MDButton>
                    )}
                    {taskGroups && classModeIsTasks && (
                        <MDButton
                            variant="contained"
                            onClick={() => setSelectedTab("tasks")}
                            color={selectedTab === "tasks" ? "primary" : undefined}
                        >
                            Tasks
                        </MDButton>
                    )}
                    {classModeIsDiscussion && (
                        <MDButton
                            variant="contained"
                            onClick={() => setSelectedTab("discussions")}
                            color={selectedTab === "discussions" ? "primary" : undefined}
                        >
                            Discussions
                        </MDButton>
                    )}
                    <MDButton
                        variant="contained"
                        onClick={() => setSelectedTab("leader")}
                        color={selectedTab === "leader" ? "primary" : undefined}
                    >
                        Leader board
                    </MDButton>
                </Grid>
            </Grid>
            {/*=============== SCREENS =============== */}
            {selectedTab === "students" && (
                <StudentsTab
                    balances={balances_state}
                    attendanceStatus={attendanceStatus}
                    students={students}
                    setSelectedStudent={setSelectedStudent}
                    selectedStudent={selectedStudent}
                    flags={flags}
                    stats={stats}
                />
            )}
            {selectedTab === "tasks" && (
                <TaskGroupsTab
                    taskGroups={taskGroups}
                    studentTasks={studentTasks}
                    loading={taskGroupsLoading || studentTasksLoading}
                />
            )}
            {selectedTab === "discussions" && (
              <DiscussionsTab
                attendanceStatus={attendanceStatus}
                balances={balances_state}
              />
            )}
            {selectedTab === "questions" && <QuestionsTab questions={questions} stats={stats}/>}
            {selectedTab === "management" && (
                <ManagementTab
                    start={meetingStart}
                    end={meetingEnd}
                    classMode={classMode}
                    setClassMode={setClassMode}
                    navigatePenaltyOn={navigatePenaltyOn}
                    setNavigatePenaltyOn={toggleNavigatePenalty}
                    pauseAutoEngagement={pauseAutoEngagement}
                    continueAutoEngagement={continueAutoEngagement}
                />
            )}
            {selectedTab === "leader" && <LeaderboardTab updateLeaderBoard={statsUpdate}/>}
        </>
    )
}

export default TeachersAdminPanel
