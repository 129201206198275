

const Day = {
  MONDAY:'MONDAY',
  TUESDAY:'TUESDAY',
  WEDNESDAY:'WEDNESDAY',
  THURSDAY:'THURSDAY',
  FRIDAY:'FRIDAY',
  SATURDAY:'SATURDAY',
  SUNDAY:'SUNDAY'
}

const DAYS  = [
  Day.MONDAY,
  Day.TUESDAY,
  Day.WEDNESDAY,
  Day.THURSDAY,
  Day.FRIDAY,
  Day.SATURDAY,
  Day.SUNDAY
]
const CalendarView = {
  DAY: "day",
  WEEK: "week",
  MONTH: "month"
};


export {CalendarView, DAYS, Day}
