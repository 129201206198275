import React, {useEffect, useState} from "react";
import PageLayout from "components/PageLayout";
import FeedbackModal from "./FeedbackModal";
import {useApi} from "../../services/helpers";
import {useApiMessages} from "../../services/api/api-messages";
import {ROUTES} from "../../services/constants";
import {useNavigate} from "react-router-dom";

const Feedback = () => {
  const [showModal, setShowModal] = useState(true)
  const api = useApi();
  const message = useApiMessages('message', 'messages')
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState(false);

  const listFeedbacks = () => {
    setLoading(true)
    api.listFeedbacks().handle({
      onSuccess: (res) => {
        console.log(res)
        setMessages(res.data)
        console.log("the messages are:",res.data.results)
      },
      errorMessage: message.create.error,
      onFinally: () => setLoading(false)
    });
  }

  const createFeedback = (data) => {
    setLoading(true)
    api.createFeedback(data).handle({
      onSuccess: (res) => {
        console.log(res.data)
        listFeedbacks()
      },
      errorMessage: message.create.error,
      onFinally: () => setLoading(false)
    });
  }

  useEffect(() => {
    listFeedbacks();
  }, []);



  return (
    <PageLayout
      teacherComponent={<FeedbackModal showModal={showModal} handleClose={() => {
        setShowModal(false)
        navigate(ROUTES.ROOT)
      }} messages={messages} postMessage={createFeedback}/>}
      studentComponent={<FeedbackModal showModal={showModal} handleClose={() => {
        setShowModal(false)
        navigate(ROUTES.ROOT)
      }} messages={messages} postMessage={createFeedback}/>}
    />
  );
};

export default Feedback;
