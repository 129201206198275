import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {RealtimeContext} from "../../services/streaming/provider";
import PageLayout from "../../components/PageLayout";
import {Box, Icon, IconButton} from "@mui/material";
import thumbsUp from "../../assets/icons/thumbs-up.svg";
import handsUp from "../../assets/icons/hands-up.svg";
import thumbsDown from "../../assets/icons/thumbs-down.svg";
import attendance from "../../assets/images/attendance.png";
import { QrReader } from 'react-qr-reader';

const MeetingEnter = observer(() => {
    // const { startCapture, sharedStream } = React.useContext(RealtimeContext);
    // const [showStream , setShowStream] = useState(false)
    // const videoRef = React.useRef(null)
    // const videoConnected = false
    //
    // // useEffect(() => {
    // //     if (showStream){
    // //         console.log(sharedStream)
    // //         videoRef.current.srcObject = sharedStream
    // //     }
    // // },[showStream, sharedStream])
    //
    // return (
    //     <Box height="100vh" width="100%">
    //         {videoConnected ? <video ref={videoRef} autoPlay playsInline width='100%' height='100%' style={{backgroundColor: "black"}}></video> : null}
    //
    //         <Box sx={{position: "absolute", bottom: 0, left: 0, right: 0, display: "flex", justifyContent: "center", alignItems: "center", height: "100px"}}>
    //             <img src={attendance} alt="attendance" style={{height: "50px", width: "50px", marginRight: "20px"}}/>
    //         </Box>
    //     </Box>
    //
    // );

    const [data, setData] = useState('No result');

    return (
        <>
            <QrReader
                onResult={(result, error) => {
                    if (!!result) {
                        setData(result?.text);
                    }

                    if (!!error) {
                        console.info(error);
                    }
                }}
                style={{ width: '100%' }}
             constraints={{}}/>
            <p>{data}</p>
        </>
    );

});

export default MeetingEnter;
