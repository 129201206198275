import React, { useState } from "react"
import { Box, Collapse, Divider, IconButton, List, ListItem, ListItemText } from "@mui/material"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import MDTypography from "../../../components/MDTypography"

const CollapsibleList = ({ title, items, ...props }) => {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <Box {...props}>
      <MDTypography
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: "1.25rem",
          fontWeight: "medium",
        }}
        variant="h6"
        color="dark"
        fontWeight="regular"
        gutterBottom
      >
        {title}
        <IconButton onClick={handleClick}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </MDTypography>
      <Collapse in={open}>
        <List sx={{marginLeft: 2}}>
          {items.map((item, index) => (
            <div key={index}>
              <ListItem>
                <ListItemText primary={item} primaryTypographyProps={{ fontSize: "1rem" }} />
              </ListItem>
              {index < items.length - 1 && <Divider />}
            </div>
          ))}
        </List>
      </Collapse>
    </Box>
  )
}

export default CollapsibleList
