import ModalItem from "components/ModalItem"
import { useEffect, useRef, useState } from "react"
import { useApi } from "../../services/helpers"
import * as Yup from "yup"
import MDBox from "../../components/MDBox"
import LessonBankConfirm from "./components/LessonBankConfirm"
import LessonBankList from "./components/LessonBankList"


const LESSON_BANK_STEPS = {
  SELECT: 1,
  CONFIRM: 2,
}
const LessonBankModal = ({ showModal, courseSelected, handleClose, onCreated }) => {
  const api = useApi()
  const [currentStep, setCurrentStep] = useState(LESSON_BANK_STEPS.SELECT)
  const [selectedLessonId, setSelectedLessonId] = useState(null)
  const [selectedCourseLessonId, setSelectedCourseLessonId] = useState(null)
  const selectLessonToImport = (lesson) => {
    setSelectedLessonId(lesson.id)
    setSelectedCourseLessonId(lesson.course?.id)
    setCurrentStep(LESSON_BANK_STEPS.CONFIRM)
  }

  const cancelLessonImport = () => {
    setCurrentStep(LESSON_BANK_STEPS.SELECT)
    setSelectedLessonId(null)
  }

  const onCloseModal = () => {
    handleClose()
    setCurrentStep(LESSON_BANK_STEPS.SELECT)
    setSelectedLessonId(null)
  }

  return (
    <ModalItem
      scrollable={false}
      open={showModal}
      closeOnClickOutside={false}
      height="auto"
      title={currentStep === LESSON_BANK_STEPS.SELECT ? "Lesson Bank" : "Import Lesson"}
      handleClose={() => onCloseModal()}
      width={"100%"}
      contentContainterStyle={{width: "80%"}}
    >
      <MDBox width={"100%"} >
        {/* SELECT LESSON TO IMPORT STEP */}
        {currentStep === LESSON_BANK_STEPS.SELECT &&
          <MDBox>
            <LessonBankList
              selectedLessonId={selectedLessonId}
              selectLessonToImport={selectLessonToImport}
            />
          </MDBox>
        }
        {/* CONFIRM LESSON STEP */}
        {currentStep === LESSON_BANK_STEPS.CONFIRM &&
          <LessonBankConfirm
            lessonId={selectedLessonId}
            lessonCourseId={selectedCourseLessonId}
            cancelLessonImport={cancelLessonImport}
            onSuccessCallback={() => {
              onCloseModal()
              !!onCreated && onCreated()
            }}
          />
        }
      </MDBox>
    </ModalItem>
  )
}

export default LessonBankModal
