import React from "react";
import PageLayout from "components/PageLayout";
import TeachersMarketplace from "./TeachersMarketplace";

const Marketplace = () => {
  return (
    <PageLayout teacherComponent={<TeachersMarketplace />} studentComponent={"Not implemented"} />
  );
};

export default Marketplace;
