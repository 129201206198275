import React from "react";
import PageLayout from "components/PageLayout";
import StudentsEarningHistory from "./StudentsEarningHistory";

const EarningHistory = () => {
  return (
    <PageLayout
      teacherComponent={"TeachersQuestionnaire "}
      studentComponent={<StudentsEarningHistory />}
    />
  );
};

export default EarningHistory;
