import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Box, Typography } from "@mui/material";
import MDBox from "../../components/MDBox";
import increaseArrow from "../../assets/icons/increaseArrow.svg";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export function StudentsEngagement({width, height}) {
  const options = {
    responsive: true,
    maintainAspectRatio: false, // This allows you to control the chart's aspect ratio
    scales: {
      x: { stacked: true },
      y: {
        beginAtZero: true,
        min: 0, // Set the minimum value for the y-axis
        max: 100, // Set the maximum value for the y-axis
        ticks: {
          stepSize: 10, // Set the interval between y-axis ticks
        },
      },
    },

    pointRadius: 0,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Student Engagement",
      },
    },
  };

  const labels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: labels.map(() => Math.random() * 100),
        backgroundColor: "#6A994E",
        borderColor: "white",
        borderWidth: "5",
      },
      {
        label: "Dataset 2",
        data: labels.map(() => Math.random() * 100),
        backgroundColor: "#6A994E",
        borderColor: "white",
        borderWidth: "5",
      },
      {
        label: "Dataset 3",
        data: labels.map(() => Math.random() * 100),
        backgroundColor: "#6A994E",
        borderColor: "white",
        borderWidth: "5",
      },
      {
        label: "Dataset 4",
        data: labels.map(() => Math.random() * 100),
        backgroundColor: "#6A994E",
        borderColor: "white",
        borderWidth: "5",

      },
    ],
  };
  return (
    <Box borderRadius={2} boxShadow={2} p={2} mb={3} height={height} width={width}>
      <Typography fontSize={18}>Student Engagement</Typography>
      <Box display="flex" gap={"4px"}  alignItems={"center"}>
        <Typography fontSize={22}>65.71%</Typography>
        <MDBox component={"img"} src={increaseArrow}/>
        <Typography fontSize={"14px"} fontWeight="bold" sx={{color: "#119D7F"}}>
          5%
        </Typography>
      </Box>
      <Typography fontSize={16}>Data Visualization</Typography>
      <Box height="250px">
        <Bar options={options} data={data} />
      </Box>
    </Box>
  );
}

export function StudentUnderstanding({width, height}) {
  const options = {
    responsive: true,
    maintainAspectRatio: false, // This allows you to control the chart's aspect ratio
    scales: {
      x: { stacked: true },
      y: {
        beginAtZero: true,
        min: 0, // Set the minimum value for the y-axis
        max: 100, // Set the maximum value for the y-axis
        ticks: {
          stepSize: 10, // Set the interval between y-axis ticks
        },
      },
    },

    pointRadius: 0,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Student Engagement",
      },
    },
  };

  const labels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: labels.map(() => Math.random() * 100),
        backgroundColor: "#6A994E",
        borderColor: "white",
        borderWidth: "5",
      },
      {
        label: "Dataset 2",
        data: labels.map(() => Math.random() * 100),
        backgroundColor: "#6A994E",
        borderColor: "white",
        borderWidth: "5",
      },
      {
        label: "Dataset 3",
        data: labels.map(() => Math.random() * 100),
        backgroundColor: "#6A994E",
        borderColor: "white",
        borderWidth: "5",
      },
      {
        label: "Dataset 4",
        data: labels.map(() => Math.random() * 100),
        backgroundColor: "#6A994E",
        borderColor: "white",
        borderWidth: "5",

      },
    ],
  };
  return (
    <Box borderRadius={2} boxShadow={2} p={2} height={height} width={width}>
      <Typography fontSize={18}>Student Understanding</Typography>
      <Box display="flex" gap={"4px"}  alignItems={"center"}>
        <Typography fontSize={22}>45.71%</Typography>
        <MDBox component={"img"} src={increaseArrow}/>
        <Typography fontSize={"14px"} fontWeight="bold" sx={{color: "#119D7F"}}>
          5%
        </Typography>
      </Box>
      <Typography fontSize={16}>Data Visualization</Typography>
      <Box height="250px">
        <Bar options={options} data={data} />
      </Box>
    </Box>
  );
}
