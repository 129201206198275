import { ApiConfig, DEFAULT_API_CONFIG } from './api-config'
import * as Types from './api.types'
import { ApiBase, ApiReturnType } from './api-base'
import { API_VERSION_PREFIX } from '../constants'
import { MeetingActionsType, StudentFeedbackType, StudentFlagsType } from '../constants_ts'

/**
 * Manages all requests to the API.
 */
export class Api extends ApiBase {
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    super(config)
  }

  login(username: string, password: string): ApiReturnType<Types.SimplePostResult> {
    return this.simple_post(`${API_VERSION_PREFIX}/authenticate/`, {
      email: username,
      password,
    })
  }
  sigup(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.simple_post(`${API_VERSION_PREFIX}/register/`, data)
  }
  getUserInfo() {
    return this.simple_get(`${API_VERSION_PREFIX}/user/`)
  }

  forgotPassword(email: string): ApiReturnType<Types.GenericResponse> {
    return this.simple_post(`${API_VERSION_PREFIX}/reset-password/`, { email: email })
  }

  resetPassword(data: any): ApiReturnType<Types.GenericResponse> {
    return this.simple_post(`${API_VERSION_PREFIX}/set-new-password/`, data)
  }

  changePassword(data: any): ApiReturnType<Types.GenericResponse> {
    return this.simple_post(`${API_VERSION_PREFIX}/change-password/`, data)
  }

  activateAccount(data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/activate-user/`, data)
  }

  getTermsConditions(): ApiReturnType<Types.SimpleGetResult> {
    return this.simple_get(`${API_VERSION_PREFIX}/modules/terms-and-conditions/`)
  }

  getPrivacyPolicy(): ApiReturnType<Types.SimpleGetResult> {
    return this.simple_get(`${API_VERSION_PREFIX}/modules/privacy-policy/`)
  }

  getAllUsers(data: any): ApiReturnType<Types.SimpleGetResult> {
    return this.simple_get(`${API_VERSION_PREFIX}/users/`, data)
  }

  deleteUser(id: number): ApiReturnType<Types.GenericResponse> {
    return this.simple_delete(API_VERSION_PREFIX + `/users/${id}/`)
  }

  setDevice(data: any) {
    return this.simple_post(`${API_VERSION_PREFIX}/set-device/`, data)
  }

  getCalendarEvents(data: any): ApiReturnType<Types.SimpleGetResult> {
    return this.simple_get(`${API_VERSION_PREFIX}/teacher/calendar/day/`, data)
  }

  getCalendarRangeEvents(data: any): ApiReturnType<Types.SimpleGetResult> {
    return this.simple_get(`${API_VERSION_PREFIX}/teacher/calendar/range/`, data)
  }

  getCourses(data: any): ApiReturnType<Types.SimpleGetResult> {
    return this.simple_get(`${API_VERSION_PREFIX}/teacher/course/`, data)
  }

  getLessons(data: any, id: number): ApiReturnType<Types.SimpleGetResult> {
    return this.simple_get(`${API_VERSION_PREFIX}/teacher/course/${id}/lesson/`, data)
  }

  searchQuestions(data: any): ApiReturnType<Types.SimpleGetResult> {
    return this.simple_get(`${API_VERSION_PREFIX}/teacher/course/search_questions/`, data)
  }

  createCourseMeeting(data: any) {
    return this.simple_post(
      `${API_VERSION_PREFIX}/teacher/course/${data?.course?.id}/meeting/`,
      data
    )
  }

  updateCourseMeeting(data: any) {
    return this.simple_patch(
      `${API_VERSION_PREFIX}/teacher/course/${data?.course?.id}/meeting/${data?.id}/`,
      data
    )
  }

  deleteCourseMeeting(
    courseId: number,
    meetingId: number,
    data: any
  ): ApiReturnType<Types.GenericResponse> {
    return this.simple_delete(
      API_VERSION_PREFIX + `/teacher/course/${courseId}/meeting/${meetingId}/`,
      data
    )
  }

  listCourseMeetings(courseId: number, data: any): ApiReturnType<Types.GenericResponse> {
    return this.simple_get(API_VERSION_PREFIX + `/teacher/course/${courseId}/meeting/`, data)
  }

  updateTeacherProfile(data: any) {
    return this.patch_collected_multipart_form_data(`${API_VERSION_PREFIX}/teacher/profile/`, data)
  }

  updateStudentProfile(data: any) {
    return this.patch_collected_multipart_form_data(`${API_VERSION_PREFIX}/student/profile/`, data)
  }

  getTeachers(data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/teacher/teacher/`, data)
  }

  getCoursesByTeacherId(id: number, data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/teacher/teacher/${id}/courses/`, data)
  }

  createTeacherCourse(data: any) {
    return this.post_collected_multipart_form_data(`${API_VERSION_PREFIX}/teacher/course/`, data)
  }

  getTeacherCourses() {
    return this.simple_get(`${API_VERSION_PREFIX}/teacher/course/`)
  }

  getTeacherCourseById(id: number) {
    return this.simple_get(`${API_VERSION_PREFIX}/teacher/course/${id}/`)
  }

  inviteCourse(courseId: number, data: any) {
    return this.simple_post(`${API_VERSION_PREFIX}/teacher/course/${courseId}/invite/`, data)
  }

  getStudentsEnrolment(courseId: number) {
    return this.simple_get(`${API_VERSION_PREFIX}/teacher/course/${courseId}/enrolment/`)
  }

  getStudentsOfCourse(courseId: number) {
    return this.simple_get(`${API_VERSION_PREFIX}/teacher/course/${courseId}/enrolment/students/`)
  }

  acceptStudentEnrolment(courseId: number, enrolmentId: number) {
    return this.simple_post(
      `${API_VERSION_PREFIX}/teacher/course/${courseId}/enrolment/${enrolmentId}/accept/`
    )
  }

  denyStudentEnrolment(courseId: number, enrolmentId: number) {
    return this.simple_delete(
      `${API_VERSION_PREFIX}/teacher/course/${courseId}/enrolment/${enrolmentId}/`
    )
  }

  getStudentCourses() {
    return this.simple_get(`${API_VERSION_PREFIX}/student/course/`)
  }

  getCourseNearestMeetingAttendance(courseId: number) {
    return this.simple_get(
      `${API_VERSION_PREFIX}/student/course/${courseId}/nearest_meeting_attendance/`
    )
  }

  getStudentEnrolmentCourses() {
    return this.simple_get(`${API_VERSION_PREFIX}/student/enrolment/`)
  }

  getStudentInvitedCourses() {
    return this.simple_get(`${API_VERSION_PREFIX}/student/enrolment/invitations/`)
  }

  studentAcceptInvite(enrolmentId: number) {
    return this.simple_post(`${API_VERSION_PREFIX}/student/enrolment/${enrolmentId}/accept/`)
  }

  studentRejectInvite(enrolmentId: number) {
    return this.simple_delete(`${API_VERSION_PREFIX}/student/enrolment/${enrolmentId}/`)
  }

  getStudentRequestedCourses() {
    return this.simple_get(`${API_VERSION_PREFIX}/student/enrolment/requests/`)
  }

  getLessonsByCourse(courseId: number) {
    return this.simple_get(`${API_VERSION_PREFIX}/teacher/course/${courseId}/lesson/`)
  }

  getLesson(courseId: number, lessonId: number) {
    return this.simple_get(`${API_VERSION_PREFIX}/teacher/course/${courseId}/lesson/${lessonId}/`)
  }

  createLesson(courseId: number, data: any) {
    return this.simple_post(`${API_VERSION_PREFIX}/teacher/course/${courseId}/lesson/`, data)
  }

  updateLesson(courseId: number, lessonId: number, data: any) {
    return this.simple_patch(
      `${API_VERSION_PREFIX}/teacher/course/${courseId}/lesson/${lessonId}/`,
      data
    )
  }
  searchLessons(data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/teacher/course/search_lessons/`, data)
  }

  getLessonBankDetail(courseId: number, lessonId: number, data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/teacher/course/${courseId}/lesson/${lessonId}/bank_detail/`, data)
  }

  importLessonBank(courseId: number, data: any) {
    return this.simple_post(`${API_VERSION_PREFIX}/teacher/course/${courseId}/import_lesson/`, data)
  }
  getSpecificCourseMeetingByCourse(courseId: number, meetingId: number) {
    return this.simple_get(`${API_VERSION_PREFIX}/teacher/course/${courseId}/meeting/${meetingId}/`)
  }

  getCourseNearestMeetings(courseId: number, instantMeetingId: number) {
    let url = `${API_VERSION_PREFIX}/teacher/course/${courseId}/meeting/nearest_meetings/`
    if (instantMeetingId) {
      url = url + `?instantMeetingId=${instantMeetingId}`
    }
    return this.simple_get(url)
  }

  searchMeetings(search: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/teacher/search_meeting/`, search)
  }

  getNearestActivities() {
    return this.simple_get(`${API_VERSION_PREFIX}/teacher/course/nearest_activities/`)
  }

  getCourseGeneralStatistics(courseId: number, data: any) {
    return this.simple_get(
      `${API_VERSION_PREFIX}/teacher/course/${courseId}/statistics/general/`,
      data
    )
  }

  getCourseStudentStatistics(courseId: number, studentId: number, data: any) {
    return this.simple_get(
      `${API_VERSION_PREFIX}/teacher/course/${courseId}/statistics/student/${studentId}/`,
      data
    )
  }

  createCourseLessonQuestion(courseId: number, lessonId: number, data: any) {
    return this.simple_post(
      `${API_VERSION_PREFIX}/teacher/course/${courseId}/lesson/${lessonId}/question/`,
      data
    )
  }

  updateCourseLessonQuestionOrder(courseId: number, lessonId: number, data: any) {
    return this.simple_post(
      `${API_VERSION_PREFIX}/teacher/course/${courseId}/lesson/${lessonId}/question/update-custom-order/`,
      data
    )
  }

  updateCourseLessonQuestion(courseId: number, lessonId: number, questionId: number, data: any) {
    return this.simple_patch(
      `${API_VERSION_PREFIX}/teacher/course/${courseId}/lesson/${lessonId}/question/${questionId}/`,
      data
    )
  }

  retrieveCourseLessonQuestion(courseId: number, lessonId: number, questionId: number, data: any) {
    return this.simple_get(
      `${API_VERSION_PREFIX}/teacher/course/${courseId}/lesson/${lessonId}/question/${questionId}/`,
      data
    )
  }

  listCourseLessonQuestions(courseId: number, lessonId: number) {
    return this.simple_get(
      `${API_VERSION_PREFIX}/teacher/course/${courseId}/lesson/${lessonId}/question/`
    )
  }

  deleteCourseLessonQuestion(
      courseId: number,
      lessonId: number,
      questionId: number,
  ): ApiReturnType<Types.GenericResponse> {
    return this.simple_delete(`${API_VERSION_PREFIX}/teacher/course/${courseId}/lesson/${lessonId}/question/${questionId}/`,)
  }

  // COURSE LESSON TASK GROUP
  listCourseLessonTaskGroups(courseId: number, lessonId: number) {
    return this.simple_get(
      `${API_VERSION_PREFIX}/teacher/course/${courseId}/lesson/${lessonId}/task-group/`
    )
  }

  updateCourseLessonTaskGroupOrder(courseId: number, lessonId: number, data: any) {
    return this.simple_post(
      `${API_VERSION_PREFIX}/teacher/course/${courseId}/lesson/${lessonId}/task-group/update-custom-order/`,
      data
    )
  }

  createCourseLessonTaskGroup(courseId: number, lessonId: number, data: any) {
    return this.simple_post(
      `${API_VERSION_PREFIX}/teacher/course/${courseId}/lesson/${lessonId}/task-group/`,
      data
    )
  }

  updateCourseLessonTaskGroup(courseId: number, lessonId: number, taskGroupId: number, data: any) {
    return this.simple_patch(
      `${API_VERSION_PREFIX}/teacher/course/${courseId}/lesson/${lessonId}/task-group/${taskGroupId}/`,
      data
    )
  }
  ///
  get_QR_info(meeting_id: number) {
    return this.simple_get(`${API_VERSION_PREFIX}/meeting/teacher/${meeting_id}/get_qrcode/`)
  }

  getAttendanceStatus(meeting_id: number) {
    return this.simple_get(`${API_VERSION_PREFIX}/meeting/teacher/${meeting_id}/attendance_status/`)
  }

  getMeetingStatus(meeting_id: number) {
    return this.simple_get(`${API_VERSION_PREFIX}/meeting/teacher/${meeting_id}/status/`)
  }

  markAttendance(code: string) {
    return this.simple_post(`${API_VERSION_PREFIX}/meeting/student/mark_attendance/`, { code })
  }

  overrideAttendance(meeting_id: number, data: any) {
    return this.simple_post(
      `${API_VERSION_PREFIX}/meeting/teacher/${meeting_id}/attendance_override/`,
      data
    )
  }

  getMeetingInfo(meeting_id: number) {
    return this.simple_get(`${API_VERSION_PREFIX}/meeting/student/${meeting_id}/info/`)
  }

  meetingAction(meeting_id: number, action: MeetingActionsType) {
    return this.simple_post(`${API_VERSION_PREFIX}/meeting/teacher/${meeting_id}/meeting_action/`, {
      action,
    })
  }

  askQuestion(meeting_id: number, question_id: number) {
    return this.simple_post(
      `${API_VERSION_PREFIX}/meeting/teacher/${meeting_id}/ask_question/${question_id}/`,
      {}
    )
  }

  getTaskGroup(meeting_id: number, task_group_id: number) {
    return this.simple_get(
      `${API_VERSION_PREFIX}/meeting/student/${meeting_id}/task_group/${task_group_id}/`
    )
  }

  sendTaskGroup(meeting_id: number, task_group_id: number) {
    return this.simple_post(
      `${API_VERSION_PREFIX}/meeting/teacher/${meeting_id}/send_task_group/${task_group_id}/`,
      {}
    )
  }

  submitTask(meeting_id: number, data: any) {
    return this.post_collected_multipart_form_data(
      `${API_VERSION_PREFIX}/meeting/student/${meeting_id}/submit_task/`,
      data
    )
  }

  sendTaskApproval(meeting_id: number, data: any) {
    return this.simple_post(
      `${API_VERSION_PREFIX}/meeting/teacher/${meeting_id}/send_task_approval/`,
      data
    )
  }

  manualBucks(meeting_id: number, data: any) {
    return this.simple_post(
      `${API_VERSION_PREFIX}/meeting/teacher/${meeting_id}/manual_bucks/`,
      data
    )
  }

  meetingRandomCalling(meeting_id: number, data: any) {
    return this.simple_post(`${API_VERSION_PREFIX}/meeting/teacher/${meeting_id}/random_calling/`, data)
  }

  meetingStats(meeting_id: number) {
    return this.simple_get(`${API_VERSION_PREFIX}/meeting/teacher/${meeting_id}/stats/`)
  }

  meetingStudentTasks(meeting_id: number) {
    return this.simple_get(`${API_VERSION_PREFIX}/meeting/teacher/${meeting_id}/student-tasks/`)
  }

  meetingStudentsAnswers(meeting_id: number, question_id: number, data: any) {
    return this.simple_get(
      `${API_VERSION_PREFIX}/meeting/teacher/${meeting_id}/question/${question_id}/answers/`,
      data
    )
  }

  meetingLeaderboard(meeting_id: number, course: boolean = false) {
    return this.simple_get(`${API_VERSION_PREFIX}/meeting/teacher/${meeting_id}/leaderboard/`, {
      course,
    })
  }

  sendAnswer(meeting_id: number, data: any) {
    return this.simple_post(
      `${API_VERSION_PREFIX}/meeting/student/${meeting_id}/submit_response/`,
      data
    )
  }

  sendEngagement(meeting_id: number, data: any) {
    return this.simple_post(
      `${API_VERSION_PREFIX}/meeting/student/${meeting_id}/submit_engagement/`,
      data
    )
  }

  manageFlag(meeting_id: number, flag: StudentFlagsType, value: boolean) {
    return this.simple_post(`${API_VERSION_PREFIX}/meeting/student/${meeting_id}/manage_flag/`, {
      flag,
      value,
    })
  }

  userNavigatedAway(meeting_id: number) {
    return this.simple_post(`${API_VERSION_PREFIX}/meeting/student/${meeting_id}/navigated_away/`)
  }

  sendMeetingFeedback(meeting_id: number, feedback: StudentFeedbackType) {
    return this.simple_post(`${API_VERSION_PREFIX}/meeting/student/${meeting_id}/feedback/`, {
      feedback,
    })
  }

  triggerEngagement(meeting_id: number) {
    return this.simple_post(
      `${API_VERSION_PREFIX}/meeting/teacher/${meeting_id}/trigger_engagement/`,
      {}
    )
  }

  listFeedbacks() {
    return this.simple_get(`${API_VERSION_PREFIX}/feedback/`)
  }

  createFeedback(data: any) {
    return this.simple_post(`${API_VERSION_PREFIX}/feedback/`, data)
  }

  updateTeacherCourseProfile(couseId: number, data: any) {
    return this.patch_collected_multipart_form_data(
      `${API_VERSION_PREFIX}/teacher/course/${couseId}/`,
      data
    )
  }

  getExportOptions() {
    return this.simple_get(`${API_VERSION_PREFIX}/teacher/export/`)
  }

  downloadCourseExport(data: any) {
    return this.download_file_post(`${API_VERSION_PREFIX}/teacher/export/download/`, data)
  }

  getEarningsHistory() {
    return this.simple_get(`${API_VERSION_PREFIX}/student/earnings-history/`)
  }

  generateAiQuestions(courseId: number, data: any) {
    return this.simple_post(`${API_VERSION_PREFIX}/teacher/course/${courseId}/lesson/ai_assistant/`, data);
  }
  getBucksPresets() {
    return this.simple_get(`${API_VERSION_PREFIX}/bucks/buck-preset/`, )
  }

  listMeetingDiscussions(meetingId: number) {
    return this.simple_get(`${API_VERSION_PREFIX}/meeting/teacher/${meetingId}/interaction_history/` )
  }

  getInteractionsSuggestions(meetingId: number, search?:string) {
    return this.simple_get(`${API_VERSION_PREFIX}/meeting/teacher/${meetingId}/interactions_suggestions/`, {search})
  }

  recordInteraction(meetingId: number, data: any) {
    return this.simple_post(`${API_VERSION_PREFIX}/meeting/teacher/${meetingId}/record_interaction/`, data )
  }

  directAccessInteractions(meetingId: number) {
    return this.simple_get(`${API_VERSION_PREFIX}/meeting/teacher/${meetingId}/direct_access_interactions/`)
  }
}
