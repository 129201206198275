import { Box, Checkbox, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import MDTypography from "components/MDTypography";
import ModalItem from "components/ModalItem";
import { Form, Formik, useFormikContext } from "formik";
import React, { useRef } from "react";
import dpad from "../../assets/images/dpad.svg";
import buttons from "../../assets/images/game-buttons.svg";
import { ArrowDropDown, ArrowDropUp, ArrowLeft, ArrowRight } from "@mui/icons-material";
import {
  TYPE_OF_QUESTIONNAIRE_OPTIONS,
  TYPE_OF_INTERACTION_OPTIONS,
  BUTTON_TYPE,
} from "../../utils/helpers";
import PaidIcon from "@mui/icons-material/Paid";
import MDBox from "../../components/MDBox";
import { MathJax } from "better-react-mathjax";
import './scrollbar.css'
import MDButton from "../../components/MDButton";
import {useApi} from "../../services/helpers";
import {useParams} from "react-router-dom";
const QuestionViewModal = ({ showModal, handleClose, question, deleteMode }) => {
  const formikRef = useRef();
  const api = useApi();
  const { courseId } = useParams();

  const initialValues = {
    prompt: question?.prompt || "",
    question_type: question?.question_type || TYPE_OF_QUESTIONNAIRE_OPTIONS.OPEN,
    answer_type: question?.answer_type || TYPE_OF_INTERACTION_OPTIONS.DPAD,
    answers: question?.answers || [],
    reward: question?.reward || 0,
  };

  const deleteQuestion = () => {
      api.deleteCourseLessonQuestion(courseId, question.lesson_id, question.id).handle({
            onSuccess: () => handleClose(true),
            errorMessage: "Error deleting question",
            successMessage: "Question deleted successfully"
        });
  }

  return (
    <ModalItem
      width={'90%'}
      height="80%"
      scrollable={false}
      open={showModal}
      closeOnClickOutside={false}
      title={deleteMode ? "Delete question?" : "Question Preview"}
      handleClose={handleClose}
      widthContainer={'100%'}
    >
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validateOnBlur={false}
        checked={true}
      >
        {({ values }) => {
          return (
            <Form style={{ width: "100%" }}>
              <Grid container rowGap={"25px"} px={"15px"}>
                <Grid item xs={12} mt={3}>
                  <MDTypography color="dark" fontSize={{ xs: "18px", md: "24px" }} mr={2}>
                    Type of questionnaire:
                  </MDTypography>
                  <Box ml={1}>
                    <RadioGroup
                      defaultValue={question?.question_type || TYPE_OF_QUESTIONNAIRE_OPTIONS.OPEN}
                      disabled={true}
                      row
                      name="row-radio-buttons-group"
                    >
                      {Object.entries(TYPE_OF_QUESTIONNAIRE_OPTIONS._LABELS).map(([key, label]) => (
                        <FormControlLabel
                          disabled={true}
                          key={key}
                          value={key}
                          control={<Radio />}
                          label={label}
                        />
                      ))}
                    </RadioGroup>
                  </Box>
                </Grid>
                {values.question_type === TYPE_OF_QUESTIONNAIRE_OPTIONS.MULTIPLE_CHOICE && (
                  <MultipleChoiceQuestion />
                )}
                {values.question_type === TYPE_OF_QUESTIONNAIRE_OPTIONS.OPEN && (
                  <OpenEndedQuestion />
                )}
                  {deleteMode && <Grid item xs={12} mt={5} display="flex" justifyContent="center">
                  <MDButton color="error" onClick={()=> deleteQuestion()}>
                      Delete
                  </MDButton>
                  </Grid>}
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </ModalItem>
  );
};

const OpenEndedQuestion = () => {
  const { values } = useFormikContext();
  return (
    <>
      <Grid item xs={12} mt={0}>
        <MDTypography color="dark" fontSize={{ xs: "18px", md: "24px" }} flexDirection="row" mr={2} mb={1}>
          Question:
        </MDTypography>
        <MDBox display={"flex"} justifyContent="space-between" sx={{background:'#6a994e24', borderRadius:2, padding:1}}>
          <MathJax style={{wordBreak:'break-word'}}>{`${values.prompt}${values.prompt}`}</MathJax>
          {values.answers.length <= 0 && <MathJax>{`${values.reward}`}</MathJax>}
        </MDBox>
          <hr style={{marginTop:20}}/>
      </Grid>
      <Grid item xs={12}>
        {values.answer_type &&
          values.answers?.map((_, i) => (
            <PossibleAnswerField
              key={i}
              i={i}
              checked={values.answers[i].correct === true}
              option={values.answers[i]}
            />
          ))}
      </Grid>
    </>
  );
};

const MultipleChoiceQuestion = ({ question }) => {
  const { values } = useFormikContext();
  return (
    <>
      <Grid item xs={12} display={"flex"} flexDirection={"column"} rowGap={"15px"}>
        <Box>
          <MDTypography
            color="dark"
            fontSize={{ xs: "18px", md: "24px" }}
            flexDirection="row"
            mr={2}
          >
            Type of interaction:
          </MDTypography>

          <RadioGroup
            defaultValue={values?.answer_type || TYPE_OF_INTERACTION_OPTIONS.DPAD}
            row
            disabled={true}
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group2"
            sx={{ ml: 1 }}
          >
            {Object.entries(TYPE_OF_INTERACTION_OPTIONS._LABELS).map(([key, label]) => (
              <FormControlLabel
                key={key}
                value={key}
                disabled={true}
                control={<Radio />}
                sx={{ display: "flex" }}
                label={
                  <Box display="flex" gap={1}>
                    {key === TYPE_OF_INTERACTION_OPTIONS.BUTTONS.toString() ? (
                      <img src={buttons} width="25px" alt="buttons" />
                    ) : key === TYPE_OF_INTERACTION_OPTIONS.DPAD.toString() ? (
                      <img src={dpad} width="25px" alt="dpad" />
                    ) : (
                      <>
                        <img src={buttons} width="25px" alt="buttons" />
                        <img src={dpad} width="25px" alt="dpad" />
                      </>
                    )}
                    <MDTypography color="dark" variant="body2">
                      {label}
                    </MDTypography>
                  </Box>
                }
              />
            ))}
          </RadioGroup>
        </Box>
          <Box display={"flex"} flexDirection={"column"} rowGap={"15px"}>
              <MDTypography
                  color="dark"
                  fontSize={{xs: "18px", md: "20px"}}
                  flexDirection="row"
                  mr={2}
              >
                  Multiple Choice Question:
              </MDTypography>
              <Box sx={{background: '#6a994e24', borderRadius: 2, padding: 1}}>
                  <MathJax>{`${values.prompt}`}</MathJax>
              </Box>
              <hr style={{marginTop: 2, width:'100%'}}/>
          </Box>
      </Grid>
        <Grid item xs={12}>
        {values.answer_type &&
          values.answers?.map((_, i) => (
            <OptionField
              key={i}
              i={i}
              checked={values.answers[i].correct === true}
              icon={icons[values.answer_type]?.[i].component}
              option={values.answers[i]}
            />
          ))}
      </Grid>
    </>
  );
};

export const OptionField = ({ checked, icon, option }) => {
  return (
    <Grid container wrap={'nowrap'} justifyContent={"space-between"}>
      <Grid container item xs={6} sm={11} display={'flex'} wrap={'nowrap'} alignItems="center" justifyContent={"space-between"}>
          {icon}
          <Grid item xs={9} sm={11} justifyContent={"center"} wordBreak={'break-all'} padding={2}>
            <MathJax style={{display:'flex', overflowY:'hidden', height:'auto', margin: 0, alignItems:'center', maxWidth:'900px'}} >{`${option?.answer || ""}`}</MathJax>
          </Grid>
          <Grid item xs={1}>
        <Checkbox checked={checked} />
          </Grid>
      </Grid>
      <Grid container item display={'flex'} xs={6} sm={1} alignItems="center" gap={1} justifyContent={"end"} wrap={'nowrap'}>
          <Grid item xs={9} justifyContent={"flex-end"} display={'flex'}>
          <MathJax>{`${option?.reward || 0}`}</MathJax>
          </Grid>
          <Grid item xs={3} justifyContent={"center"} display={'flex'} alignItems="center" >
        <PaidIcon color="primary" />
          </Grid>
      </Grid>
    </Grid>
  );
};

export const PossibleAnswerField = ({ i, checked, option }) => {
  return (
    <Grid container wrap={'nowrap'} justifyContent={"space-between"}>
      <Grid container item xs={6} sm={10} display={'flex'} wrap={'nowrap'} alignItems="center" justifyContent={"space-between"}>
        <Grid item xs={9} sm={11} justifyContent={"center"} wordBreak={'break-all'} padding={2}>
          <MathJax style={{display:'flex', overflowX:'auto', overflowY:'hidden', height:'40px', margin: 0, alignItems:'center' }} >{`${option?.answer || ""}`}</MathJax>
        </Grid>
          <Grid xs={1}>
        <Checkbox checked={checked} />
          </Grid>
      </Grid>
      <Grid container item display={'flex'} xs={6} sm={2} alignItems="center" gap={1} justifyContent={"end"} wrap={'nowrap'}>
          <Grid item xs={11} justifyContent={"flex-end"} display={'flex'}>
          <MathJax>{`${option?.reward || 0}`}</MathJax>
          </Grid>
          <Grid item xs={1} justifyContent={"center"} display={'flex'} alignItems="center" >
        <PaidIcon color="primary" />
          </Grid>
      </Grid>
    </Grid>
  );
};

const icons = {
  [TYPE_OF_INTERACTION_OPTIONS.BUTTONS]: [
    {
      id: BUTTON_TYPE.B,
      component: (
        <span
          style={{
            width: "20px",
            borderRadius: "15px",
            height: "20px",
            backgroundColor: "#010DFF",
          }}
        />
      ),
    },
    {
      id: BUTTON_TYPE.A,
      component: (
        <span
          style={{
            width: "20px",
            borderRadius: "100%",
            height: "20px",
            backgroundColor: "#FF2D3A",
          }}
        />
      ),
    },
    {
      id: BUTTON_TYPE.X,
      component: (
        <span
          style={{
            width: "20px",
            borderRadius: "100%",
            height: "20px",
            backgroundColor: "#FEF200",
          }}
        />
      ),
    },
    {
      id: BUTTON_TYPE.Y,
      component: (
        <span
          style={{
            width: "20px",
            borderRadius: "100%",
            height: "20px",
            backgroundColor: "#00B447",
          }}
        />
      ),
    },
  ],
  [TYPE_OF_INTERACTION_OPTIONS.DPAD]: [
    {
      id: BUTTON_TYPE.LEFT,
      component: <ArrowLeft sx={{ backgroundColor: "lightgray", borderRadius: "4px" }} />,
    },
    {
      id: BUTTON_TYPE.UP,
      component: <ArrowDropUp sx={{ backgroundColor: "lightgray", borderRadius: "4px" }} />,
    },
    {
      id: BUTTON_TYPE.RIGHT,
      component: <ArrowRight sx={{ backgroundColor: "lightgray", borderRadius: "4px" }} />,
    },
    {
      id: BUTTON_TYPE.DOWN,
      component: <ArrowDropDown sx={{ backgroundColor: "lightgray", borderRadius: "4px" }} />,
    },
  ],
  [TYPE_OF_INTERACTION_OPTIONS.BOTH]: [
    {
      id: BUTTON_TYPE.B,
      component: (
        <span
          style={{
            width: "20px",
            borderRadius: "100%",
            height: "20px",
            backgroundColor: "#010DFF",
          }}
        />
      ),
    },
    {
      id: BUTTON_TYPE.A,
      component: (
        <span
          style={{
            width: "20px",
            borderRadius: "100%",
            height: "20px",
            backgroundColor: "#FF2D3A",
          }}
        />
      ),
    },
    {
      id: BUTTON_TYPE.X,
      component: (
        <span
          style={{
            width: "20px",
            borderRadius: "100%",
            height: "20px",
            backgroundColor: "#FEF200",
          }}
        />
      ),
    },
    {
      id: BUTTON_TYPE.Y,
      component: (
        <span
          style={{
            width: "20px",
            borderRadius: "100%",
            height: "20px",
            backgroundColor: "#00B447",
          }}
        />
      ),
    },
    {
      id: BUTTON_TYPE.LEFT,
      component: <ArrowLeft sx={{ backgroundColor: "lightgray", borderRadius: "4px" }} />,
    },
    {
      id: BUTTON_TYPE.UP,
      component: <ArrowDropUp sx={{ backgroundColor: "lightgray", borderRadius: "4px" }} />,
    },
    {
      id: BUTTON_TYPE.RIGHT,
      component: <ArrowRight sx={{ backgroundColor: "lightgray", borderRadius: "4px" }} />,
    },
    {
      id: BUTTON_TYPE.DOWN,
      component: <ArrowDropDown sx={{ backgroundColor: "lightgray", borderRadius: "4px" }} />,
    },
  ],
};
export default QuestionViewModal;
