import { Box, IconButton } from '@mui/material'
import { MathJax } from 'better-react-mathjax'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import React from 'react'
import editIcon from '../../../assets/icons/edit.svg'

export const TaskGroupItem = ({
  taskGroup,
  index,
  setShowTaskGroupModal,
  lesson,
  setSelectedLesson,
}) => {
  const rowStyle = index % 2 === 1 ? { backgroundColor: 'rgba(106, 153, 78, 0.25)' } : {}
  //
  return (
    <MDBox
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      style={{
        flex: 1,
        paddingLeft: '5px',
        paddingRight: '25px',
        paddingTop: '3px',
        paddingBottom: '3px',
        marginRight: '-25px',
        borderRadius: '10px',
        ...rowStyle,
      }}
    >
      <MDTypography
        fontSize={{ xs: '18px', md: '20px' }}
        variant="h5"
        color="secondary"
        fontWeight="regular"
      >
        <MathJax>{`${index + 1}. ${taskGroup.name}`}</MathJax>
      </MDTypography>
      {/*  */}
      <Box>
        <IconButton
          onClick={() => {
            setShowTaskGroupModal({ isVisible: true, taskGroup: taskGroup })
            setSelectedLesson(lesson)
          }}
        >
          <img src={editIcon} alt="edit" />
        </IconButton>
      </Box>
    </MDBox>
  )
}
