/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React base styles
import colors from "assets/theme/base/colors";

// Material Dashboard 2 PRO React helper functions

const { white, primary } = colors;

const stepIcon = {
  styleOverrides: {
    root: {
      // backgroundColor: secondary.main,
      borderRadius: "50%",
      transition: "all 200ms linear",
      "&.Mui-active": {},
      "&.Mui-completed": {
        border: `1px solid ${primary.main}`,
        background: primary.main,
        color: white.main,
      },
    },
  },
};

export default stepIcon;
