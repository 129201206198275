import { LAYOUT_TYPES } from "context";
import { useMaterialUIController } from "context";
import { setLayout } from "context";
import { useApi } from "services/helpers";
import { useState, useEffect } from "react";
import { Container } from "@mui/material";
import { Box } from "@mui/system";
import MDTypography from "../../components/MDTypography";
import bgImage from '../../assets/images/background/background.png';
const sideBar = require("../../assets/images/background/sidebar.png");

const PrivacyPolicy = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState("");
  const api = useApi();
  const [, dispatch] = useMaterialUIController();

  const getPrivacyPolicy = () => {
    api.getPrivacyPolicy().handle({
      onSuccess: (res) => {
        console.log(res.data)
        setPrivacyPolicy(res.data?.body)
      },
      errorMessage: "Error getting Privacy Policy",
    })
  }

  useEffect(() => {
    getPrivacyPolicy();
  }, []);

  useEffect(() => {
    setLayout(dispatch, LAYOUT_TYPES.PAGE);
  }, [dispatch]);

  return (
    <div style={{ height: "100vh" }}>

      <Box
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: -1,
          overflowX: "hidden",
          overflowY: "hidden",
        }}
      >
        <img
          alt="background"
          src={sideBar}
          style={{ height: "100vh", width: "10%", objectFit: "cover" }}
        />

        <img alt="side" src={bgImage} style={{ height: "100vh", width: "90%" }} />
      </Box>
      <Container sx={{ p: 4 }}>
        <Box sx={{background:'white', boxShadow: 2, borderRadius: 3, p: 3, textAlign: 'center' }}>
          <MDTypography color={"primary"} fontWeight="bold" fontSize={24} >
            Privacy Policy
          </MDTypography>
        </Box>
        <Box sx={{ background:'white', boxShadow: 2, borderRadius: 3, p: 3, maxHeight: '80vh', overflowY:"auto", marginTop:2}}>
          <MDTypography color={"secondary"}>
              <div dangerouslySetInnerHTML={{__html: privacyPolicy || "No privacy policy information."}}/>
          </MDTypography>
        </Box>
      </Container>
    </div>
  );
};
export default PrivacyPolicy;
