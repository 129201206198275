import {Box, Grid, Typography} from "@mui/material";
import {ArrowDropDown, ArrowDropUp, ArrowLeft, ArrowRight} from "@mui/icons-material";
import React from "react";


export const ArrowPad = ({ userInteraction }) => {
    const onUp = () => userInteraction({button: 1})
    const onDown = () => userInteraction({button: 2})
    const onLeft = () => userInteraction({button: 3})
    const onRight = () => userInteraction({button: 4})
    return (
        <Grid container width={`${40 * 3}px`} height={`${40 * 3}px`}>
            <Grid item xs={4} />
            <Grid
                item
                xs={4}
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="40px"
                borderRadius="10px 10px 0 0"
                bgcolor="#d9d9d9"
                onClick={onUp}
            >
                <ArrowDropUp fontSize="35px" sx={{ color: "#BBB9B9", fontSize: "35px" }} />
            </Grid>
            <Grid item xs={4} />
            <Grid
                item
                xs={4}
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="40px"
                borderRadius="10px 0 0 10px"
                onClick={onLeft}
                bgcolor="#d9d9d9"
            >
                <ArrowLeft fontSize="35px" sx={{ color: "#BBB9B9", fontSize: "35px" }} />
            </Grid>
            <Grid item xs={4} bgcolor="#d9d9d9" border="4px solid #d9d9d9" />
            <Grid
                item
                xs={4}
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="40px"
                borderRadius="0 10px 10px 0"
                bgcolor="#d9d9d9"
                onClick={onRight}
            >
                <ArrowRight fontSize="35px" sx={{ color: "#BBB9B9", fontSize: "35px" }} />
            </Grid>
            <Grid item xs={4} />
            <Grid
                item
                xs={4}
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="40px"
                borderRadius="0 0 10px 10px"
                bgcolor="#d9d9d9"
                onClick={onDown}
            >
                <ArrowDropDown fontSize="35px" sx={{ color: "#BBB9B9", fontSize: "35px" }} />
            </Grid>
            <Grid item xs={4} />
        </Grid>
    );
};
export const LetterButtons = ({ userInteraction }) => {
    const onA = () => userInteraction({button: 5})
    const onB = () => userInteraction({button: 6})
    const onX = () => userInteraction({button: 7})
    const onZ = () => userInteraction({button: 8})
    const commonProps = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "40px",
        height: "40px",
        borderRadius: "25px",
        sx: { userSelect: "none" },
    }
    const typoGraphyProps = {
        textAlign: "center",
        sx:{
           color: 'white !important',
           textShadow:' -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;'
        }
    }
    return (
        <Grid container width={`${40 * 3}px`} height={`${40 * 3}px`}>
            <Grid item xs={4} />
            <Grid item xs={4}>
                <Box
                    {...commonProps}
                    bgcolor="#00B447"
                    onClick={onZ}
                >
                    <Typography {...typoGraphyProps}> Y </Typography>
                </Box>
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4}>
                <Box
                    {...commonProps}
                    bgcolor="#FEF200"
                    onClick={onX}
                >
                    <Typography  {...typoGraphyProps}> X </Typography>
                </Box>
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4}>
                <Box
                    {...commonProps}
                    bgcolor="#010DFF"
                    onClick={onB}
                >
                    <Typography  {...typoGraphyProps}> B </Typography>
                </Box>
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4}>
                <Box
                    {...commonProps}
                    bgcolor="#FF2D3A"
                    onClick={onA}
                >
                    <Typography  {...typoGraphyProps}> A </Typography>
                </Box>
            </Grid>
            <Grid item xs={4} />
        </Grid>
    );
};
