import React from "react";
import PageLayout from "components/PageLayout";
import TeachersLessons from "./TeachersLessons";

const CourseLessons = () => {
  return (
    <PageLayout teacherComponent={<TeachersLessons />} studentComponent={"Student questionnaire"} />
  );
};

export default CourseLessons;
