import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import MDButton from "../MDButton";
import {Modal} from "@mui/material";

export const CalendarCrudModal = ({handleClose, open, confirmAllText, confirmText = 'Confirm', handleConfirm, handleConfirmAll, title, subtitle}) => {
  return(
    <Modal open={open} onClose={handleClose}>
      <MDBox sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        backgroundColor: '#fff',
        borderRadius: '10px',
        transform: 'translate(-50%, -50%)',
        // width: 400,
        boxShadow: 24,
        padding: 2
      }}>
        <MDTypography pt={2} sx={{textAlign: 'center', fontSize: "22px", color: '#6A994E'}}>{title}</MDTypography>
        <MDTypography pt={2} sx={{textAlign: 'center', fontSize: "16px", color: '#000'}}>{subtitle}</MDTypography>
        <MDBox pb={2} display={'flex'} justifyContent={'center'} alignItems={'center'} gap={2} pt={2}>
          {confirmAllText && <MDButton color={'secondary'}  onClick={() => {
            handleConfirmAll()
          }}>{confirmAllText}</MDButton>}
          <MDButton color={'primary'} onClick={() => {
            handleConfirm()
          }}>{confirmText}</MDButton>
          <MDButton variant={'outlined'} color={'secondary'} onClick={()=>{
            handleClose()
          }}>Cancel</MDButton>
        </MDBox>
      </MDBox>
    </Modal>
  )
}
