import {
  Avatar,
  Box,
  Grid,
  Typography
} from "@mui/material";
import {QuestionStats} from "./charts";
import PaidIcon from "@mui/icons-material/Paid";
import React, {useEffect, useState} from "react";
import MDBox from "../../components/MDBox";
import defaultProfile from "../../assets/images/default-profile.png";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import {useApi} from "../../services/helpers";
import {useParams} from "react-router-dom";


export const LeaderboardTab = ({updateLeaderBoard }) => {
  const api = useApi();
  const {meetingId} = useParams()
  const [currentMeeting, setCurrentMeeting] = useState(true)
  const [leaderboard, setLeaderboard] = useState([])


  const getLeaderBoard = () => {
    api.meetingLeaderboard(meetingId, !currentMeeting).handle({
        onSuccess: (response) => { setLeaderboard(response.data); },
        errorMessage: "Error getting leaderboard info"
    })
  }
  const handleCurrentCourse = () => {
    setCurrentMeeting(true)
  }

  const handleHoleCourt = () => {
    setCurrentMeeting(false)
  }

  useEffect(() => {
    getLeaderBoard()
  }, [meetingId, currentMeeting, updateLeaderBoard])

    return (

      <Grid justifyContent={'center'} container mt={3} direction={{xs:'column', md:'row'}} wrap={"nowrap"} columnSpacing={2}>
        {/*<Grid container item xs={12} md={4} direction='column' wrap={"nowrap"}>*/}
        {/*  <Grid item flexBasis={'0 !important'} xs={12}>*/}
        {/*    <Typography color="primary" fontWeight="bold">*/}
        {/*      Completed Questions*/}
        {/*    </Typography>*/}
        {/*  </Grid>*/}
        {/*  <Grid item xs={12} py={2}>*/}
        {/*    {questions?.map((question, index) => (*/}
        {/*      <QuestionStats key={question.id} title={`${index+1}. ${question.prompt}`} />*/}
        {/*    ))}*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
        <Grid order={{xs:'1', md:'2'}} container item direction='column' wrap={"nowrap"} xs={9}
              sx={{borderLeft:{xs: "none", md:"1px solid rgba(0, 0, 0, 0.25)"}}} alignItems={'flex-start'}>
          <Grid display={'flex'} item container xs={9} sx={{flexBasis:'0px !important'}}>
            <MDBox display={"flex"} justifyContent={"space-between"} width={"100%"} >
              <Typography color="primary" fontWeight="bold">
                Leaderboard
              </Typography>
              <MDBox display={"flex"} gap={1}>
                <MDButton color={currentMeeting? "info" :"light"} onClick={handleCurrentCourse}>Current meeting</MDButton>
                <MDButton color={!currentMeeting ? "info" :"light"} onClick={handleHoleCourt}>Course</MDButton>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid order={{xs:'1', md:'2'}} container item direction='column' wrap={"nowrap"} xs={9}>
            <MDBox display="flex" flexDirection="column" alignItems="flex-start" >
              {(leaderboard || []).map((position, index) => (
                <StudentRow
                  key={position.id}
                  student={position}
                  amount={position.total}
                  position={index+1}
                />
              ))}
            </MDBox>
         </Grid>
        </Grid>
      </Grid>

    );
};

const StudentRow = ({ student, amount, position }) => {

  const index = () => {
    if (position === 1) return `${position}st`
    else if (position === 2) return `${position}nd`
    else if (position === 3) return `${position}rd`
    else return `${position}th`
  }
  const renderStudentBalance = (student) => {
    const value = amount
    return value !== undefined ? value : '-'
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        p={1}
        width={"100%"}
        overflow={"auto"}
      >
        <Box flex={1} display="flex" flexDirection="row" alignItems="center">
          <MDTypography color="dark" variant="body1" mr={2}>
            {index()}
          </MDTypography>
          <Avatar src={defaultProfile} />
          <MDTypography color="dark" variant="body1" ml={1}>
            {student.name}
          </MDTypography>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" sx={{ height: "100%" }} px={1}>
          <MDTypography color="dark">{renderStudentBalance(student)}</MDTypography>
          <PaidIcon color="primary" sx={{ marginLeft: 1 }} />
        </Box>
      </Box>

    </>
  );
};
