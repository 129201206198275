import React from "react"
import { Box, Card, CardActionArea } from "@mui/material"
import MDTypography from "../../../components/MDTypography"
import PaidIcon from "@mui/icons-material/Paid"

const MDBuckPresetCard = ({ title, amount, isAddition, onClick }) => {
  return (
    <Card
      sx={{ width: "100%", cursor: "pointer", height: "100%" }}
      onClick={onClick}
    >
      <CardActionArea sx={{ height: "100%", padding: 1 }}>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent={"space-between"}
             sx={{ border: "2px solid redx", height: "100%" }}>
          <MDTypography variant="h5" color={isAddition ? "primary" : "error"} display={"flex"} alignItems={"center"}
                        gap={1}>
            <div>{isAddition ? "+" : "-"}{amount}</div>
            <PaidIcon color="primary" sx={{ marginBottom: 0.2 }} />
          </MDTypography>
          <MDTypography variant="h6" color="textPrimary" fontSize={"0.9rem"}>
            {title}
          </MDTypography>
        </Box>
      </CardActionArea>
    </Card>
  )
}

export default MDBuckPresetCard
