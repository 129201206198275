import React from "react";
import PageLayout from "components/PageLayout";
import TeachersQuestionnaire from "./TeachersQuestionnaire";

const Questionnaire = () => {
  return (
    <PageLayout teacherComponent={<TeachersQuestionnaire />} studentComponent="Studens lessons" />
  );
};

export default Questionnaire;
