import { Backdrop, CircularProgress, Grid } from "@mui/material";
import { observer } from "mobx-react";
import moment from "moment";
import timezone from "moment-timezone";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { momentLocalizer, Calendar as MyCalendar } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import LoadingBar from "react-top-loading-bar";
import { date_fmt, useApi } from "../../services/helpers";
import { CalendarView } from "./constants";
import { CourseModal } from "./courseModal";
import "./paper-dashboard.css";
import "./styles.css";
import { MyToolbar } from "./toolbar";

import { useSearchParams } from "react-router-dom";
import {
  AllDayEvent,
  combineDate,
  DateCellWrapper,
  EventViewDay,
  EventViewMonth,
  EventViewWeek,
  MonthHeaderCellContent,
  updateTimeValidator,
  WeekHeaderCellContent,
} from "./utils";

timezone.tz.setDefault("en");
const BigCalendar = memo(withDragAndDrop(MyCalendar));
const localizer = momentLocalizer(timezone);


const CalendarPage = observer(({ courseId }) => {
  const api = useApi();
  const calendarRef = useRef();
  const [viewState, setViewState] = useState(CalendarView.DAY);
  const [calendarDate, setCalendarDate] = useState(moment());
  const [showClassModal, setShowClassModal] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState(null);  
  const [Loading, setLoading] = useState(true);
  const [Events, setEvents] = useState([]);
  const [repeatOptions, setRepeatOptions] = useState(false);
  const dateFormatted = date_fmt(calendarDate, "YYYY-MM-DD");
  const [searchParams] = useSearchParams();
  const course_id = searchParams.get("cid");

  const onNavigate = useCallback((newDate) => setCalendarDate(newDate), [setCalendarDate]);

  const getDayEvents = (data) => {
    setLoading(true);
    api.getCalendarEvents({ ...data, day: dateFormatted }).handle({
      onSuccess: (result) => {
        const { data } = result;
        const newData = data?.map((item, index) => {
          return {
            allDay: false,
            end: combineDate(item.date, item.end_time),
            start: combineDate(item.date, item.start_time),
            date: item.date,
            title: item?.title,
            color: item.course.color,
            resourceId: `${item?.id}`,
            id: item.id,
            course: item.course,
            lesson: item.lesson,
            period: item.period,
            repeat_config: item.repeat_config,
          };
        });
        setEvents(newData);
      },
      errorMessage: "Error getting events",
      onFinally: () => setLoading(false),
    });
  };

  const getWeekEvents = (data) => {
    setLoading(true);
    const start = moment(calendarDate).startOf("week").format("YYYY-MM-DD");
    const end = moment(calendarDate).endOf("week").format("YYYY-MM-DD");
    const rangeData = { ...data, date_from: start, date_to: end };
    api.getCalendarRangeEvents(rangeData).handle({
      onSuccess: (result) => {
        const { data } = result;
        const newData = data?.map((item, index) => {
          return {
            allDay: false,
            end: combineDate(item.date, item.end_time),
            start: combineDate(item.date, item.start_time),
            date: item.date,
            title: item?.title,
            color: item.course.color,
            resourceId: `${item?.id}`,
            id: item.id,
            course: item.course,
            lesson: item.lesson,
            period: item.period,
            repeat_config: item.repeat_config,
          };
        });
        setEvents(newData);
      },
      errorMessage: "Error getting events",
      onFinally: () => setLoading(false),
    });
  };

  const getMonthEvents = () => {
    const startOfMonth = moment(calendarDate).startOf("month").format("YYYY-MM-DD");
    const endOfMonth = moment(calendarDate).endOf("month").format("YYYY-MM-DD");
    setLoading(true);
    const rangeData = { date_from: startOfMonth, date_to: endOfMonth };
    api.getCalendarRangeEvents(rangeData).handle({
      onSuccess: (result) => {
        const { data } = result;
        const newData = data?.map((item, index) => {
          return {
            allDay: false,
            end: combineDate(item.date, item.end_time),
            start: combineDate(item.date, item.start_time),
            date: item.date,
            title: item?.title,
            color: item.course.color,
            resourceId: `${item?.id}`,
            id: item.id,
            course: item.course,
            lesson: item.lesson,
            period: item.period,
            repeat_config: item.repeat_config,
          };
        });
        setEvents(newData);
      },
      errorMessage: "Error getting events",
      onFinally: () => setLoading(false),
    });
  };

  const updateCourseMeeting = (data, start, end) => {
    setLoading(true);
    const validatedData = {
      ...data,
      date: date_fmt(start, "YYYY-MM-DD"),
      start_time: updateTimeValidator(start),
      end_time: updateTimeValidator(end),
    };
    api.updateCourseMeeting(validatedData).handle({
      onSuccess: () => {
        init();
      },
      errorMessage: "Error updating course meeting",
      successMessage: "Course meeting updated successfully!",
      onFinally: () => setLoading(false),
    });
  };

  const deleteCourseMeeting = (courseId, meetingId, params) => {
    setLoading(true);
    api.deleteCourseMeeting(courseId, meetingId, params).handle({
      onSuccess: () => {
        init();
      },
      errorMessage: "Error deleting course meeting",
      successMessage: "Course meeting deleted successfully!",
      onFinally: () => setLoading(false),
    });
  };

  useEffect(() => {
    if (selectedMeeting) {
      setShowClassModal(true);
    }
  }, [selectedMeeting]);

  const init = () => {
    if (viewState === 1) {
      getDayEvents();
    } else if (viewState === 2) {
      getWeekEvents();
    } else {
      getMonthEvents();
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarDate]);

  const handleSelectEvent = (event) => {
    setSelectedMeeting(event);
    if (event?.repeat_config) {
      setRepeatOptions(true);
    } else {
      setRepeatOptions(false);
    }
  };

  const handleMoveEvent = ({ event, start, end }) => {
    updateCourseMeeting(event, start, end);
  };

  const handleDragStartNewEvent = (event) => {
    const newEvent = Object.assign({}, { ...event, date: event.start });
    setSelectedMeeting(newEvent);
    setShowClassModal(true);
  };

  function CustomEvent(opts) {
    const { event } = opts;
    return (
      <>
        {(event.allDay && <AllDayEvent event={event} viewState={viewState} />) || (
          <>
            {viewState === CalendarView.DAY && <EventViewDay event={event} />}
            {viewState === CalendarView.WEEK && <EventViewWeek event={event} />}
            {viewState === CalendarView.MONTH && <EventViewMonth event={event} />}
          </>
        )}
      </>
    );
  }

  return (
    <>
      <LoadingBar color="#4B8C01" height={5} progress={100} />
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={Loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid container spacing={0}>
        <Grid item lg={12}>
          <BigCalendar
            ref={calendarRef}
            onNavigate={onNavigate}
            components={{
              toolbar: (toolbar) => (
                <MyToolbar
                  toolbar={toolbar}
                  CurrentDate={calendarDate}
                  setShowScheduledServiceModal={setShowClassModal}
                  setCalendarDate={setCalendarDate}
                  viewState={viewState}
                  setViewState={setViewState}
                />
              ),
              event: CustomEvent,
              week: {
                header: WeekHeaderCellContent,
              },
              month: {
                header: MonthHeaderCellContent,
                dateHeader: DateCellWrapper,
              },
            }}
            localizer={localizer}
            events={Events}
            selectable
            views={['day', 'week', 'month']}
            defaultView={viewState}
            dayLayoutAlgorithm={"no-overlap"}
            showMultiDayTimes={true}
            startAccessor="start"
            endAccessor="end"
            onSelectSlot={handleDragStartNewEvent}
            onSelectEvent={handleSelectEvent}
            onEventDrop={handleMoveEvent}
            eventPropGetter={(event, start, end, isSelected) => {
              const backgroundColor = event?.color || "#6A994E";
              return { style: { backgroundColor } };
            }}
          />
        </Grid>
      </Grid>
      <CourseModal
        showModal={showClassModal}
        setShowModal={setShowClassModal}
        selectedMeeting={selectedMeeting}
        onSave={() => {
          init();
          setSelectedMeeting(null);
          setShowClassModal(false);
        }}
        handleClose={() => {
          setSelectedMeeting(null);
          setShowClassModal(false);
        }}
        onNewLessonClick={() => {}}
        deleteCourseMeeting={deleteCourseMeeting}
        setRepeatOptions={setRepeatOptions}
        repeatOptions={repeatOptions}
        courseId={courseId || course_id}
        openModal={!!course_id}
      />
    </>
  );
});

export default CalendarPage;
