import { Grid } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import { Box } from "@mui/system";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { FormikProvider, useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import filter from "../../assets/icons/filter.svg";
import { CheckboxList } from "../../components/CheckBoxList";
import FormikInput from "../../components/FormikInput";
import MDButton from "../../components/MDButton";
import { transformObjectsToId, useApi, useLoginStore } from "../../services/helpers";


const TeacherExport = () => {
  const api = useApi();
  const loginStore = useLoginStore();
  const { is_admin: isAdmin } = loginStore;
  const [loading, setLoading] = useState(false);
  const [globalExport, setGlobalExport] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [teachers, setTeachers] = useState([]);
  const [courses, setCourses] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [meetings, setMeetings] = useState([]);
  const [students, setStudents] = useState([]);
  const [exportColumns, setExportColumns] = useState({labels: {}, values: {}});
  const [exportEventTypes, setExportEventTypes] = useState({labels: {}, values: {}});

  const validationSchema = Yup.object().shape({
    course: Yup.mixed(),
    lesson: Yup.mixed(),
    meeting: Yup.mixed(),
    student: Yup.mixed(),
  })

  const initialValues = {
    teacher: null,
    course: null,
    lesson: null,
    meeting: null,
    student: null,
  };

  const getTeachers = () => {
    if(!isAdmin) return
    api.getTeachers().handle({
      onSuccess: (res) => {
        setTeachers(res.data)
      },
      errorMessage: "Error getting teachers",
    });
  }

  const getTeacherCourses = () => {
    api.getTeacherCourses().handle({
      onSuccess: (res) => {
        setCourses(res.data)
      },
      errorMessage: "Error getting teacher courses",
    });
  }

  const getCoursesByTeacherId = (teacherId) => {
    if(!isAdmin) return
    api.getCoursesByTeacherId(teacherId).handle({
      onSuccess: (res) => {
        setCourses(res.data)
      },
      errorMessage: "Error getting courses",
    });
  }

  const getLessonsCourse = () => {
    api.getLessonsByCourse(selectedCourse?.id).handle({
      onSuccess: (res) => {
        setLessons(res.data.results)
        setSelectedLesson(null)
      },
      errorMessage: "Error getting lessons",
    });
  }

  const getMeetingsCourse = () => {
    const filter = {}
    if (selectedTeacher) {
      filter.teacher = selectedTeacher.id
    }
    if (selectedCourse) {
      filter.course = selectedCourse.id
    }
    if(selectedLesson){
        filter.lesson = selectedLesson.id
    }
    api.searchMeetings(filter).handle({
      onSuccess: (res) => {
        setMeetings(res.data.results)
      },
      errorMessage: "Error getting meetings",
    });
  }

  const getStudentsCourse = () => {
    api.getStudentsOfCourse(selectedCourse?.id).handle({
      onSuccess: (res) => {
        setStudents(res.data)
      },
      errorMessage: "Error getting students",
    });
  }

  const getExportOptions = () => {
    api.getExportOptions().handle({
      onSuccess: (res) => {
        const data = res.data
        setExportColumns({
          values: Object.fromEntries(data.export_columns.map(({id, label}) => [id, true])),
          labels: Object.fromEntries(data.export_columns.map(({id, label}) => [id, label])),
        })
        setExportEventTypes({
          values: Object.fromEntries(data.event_types.map(({id, label}) => [id, true])),
          labels: Object.fromEntries(data.event_types.map(({id, label}) => [id, label])),
        })
        // console.log(res.data)
      },
      errorMessage: "Error getting export options",
    });
  }

  useEffect(() => {
    getExportOptions()
    getTeacherCourses()
  }, []);

  const resetTeacherFilters = () => {
    setSelectedCourse(null)
    setSelectedLesson(null)
    setLessons([])
    setMeetings([])
    setStudents([])
    formik.setFieldValue("course", null)
    formik.setFieldValue("lesson", null)
    formik.setFieldValue("meeting", null)
    formik.setFieldValue("student", null)
  }

  useEffect(() => {
    if (!globalExport) {
      setSelectedTeacher(null)
      getTeacherCourses()
      resetTeacherFilters()
      formik.setFieldValue('teacher', null)
    } else if (isAdmin) {
      getTeachers()
      setCourses([])
    }
  }, [globalExport])

  useEffect(() => {
    resetTeacherFilters()
    if (selectedTeacher) {
      getCoursesByTeacherId(selectedTeacher.id)
    } else {
      setCourses([])
    }
  }, [selectedTeacher]);

  useEffect(() => {
    if (selectedCourse) {
      getLessonsCourse()
      getMeetingsCourse()
      getStudentsCourse()
    }
  }, [selectedCourse]);

  useEffect(() => {
    getMeetingsCourse()
  }, [selectedLesson]);

  const generateReport = (values) => {
    const sendValues = {
      ...values,
      columns: Object.keys(exportColumns.values).filter((key) => exportColumns.values[key]),
      event_types: Object.keys(exportEventTypes.values).filter((key) => exportEventTypes.values[key]),
      global_export: globalExport,
    }
    setLoading(true)
    api.downloadCourseExport(sendValues).handle({
      onSuccess: (result) => { result?.download_file() },
      errorMessage: 'Error while generating metrics report',
      onFinally: () => setLoading(false),
    })
  }

  const formik = useFormik({
    initialValues: initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = transformObjectsToId(values, ['course', 'lesson', 'meeting', 'student', 'teacher'])
      generateReport(data)
    },
  })

  return (
    <>
      <MDBox display="flex" py={1} m={0} >
        <MDTypography flex={1} py={1} borderBottom="1px solid rgba(0, 0, 0, 0.25)" variant="h3" color="primary" fontWeight="regular">
          Export system data
        </MDTypography>
      </MDBox>
      <Grid container item xs={12} sm={8} justifyContent={"flex-start"}>
        <MDBox width={"100%"} pl={4} pr={4} pt={1} bgColor={"#fff"} borderRadius={'xl'} border={"2px solid rgba(0, 0, 0, 0.10)"}>
          <MDBox display={"flex"} alignItems={"center"} justifyContent="space-between" pt={2} pb={2}>
            <MDBox display="flex" alignItems="items">
              <Box component={"img"} src={filter} alt={"filter"} pr={1}/>
              <MDTypography variant={"textBold"} color={"secondary"}>Filters</MDTypography>
            </MDBox>
            {isAdmin && <MDBox display="flex" alignItems="center">
              <Checkbox
                  checked={globalExport}
                  onChange={(e) => {
                    const checked = e.target?.checked
                    setGlobalExport(checked)
                  }}
                  disableRipple
              />
              <MDTypography variant={"p"} color={"secondary"}>Global export</MDTypography>
            </MDBox>}
          </MDBox>
          <FormikProvider value={formik}>
            {/*  */}
            {!!globalExport && <Grid container spacing={8}>
              <Grid item xs={6} lg={6}>
                {/* TEACHER */}
                <MDTypography variant={"h5"} pt={1} fontWeight={"regular"} color={"dark"}>Teachers</MDTypography>
                <FormikInput
                  type={"autocomplete"}
                  value={formik.values.teacher}
                  fieldName={"teacher"}
                  options={teachers}
                  accessKey={"name_email"}
                  onChange={(value) => {
                    formik.setFieldValue('teacher', value)
                    setSelectedTeacher(value)
                  }}
                  styleContainer={{mb: 1}}
                />
              </Grid>
            </Grid>}
            {/*  */}
            <Grid container spacing={8}>
              <Grid item xs={6} lg={6}>
                {/* COURSE */}
                <MDTypography variant={"h5"} pt={1} fontWeight={"regular"} color={"dark"}>Courses</MDTypography>
                <FormikInput
                  type={"autocomplete"}
                  value={formik.values.course}
                  fieldName={"course"}
                  options={courses}
                  accessKey={"name"}
                  onChange={(value) => {
                    formik.setFieldValue('course', value)
                    setSelectedCourse(value)
                  }}
                  styleContainer={{mb: 1}}
                />
                {/* LESSON */}
                <MDTypography variant={"h5"} fontWeight={"regular"} color={"dark"}>Lesson</MDTypography>
                <FormikInput
                  type={"autocomplete"}
                  value={formik.values.lesson}
                  fieldName={"lesson"}
                  options={lessons}
                  accessKey={"name"}
                  onChange={(value) => {
                    formik.setFieldValue('lesson', value)
                    setSelectedLesson(value)
                  }}
                  styleContainer={{mb: 1}}
                />
                 {/* MEETINGS */}
                <MDTypography  variant={"h5"} fontWeight={"regular"} color={"dark"}>Meetings</MDTypography>
                <FormikInput
                  type={"autocomplete"}
                  value={formik.values.meeting}
                  fieldName={"meeting"}
                  options={meetings}
                  accessKey={"label"}
                  onChange={(value) => {
                    formik.setFieldValue('meeting', value)
                  }}
                  styleContainer={{mb: 1}}
                />
              </Grid>

              <Grid item xs={6} lg={6}>
                {/* STUDENT */}
                <MDTypography variant={"h5"} pt={1} fontWeight={"regular"} color={"dark"}>Student</MDTypography>
                <FormikInput
                    type={"autocomplete"}
                    value={formik.values.student}
                    fieldName={"student"}
                    options={students}
                    accessKey={"name"}
                    onChange={(value) => {
                      formik.setFieldValue('student', value)
                    }}
                    styleContainer={{mb: 1}}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <MDTypography variant={"h5"} pt={1} fontWeight={"regular"} color={"dark"}>Types of event to include</MDTypography>
                <MDBox width={"100%"} pl={4} pr={4} pt={1} bgColor={"#fff"} borderRadius={'xl'} border={"2px solid rgba(0, 0, 0, 0.10)"}
                       sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
                >
                  <CheckboxList
                      id={'exportEventTypes'}
                      options={exportEventTypes.values}
                      getOptionLabel={useCallback((option) => exportEventTypes.labels[option], [exportEventTypes])}
                      changeOption={(option, value) => {
                        setExportEventTypes({
                          ...exportEventTypes,
                          values: {
                            ...exportEventTypes.values,
                            [option]: !value
                          }
                        })
                      }}
                  />

                </MDBox>
              </Grid>
              <Grid item xs={12} lg={6}>
                <MDTypography variant={"h5"} pt={1} fontWeight={"regular"} color={"dark"}>Columns to include</MDTypography>
                <MDBox width={"100%"} pl={4} pr={4} pt={1} bgColor={"#fff"} borderRadius={'xl'} border={"2px solid rgba(0, 0, 0, 0.10)"}
                       sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
                >
                  <CheckboxList
                      id={'exportColumns'}
                      options={exportColumns.values}
                        getOptionLabel={useCallback((option) => exportColumns.labels[option], [exportColumns])}
                        changeOption={(option, value) => {
                          setExportColumns({
                            ...exportColumns,
                            values: {
                              ...exportColumns.values,
                              [option]: !value
                            }
                          })
                        }}
                  />
                </MDBox>
              </Grid>
            </Grid>
            {/*  */}
            <MDBox mt={1} mb={1} pb={4} gap={1.6} display={'flex'} justifyContent={'flex-end'}>
              <MDButton
                variant="outlined"
                color="secondary"
                type='button'
                disabled={loading || !formik.isValid}
                onClick={() => formik.resetForm()}
              >
                Clear Filters
              </MDButton>
              <MDButton
                variant="contained"
                color="primary"
                type='submit'
                disabled={loading}
                loading={loading}
                onClick={() => formik.handleSubmit()}
              >
                Download
              </MDButton>
            </MDBox>
          </FormikProvider>
        </MDBox>
      </Grid>
    </>
  );
};

export default TeacherExport;
