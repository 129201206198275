import { Grid,} from "@mui/material";
import FormikInput from "components/FormikInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import ModalItem from "components/ModalItem";
import { Form, Formik } from "formik";
import {useEffect, useRef} from "react";
import MDBox from "../../components/MDBox";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import moment from "moment";
import {FROM_USER} from "../../utils/helpers"
import * as Yup from "yup";


const FeedbackModal = ({ showModal, handleClose, messages, postMessage }) => {
  const formikRef = useRef();
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [messages]);

  const initialValues ={
    message: ""
  }

  const validationSchema = Yup.object().shape({
    message: Yup.string().required(),
  })

  return (
    <ModalItem
      scrollable={false}
      open={showModal}
      height={{xs:'50vh', md:'60vh'}}
      closeOnClickOutside={false}
      widthContainer='100%'
      title="Feedback"
      handleClose={handleClose}
      px={0}
    >
      <MDBox display={"flex"} flexDirection={"column"} width={"100%"}>
        <MDBox ref={containerRef} sx={{backgroundColor: "#cdcdcd", overflowY: "auto"}} height={"60%"}>
          {messages?.length>0 && messages.map(m => <MessageCard message={m}/> )}
        </MDBox>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={false}
          onSubmit={(values) => {
            postMessage(values)
            formikRef.current.resetForm()
          }}
          checked={true}
        >
          {({ handleSubmit, isValid, dirty }) => {
            return (
              <Form style={{ width: "100%" }}>
                <Grid container rowGap={"25px"} px={'15px'}>
                  <Grid item xs={12} mt={3}>
                    <MDTypography color="dark" fontSize={24} flexDirection="row" mr={2}>
                      Enter your feedback
                    </MDTypography>
                    <FormikInput
                      name="message"
                      rows={2}
                      type={"textarea"}
                      label="Write a message"
                    />
                  </Grid>
                  <Grid item xs={12} mt={1} display="flex" justifyContent="center">
                    <MDButton
                      color="primary"
                      onClick={handleSubmit}
                      disabled={!isValid || !dirty}
                    >
                      Send
                    </MDButton>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </MDBox>
    </ModalItem>
  );
};

const MessageCard = ({ message }) => {

  return (
    <Card
      sx={{
        boxShadow: 4,
        m:1,
        ml: message?.from_user.includes(FROM_USER.ADMIN)? 1: 5,
        maxWidth:'440px',
    }}
    >
      <MDBox>
        <CardContent sx={{width:'100%', px:'5px'}}>
          <MDBox display="flex" flexDirection="column" >
            <MDBox display="flex" alingItems={"center"} justifyContent={"space-between"}>
              <MDTypography
                variant="h6"
                color="primary"
                fontWeight="bold"
                mr={1}
              >
                {message?.from_user}
              </MDTypography>
              <MDTypography
                variant="caption"
                color="secondary"
                fontWeight="bold"
                textAlign={"center"}
              >
                {moment(message?.created).format("DD-MM-YYYY h:mm A")}
              </MDTypography>
            </MDBox>
            <MDTypography
              variant="caption"
              color="dark"
            >
              {message?.message}
            </MDTypography>
          </MDBox>
        </CardContent>
      </MDBox>
    </Card>
  );
};


export default FeedbackModal;
