import {Card, Grid, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import DiscussionModal from "./components/DiscussionModal";
import {useApi} from "../../services/helpers";
import moment from "moment/moment";
import Box from "@mui/material/Box";
import defaultImg from "../../assets/images/default-profile.png";
import MDBox from "../../components/MDBox";
import PaidIcon from "@mui/icons-material/Paid";
import DiscussionStudentCard from "./components/DiscussionsStudentCard";

export const DiscussionsTab = ({ attendanceStatus, balances }) => {
  const { courseId, meetingId } = useParams()
  const api = useApi()
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [discussions, setDiscussions] = useState(null);
  const [openDiscussionModal, setOpenDiscussionModal] = useState(false);

  const courseMeetingDiscussions = () => {
    api.listMeetingDiscussions(meetingId).handle({
      onSuccess: (res) => {
        setDiscussions(res.data)
      },
      errorMessage: "Error getting discussions",
    })
  }

  const recordInteraction = (values) => {
    api.recordInteraction(meetingId, values).handle({
      onSuccess: () => {
        courseMeetingDiscussions()
      },
      errorMessage: 'Error recording interaction ',
    })
  }

  const onOpenDiscussionModal = (student) => {
    setSelectedItem(student)
    setOpenDiscussionModal(true)
  }

  const renderStudentBalance = (student) => {
    const value = balances[student?.user_id]
    return value !== undefined ? value : '-'
  }

  useEffect(() => {
    courseMeetingDiscussions()
  }, [])

  return (
    <Grid container alignItems={'flex-start'} mt={3}>
      <Grid container item xs={12} sm={10} md={4} lg={3} mb={4} direction="column" wrap={'nowrap'} borderRight={{ xs: 'none', md:'solid #e6e6e6'}}>
        <Grid item flexBasis={'0 !important'} xs={12}>
          <Typography color="primary" fontWeight="bold">
            Interaction history
          </Typography>
        </Grid>
        <MDBox item xs={12} sx={{mr:2, maxHeight:{xs:'200px', md:'600px'}, boxShadow: '6px 6px 8px 2px #cecece', borderRadius:'10px', p:1}} overflow={'auto'} >
          {discussions?.length > 0 &&
            <Card sx={{boxShadow: 'none'}} >
              {discussions?.map((discussion, index) => {
                const isNegative = discussion?.reward < 0
                const hour = moment(discussion.created).format('HH:mm')
                return (
                  <MDBox
                    key={`discussion-key-${index}`}
                    flexBasis={'0 !important'}
                    xs={12}
                    display={"flex"}
                    justifyContent={"space-between"}
                    bgColor={index%2===0? "#6A994E0D": "transparent"}
                    sx={{ borderRadius: 2}}
                  >
                    <MDBox display={"flex"} alignItems={"center"}>
                      {discussion?.student?.image
                        ? <Box component={"img"} src={discussion?.student?.image} ml={1} alt={"profile_picture"} height={"30px"} width={"30px"}
                               sx={{objectFit: 'cover'}} borderRadius={"50%"}/>
                        : <Box component={"img"} src={defaultImg} ml={1} alt={"profile_picture"} height={"30px"} width={"30px"}
                               borderRadius={"50%"}/>
                      }
                      <MDBox display={"flex"}>
                        <Typography color={"secondary"} fontWeight="bold" ml={1} fontSize={15} >
                          {`${discussion?.student?.name}:`}
                        </Typography>
                        <Typography color={isNegative? "error": "primary"} fontWeight="bold" ml={1} fontSize={15} sx={{wordBreak: "break-word",}}>
                          {`${discussion?.action}`}
                        </Typography>
                      </MDBox>
                    </MDBox>
                    <MDBox >
                      <Typography color={"primary"} fontWeight="bold" ml={1} fontSize={15}>
                        {hour}
                      </Typography>
                      <MDBox  display={"flex"} alignItems={"center"}>
                        <Typography color={isNegative? "error": "primary"} fontWeight="bold" ml={1} fontSize={15}>
                          {discussion?.reward}
                        </Typography>
                        <PaidIcon color={isNegative? "error": "primary"} sx={{marginLeft: 1}}/>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                )
              })}
            </Card>
          }
        </MDBox>
      </Grid>

      <Grid container item xs={12} md={8} direction={'column'} wrap={'nowrap'}>
        <Grid
          item
          flexBasis={'0 !important'}
          xs={12}
        >
          <Typography color="primary" fontWeight="bold" ml={2}>
            Students
          </Typography>
        </Grid>
        <Grid container>
          <MDBox paddingX={1} display={"flex"} direction={"row"} gap={1} sx={{flexWrap: "wrap"}}>
            {attendanceStatus?.present.length > 0 && attendanceStatus.present.map( student => (
              <DiscussionStudentCard
                student={student?.student}
                openModal={onOpenDiscussionModal}
                onClickDirectAccess={recordInteraction}
                renderStudentBalance={renderStudentBalance}
              />
            ))}
          </MDBox>
        </Grid>
      </Grid>

      <DiscussionModal
        meetingId={ meetingId}
        handleClose={()=> setOpenDiscussionModal(false)}
        handleConfirm={recordInteraction}
        showModal={openDiscussionModal}
        selectedItem={selectedItem}
        renderStudentBalance={renderStudentBalance}
      />
    </Grid>
  )
}
