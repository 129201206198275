import {ArrowDropDown, ArrowDropUp, ArrowLeft, ArrowRight} from "@mui/icons-material";

const arrowColor = "#606060";
const arrowSx = { color: "#BBB9B9", fontSize: "48px", width: '48px', height: '48px'  }
export const BUTTON_OPTIONS = [
    {
        id: 1,
        button: <ArrowDropUp sx={arrowSx} />,
        color: arrowColor,
    },
    {
        id: 3,
        button: <ArrowLeft sx={arrowSx} />,
        color: arrowColor,
    },
    {
        id: 4,
        button: <ArrowRight sx={arrowSx} />,
        color: arrowColor,
    },
    {
        id: 2,
        button: <ArrowDropDown sx={arrowSx} />,
        color: arrowColor,
    },
    {
        id: 7,
        button: "X",
        color: "#FEF200",
    },
    {
        id: 8,
        button: "Y",
        color: "#00B447",
    },
    {
        id: 6,
        button: "B",
        color: "#010DFF",
    },
    {
        id: 5,
        button: "A",
        color: "#FF2D3A",
    },
];
