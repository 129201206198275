import React from "react";
import PageLayout from "components/PageLayout";
import TeacherCourseDetail from "./TeacherCourseDetail";

const CourseDetail = () => {
  return (
    <PageLayout teacherComponent={<TeacherCourseDetail />} studentComponent="Not yet implemented" />
  );
};

export default CourseDetail;
