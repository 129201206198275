import { Grid, IconButton, TextField } from "@mui/material";
import { BaseModal } from "components/BaseModal";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useState, useCallback } from "react";
import * as Yup from "yup";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {useApi} from "../../services/helpers";
const AddStudentsModal = ({ open, setOpen, courseId }) => {
  const api = useApi();
  const [step, setStep] = useState(0);
  const [emails, setEmails] = useState([]);
  const [emailsText, setEmailsText] = useState([]);

  const inviteStudents = useCallback(async (emails) => {
    api.inviteCourse(courseId, emails).handle({
      onSuccess: (res) => {
        setOpen(false)
      },
      successMessage: "Students invited successfully",
      errorMessage: "Error inviting students",
    });
  }, [api]);

  return (
    <BaseModal open={open} height="auto" width="80%">
      <MDBox sx={{ display: "flex", m: 2, alignItems: "flex-start" }}>
        <MDTypography variant="body1" color="primary" sx={{ mb: 3, fontSize: 36, flex: 1 }}>
          Add people
        </MDTypography>
        <IconButton size="small" onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </MDBox>
      <MDBox sx={{ m: 2 }}>
        {step === 0 && <Step1 emailsText={emailsText} setEmailsText={setEmailsText} />}
        {step === 1 && <Step2 emails={emails} />}
        <MDBox sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}>
          {step === 1 && <MDButton onClick={() => setStep(0)}>Start over</MDButton>}
          { !step ?
            <MDButton
              color="primary"
              sx={{ ml: 1 }}
              onClick={() => {
                setEmails(emailsText
                  .split(",")
                  .map((e) => e.trim())
                  .filter((e) => Yup.string().email().isValidSync(e)))
                setStep(step + 1)
              }}
            >
              Next
            </MDButton>
            :
            <MDButton
              color="primary"
              sx={{ ml: 1 }}
              onClick={() => {
                inviteStudents(emails);
              }}
            >
              Add Users
            </MDButton>
          }
        </MDBox>
      </MDBox>
    </BaseModal>
  );
};

const Step1 = ({ emailsText, setEmailsText }) => {
  return (
    <>
      <MDTypography variant="body2" style={{ fontWeight: 400 }} color="dark">
        Add user(s) by email
      </MDTypography>
      <MDTypography color="dark" style={{ fontWeight: 300 }} variant="body2">
        Example: user@school.edu, username@school.edu
      </MDTypography>
      <TextField
        id="outlined-multiline-static"
        multiline
        rows={4}
        fullWidth
        value={emailsText}
        onChange={(e) => setEmailsText(e.target.value)}
        defaultValue="Default Value"
        sx={{ border: "1px solid lightgrey", borderRadius: 1 }}
      />
    </>
  );
};
const Step2 = ({ emails }) => {
  return (
    <>
      <MDBox
        display="flex"
        alignItems="center"
        borderRadius={4}
        border={(theme) => `1px solid ${theme.palette.primary.main}`}
        sx={{ padding: 0 }}
      >
        <MDBox
          backgroundColor="red"
          sx={(theme) => ({
            backgroundColor: theme.palette.primary.main,
            display: "flex",
            // borderRadius: 1,
          })}
        >
          <CheckIcon color="white" sx={{ m: 1 }} />
        </MDBox>

        <MDTypography color="dark" sx={{ fontWeight: 300, pl: 1 }} variant="body2">
          The following users are ready to be added to the course
        </MDTypography>
      </MDBox>
      <MDBox sx={{height:'400px', overflow:'hidden'}}>
      <Grid container mt={2}>
        <Grid container item sx={{overflow:'auto', maxHeight:'400px'}}>
        <Grid item xs={4} borderBottom={"1px solid lightgray"}>
          <MDTypography color="dark" variant="body2">
            Name
          </MDTypography>
        </Grid>
        <Grid item xs={4} borderBottom={"1px solid lightgray"}>
          <MDTypography color="dark" variant="body2">
            Email Address
          </MDTypography>
        </Grid>
        <Grid item xs={4} borderBottom={"1px solid lightgray"}>
          <MDTypography color="dark" variant="body2">
            Institution
          </MDTypography>
        </Grid>
        {emails?.map((email) => (
          <>
            <Grid item xs={4} py={1} borderBottom={"1px solid lightgray"}>
              <MDTypography color="dark" variant="body2" sx={{ whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}></MDTypography>
            </Grid>
            <Grid item xs={4} py={1} borderBottom={"1px solid lightgray"}>
              <MDTypography color="dark" variant="body2" sx={{ whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}>
                {email}
              </MDTypography>
            </Grid>
            <Grid item xs={4} py={1} borderBottom={"1px solid lightgray"}>
              <MDTypography color="dark" variant="body2" sx={{ whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}></MDTypography>
            </Grid>
          </>
        ))}
        </Grid>
      </Grid>
      </MDBox>
    </>
  );
};

export default AddStudentsModal;
