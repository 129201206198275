import React, { useEffect, useRef, useState } from "react";
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MDBox from "../MDBox";
import DeleteIcon from "@mui/icons-material/Delete";
import coin from "../../assets/icons/coin.svg";
import sandClock from "../../assets/icons/sandClock.svg";
import Draggable from "react-draggable";
import { useStores } from "models";

const ModalItemTimer = ({
  children,
  title,
  titleColor = "white",
  handleClose = null,
  handleDelete = null,
  open,
  closeOnClickOutside = true,
  scrollable = true,
  height = 50,
  width = "100%",
  marginRightIcon,
  marginTopIcon,
  fontSizeIcon,
  timer,
  reward,
  pillMode = true,
  color = '#000'
}) => {
  const rootStore = useStores();
  const [questionX, setQuestionX] = useState(window.innerWidth / 2 - 415/2);
  const [questionY, setQuestionY] = useState(window.innerHeight - height*2);
  const [timeLeft, setTimeLeft] = useState(timer);
  const [tsOpened, setTsOpened] = useState(null);
  const windowWidth = window.innerWidth;
  const questionSideTimeout = 3000;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);

      if (timeLeft === 0) {
        setTsOpened(null)
        clearInterval(intervalId);
        handleClose();
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

    useEffect(() => {
       if(open) setTsOpened(+ new Date())
       else setTsOpened(null)
    }, [open]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secondsLeft = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secondsLeft.toString().padStart(2, "0")}`;
  };

  const onDragStop = (evt, { x, y }) => {
      setQuestionX(x)
      setQuestionY(y)
  };

  const isdraggable = tsOpened !== null && ((+ new Date() - tsOpened) > questionSideTimeout);

  const content = pillMode ? (
      <Box
          className="question"
          sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: color,
              boxShadow: 24,
              borderRadius: 10,
              display: "flex",
              flexDirection: "row",
              flex: 1,
              width: "415px",
              overflow: "hidden",
              "&:focus-visible": {
                  outline: "transparent",
              },
          }}
      >
          <MDBox
              display={"flex"}
              justifyContent={"center"}
              flexDirection={'column'}
              alignItems={'end'}
              //width={'100px'}
              pl={1}
              pr={1}
          >
              <MDBox display={"flex"} pl={1} pr={3} alignItems={"center"}>
                  <Typography color={titleColor} variant="h6" textAlign={"center"} mr={1}>
                      {formatTime(timeLeft)}
                  </Typography>
                  <MDBox component={"img"} src={sandClock} />
              </MDBox>
              {!!reward && (
                  <MDBox display={"flex"} pr={3} alignItems={"center"} justifyContent={"center"}>
                      <Typography color={titleColor} variant="h6" textAlign={"center"} mr={1}>
                          {reward}
                      </Typography>
                      <MDBox component={"img"} src={coin} />
                  </MDBox>
              )}
          </MDBox>
          <MDBox
              sx={{ overflowY: scrollable ? "auto" : "unset", overflowX: "hidden" }}
              bgColor={"white"}
              display={"flex"}
              height={height}
              width={width}
              pl={2}
          >
              {children}
          </MDBox>
      </Box>
  ) : (
      <Box
          className="question"
          sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: color,
              boxShadow: 24,
              borderRadius: 5,
              display: "flex",
              flexDirection: "column",
              flex: 1,
              width: pillMode ? "234px" : "250px",
              overflow: "hidden",
              "&:focus-visible": {
                  outline: "transparent",
              },
          }}
      >
          <MDBox style={{ display: "flex", flexDirection: "row", flex: 1 }}>
              {handleDelete !== null && (
                  <DeleteIcon
                      sx={{
                          marginRight: "auto",
                          cursor: "pointer",
                          marginLeft: marginRightIcon,
                          marginTop: marginTopIcon,
                      }}
                      color={"secondary"}
                      onClick={handleDelete}
                      fontSize={fontSizeIcon}
                  />
              )}
          </MDBox>
          <Typography color={titleColor} variant="h6" textAlign={"center"} pt={1} px={2} pb={1}>
              {title}
          </Typography>
          <MDBox display={"flex"} justifyContent={"space-between"}>
              <MDBox display={"flex"} pl={2} alignItems={"center"}>
                  <Typography color={titleColor} variant="h6" textAlign={"center"} mr={1}>
                      {formatTime(timeLeft)}
                  </Typography>
                  <MDBox component={"img"} src={sandClock} />
              </MDBox>
              {!!reward && (
                  <MDBox display={"flex"} pr={2} alignItems={"center"} justifyContent={"center"}>
                      <Typography color={titleColor} variant="h6" textAlign={"center"} mr={1}>
                          {reward}
                      </Typography>
                      <MDBox component={"img"} src={coin} />
                  </MDBox>
              )}
          </MDBox>
          <MDBox
              sx={{ overflowY: scrollable ? "auto" : "unset", overflowX: "hidden", }}
               //   width: "100%", display: 'flex', justifyContent: 'center', flexDirection: 'row'}}
              bgColor={"white"}
              display={"flex"}
              height={height}
              width={width}
          >
              {children}
          </MDBox>
      </Box>
  )

  const innerModal = (
    <Modal
      open={open}
      onClose={(event) => {
        if (closeOnClickOutside && handleClose) {
          handleClose(event);
        }
      }}
      // hideBackdrop={timer - timeLeft > questionSideTimeout}
      hideBackdrop={true}
      sx={
          isdraggable
             ? { position: "absolute" , width: "415px", height: height }
              //  ? { position: "absolute" }
          : {}
      }
    >
        {content}
    </Modal>
  );

  return isdraggable ? (
    <Draggable
      axis="both"
      bounds="body"
      handle=".question"
      defaultPosition={{ x: questionX, y: questionY }}
      position={null}
      grid={[25, 25]}
      scale={1}
      onStop={onDragStop}
    >
      {innerModal}
    </Draggable>
  ) : (
    innerModal
  );
};

export default ModalItemTimer;
