import { Box, IconButton, Radio, Slider, TextField, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useState } from "react";
import QuestionModal, { QUESTION_TYPES } from "./QuestionModal";
import PaidIcon from "@mui/icons-material/Paid";
import { Remove } from "@mui/icons-material";

const QUESTION_TYPES_TEXT = {
  [QUESTION_TYPES.MULTIPLE_CHOICE]: "Multiple Choice",
  [QUESTION_TYPES.OPEN_ENDED]: "Open Ended",
  [QUESTION_TYPES.SLIDING_SCALE]: "Sliding Scale",
};

const TeachersQuestionnaire = () => {
  const [questionModal, setQuestionModal] = useState(false);
  const [questions, setQuestions] = useState([
    {
      type: "sliding_scale",
      question: "1",
      value: 12,
      from: 10,
      to: 16,
      step: 1,
    },
  ]);

  return (
    <>
      <MDBox display="flex" py={1} mb={3} gap={1} borderBottom="1px solid rgba(0, 0, 0, 0.25)">
        <MDTypography flex={1} variant="h3" color="primary" fontWeight="regular">
          Questionnaire
        </MDTypography>
        <MDButton onClick={() => setQuestionModal(true)}>Add item</MDButton>
        <MDButton onClick={() => {}} variant="contained">
          Edit Questionnaire
        </MDButton>
      </MDBox>
      <MDTypography
        flex={1}
        variant="h3"
        color="dark"
        fontWeight="regular"
        borderBottom="1px solid rgba(0, 0, 0, 0.25)"
        sx={{ mx: 1, fontSize: 28 }}
      >
        Questionnaire Name
      </MDTypography>
      <MDBox display="flex" flexDirection="column" py={1} my={3} gap={1} sx={{ mx: 1 }}>
        {questions.map((question, index) => {
          return (
            <Box width="100%">
              <Box
                flex={1}
                key={question.question}
                display="flex"
                alignItems="center"
                borderBottom="1px solid rgba(0, 0, 0, 0.25)"
              >
                <IconButton
                  color="error"
                  onClick={() => setQuestions((prev) => prev.filter((_, i) => i !== index))}
                >
                  <Remove />
                </IconButton>
                <MDTypography
                  flex={1}
                  variant="h3"
                  color="dark"
                  sx={{ fontSize: 20 }}
                  fontWeight="regular"
                >
                  {question.question} ({QUESTION_TYPES_TEXT[question.type]})
                </MDTypography>

                <Box display="flex" flexDirection="row" alignItems="center">
                  <MDTypography color="dark">20</MDTypography>
                  <PaidIcon color="primary" sx={{ ml: "4px" }} />
                </Box>
              </Box>
              {question.type === QUESTION_TYPES.MULTIPLE_CHOICE &&
                question.options.map((option, i) => (
                  <Box display="flex" maxWidth="500px">
                    <Typography flex={1}>{option}</Typography>
                    <Radio checked={i === question.answer} />
                  </Box>
                ))}
              {question.type === QUESTION_TYPES.OPEN_ENDED && (
                <TextField
                  fullWidth
                  sx={{ border: "1px solid lightgrey", borderRadius: 1, my: 2, maxWidth: "500px" }}
                />
              )}
              {question.type === QUESTION_TYPES.SLIDING_SCALE && (
                <Slider
                  sx={{ maxWidth: "500px" }}
                  defaultValue={question.from}
                  min={question.from}
                  max={question.to}
                  getAriaValueText={(value) => `${value}`}
                  step={question.step}
                  marks={Array.from(
                    { length: Math.floor((question.to - question.from) / question.step) + 1 },
                    (_, index) => {
                      const value = index * question.step + question.from;
                      return { label: `${value}`, value };
                    }
                  )}
                  valueLabelDisplay="auto"
                />
              )}
            </Box>
          );
        })}
        <Box display="flex" justifyContent="end" gap={2}>
          <MDButton>Cancel</MDButton>
          <MDButton color="primary">Save</MDButton>
        </Box>
      </MDBox>
      <QuestionModal
        showModal={questionModal}
        handleClose={() => setQuestionModal(false)}
        onCreated={(values) => {
          setQuestions((prev) => [...prev, values]);
          setQuestionModal(false);
        }}
      />
    </>
  );
};

export default TeachersQuestionnaire;
