import { Link, useNavigate } from "react-router-dom";

// Material Dashboard 2 PRO React components
import { ROUTES } from "../../services/constants";
import MDButton from "components/MDButton";
import check from "../../assets/icons/check.svg"
import ModalItem from "../../components/ModalItem";
import MDBox from "../../components/MDBox";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import React from "react";

const SuccessfulRegistrationModal = ({ showModal, teacher }) => {
  const navigate = useNavigate();

  return (
    <ModalItem
      scrollable={false}
      open={showModal}
      closeOnClickOutside={false}
      height={"auto"}
      title="Your registration is successful!"
      titleColor={"secondary"}
      // handleClose={() => navigate(ROUTES.LOGIN)}
    >
      <MDBox display={"flex"} flexDirection={"column"} alignItems={"center"} width={"100%"} gap={3}>

        <Avatar sx={{ bgcolor: "#6A994E", width: "62px", height: "62px" }}>
          <img src={check} alt="check" />
        </Avatar>
        {teacher && <Typography color={"secondary"} variant="h4" textAlign={"center"} mb={2}>
          As a teacher, your account needs to be approved by an administrator, <br />you will receive an email when your account is approved.
        </Typography>}
        <MDButton variant="contained" color="primary" width={"209px"} onClick={() => navigate(ROUTES.LOGIN)}>
          Go to login page
        </MDButton>
      </MDBox>
    </ModalItem>
  );
};

export default SuccessfulRegistrationModal;
