import { Box, Typography } from "@mui/material";
import MDBox from "../../../components/MDBox";
import { MathJax } from "better-react-mathjax";

export const verticalButtonXZBARender = (option, index, handleOptionClick) => {
  return (
    <MDBox
      key={`id-btn-${index}`}
      border={`2px solid ${option.color}`}
      display={"flex"}
      justifyContent={"flex-start"}
      alignItems={"center"}
      gap={1}
      p={0.5}
      borderRadius={"md"}
      sx={{ overflow: "auto" }}
      onClick={()=> handleOptionClick(option)}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="20px"
        height="20px"
        p={1}
        bgcolor={option?.color}
        borderRadius="25px"
      >
        <Typography textAlign="center" fontSize={12} sx={{color:'white !important', textShadow:' -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;'}} >
          {option?.button}
        </Typography>
      </Box>
      <MDBox
        sx={{
          overflowX: "auto",
          overflowY: "hidden",
          whiteSpace: "nowrap",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Typography variant="body2" fontSize={12} color={"#fff"}>
            <MathJax>{option?.text}</MathJax>
        </Typography>
      </MDBox>
    </MDBox>
  );
};

export const verticalButtonArrowsRender = (option, index, handleOptionClick) => {
  return (
    <MDBox
      key={`id-btn-${index}`}
      border={option?.color ? `2px solid ${option.color}` : "2px solid #0B0F08"}
      display={"flex"}
      justifyContent={"flex-start"}
      alignItems={"center"}
      gap={2}
      p={0.5}
      borderRadius={"md"}
      onClick={() => handleOptionClick(option)}
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        minWidth="20px"
        height="20px"
        bgColor={option?.color ? "#D9D9D9" : null}
        borderRadius="5px"
      >
        {option?.button}
      </MDBox>
      <MDBox
        sx={{
          overflowX: "auto",
          overflowY: "hidden",
          whiteSpace: "nowrap",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Typography variant="body2" fontSize={12} fontWeight={"600"} color={"#6A994E"}>
            <MathJax>{option?.text}</MathJax>
        </Typography>
      </MDBox>
    </MDBox>
  );
};
