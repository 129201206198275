import defaultImg from "../../assets/images/default-profile.png";
import Box from "@mui/material/Box";
import {Chip, Icon} from "@mui/material";
import { ENROLMENT_STATUS } from "../../utils/helpers";

const STATUS_OPTIONS_COLORS = {
  [ENROLMENT_STATUS.INVITED]: "warning",
  [ENROLMENT_STATUS.REQUESTED]: "warning",
  [ENROLMENT_STATUS.ACCEPTED]: "success",
  [ENROLMENT_STATUS.REJECTED]: "error",
  [ENROLMENT_STATUS.WITHDRAWN]: "secondary",
}

export const dataTableModel = {
  columns: [
    {Header: "Profile Picture", accessor: "profile_picture"},
    {Header: "Name", accessor: "name"},
    {Header: "Email", accessor: "email"},
    {Header: "Balance", accessor: "balance"},
    {Header: "Status", accessor: "status"},
    {Header: "", accessor: "actions", disableOrdering: true, width:0}
  ],
  rows: [],
};

const renderProfilePicture = (item) => {
  return (
    <Box display={"flex"} alignItems={"center"} gap={1}>
      {item.image
        ? <Box component={"img"} src={item.image} alt={"profile_picture"} width={"40px"}
               sx={{objectFit: 'cover'}} borderRadius={"50%"}/>
        : <Box component={"img"} src={defaultImg} alt={"profile_picture"} width={"40px"}
               borderRadius={"50%"}/>
      }
      {item.name}
    </Box>
  )
}

const renderStatus = (item) => {
  return (
    <Chip id={item.status} color={STATUS_OPTIONS_COLORS[item.status]} label={ENROLMENT_STATUS._LABELS[item.status]} />
  )
}

const popOver = (status, item, setAnchorEl, setOpenPopover, setSelectedItem) => {
  return (
    <>
      {
        (status === ENROLMENT_STATUS.INVITED || status === ENROLMENT_STATUS.REQUESTED || status === ENROLMENT_STATUS.ACCEPTED ) &&
        <Icon
          fontSize='medium' sx={{cursor: 'pointer'}}
          onClick={(e) => {
            setAnchorEl(e.currentTarget)
            setOpenPopover(true)
            setSelectedItem(item)
          }}
        >
          more_vert
        </Icon>
      }
    </>
  )
}

export const renderTableRow = (item, setAnchorEl, setOpenPopover, setSelectedItem) => {
  const status =  item.status
  item.profile_picture = renderProfilePicture(item)
  item.status_id = item.status
  item.status = renderStatus(item)
  item.actions = (popOver(status, item, setAnchorEl, setOpenPopover, setSelectedItem))
  return item
}
