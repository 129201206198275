


const navigatorVibrate = navigator.vibrate || navigator.webkitVibrate || navigator.mozVibrate || navigator.msVibrate;

export function vibrate() {
    if (!!navigatorVibrate) {
        // vibrate will not work unless bound to navigator global
        navigatorVibrate.apply(navigator, arguments);
        return true;
    }

    // log instead of actually vibrating device if disabled
    return false;
}

export function triggerVibrate() {
    const outerArgs = arguments
    setTimeout(() => {
        vibrate(outerArgs)
    }, 1)
}
