import {Instance, SnapshotOut, types} from 'mobx-state-tree'
import {LoginStoreModel} from '../login-store/login-store'
import {withEnvironment} from "../extensions/with-environment";

/**
 * A RootStore model.
 */
// prettier-ignore
export const RootStoreModel = types.model('RootStore').extend(withEnvironment)
  .props({
    loginStore: types.optional(LoginStoreModel, {}),
    qrX: types.optional(types.number, 0),
    qrY: types.optional(types.number, 0),
    questionX: types.optional(types.number, 0),
    questionY: types.optional(types.number, 0),
  }).views(self => ({}))
  .actions(self => ({
    reset() {
      self.loginStore.reset();
    },
    setQRPosition(x:number, y:number){
      self.qrX = x;
      self.qrY = y;
    },
    setQuestionPosition(x:number, y:number){
      self.questionX = x;
      self.questionY = y;
    },
  }));


/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {
}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {
}
