import { AddCircleOutline, ChevronLeftRounded, ChevronRightRounded } from "@mui/icons-material";
import { Box, Button, Grid, IconButton } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { styles } from "./calendar.styles";
import { CalendarView } from "./constants";


export const MyToolbar = ({
  toolbar, 
  setShowScheduledServiceModal, 
  setCalendarDate,
  ...props 
}) => {
  const { view: viewState } = toolbar
  const [Label, setLabel] = useState();
  const formatLabel = useCallback(
    (date) => {
      const todayDate = moment(new Date()).format("MM/DD/YYYY");
      const nowDay = moment(date).format("MM/DD/YYYY");
      let label = "";
      if (viewState === CalendarView.DAY) {
        label = todayDate === nowDay ? `Today, ${moment(date).format("MM/DD/YYYY")}` : nowDay;
      } else {
        label = moment(date).format("MMMM YYYY");
      }
      setLabel(label);
    },
    [viewState]
  );
  //
  useEffect(() => {
    if (toolbar) {
      formatLabel(moment(toolbar.date));
    }
  }, [toolbar, viewState, formatLabel]);

  const goToPrevious = () => {
    let mDate = moment(toolbar.date);
    let newDate;
    if (viewState === CalendarView.MONTH) {
      newDate = new Date(mDate.year(), mDate.month() - 1, 1);
    } else if (viewState === CalendarView.WEEK) {
      newDate = mDate.subtract(7, "day");
      newDate = newDate.toDate();
    } else {
      newDate = mDate.subtract(1, "day");
      newDate = newDate.toDate();
    }
    setCalendarDate(newDate);
    toolbar.onNavigate("previous", newDate);
    formatLabel(newDate);
  };

  const goToNext = () => {
    let mDate = moment(toolbar.date);
    let newDate;
    if (viewState === CalendarView.MONTH) {
      newDate = new Date(mDate.year(), mDate.month() + 1, 1);
    } else if (viewState === CalendarView.WEEK) {
      newDate = mDate.add(7, "day");
      newDate = newDate.toDate();
    } else {
      newDate = mDate.add(1, "day");
      newDate = newDate.toDate();
    }
    setCalendarDate(newDate);
    toolbar.onNavigate("next", newDate);
    formatLabel(newDate);
  };

  const toolbarLabel = () => {
    return (
      <span>
        {viewState === CalendarView.MONTH && Label}
        {!!props.CurrentDate && viewState !== CalendarView.MONTH && (
          <DesktopDatePicker
            label="Date"
            inputFormat="MM/DD/YYYY"
            value={moment(props.CurrentDate)}
            InputAdornmentProps={{ position: "end" }}
            onChange={(newValue) => {
              setCalendarDate(newValue?._d)
              toolbar.onNavigate("next", newValue?._d);
            }}
          />
        )}
      </span>
    );
  };

  const changeView = (view) => {
    toolbar.onView(view);
    formatLabel(moment(toolbar.date));
  };

  return (
    <Box sx={{ marginBottom: "30px", marginTop: "20px" }}>
      <Grid container spacing={2}>
        <Grid item container xs={12} md={4} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Grid item container xs={5} wrap="nowrap" gap={2}>
            <Grid item xs={6}>
              <IconButton
                sx={{ border: "1px solid lightgray", mr: 2, boxShadow: 1 }}
                onClick={goToPrevious}
              >
                <ChevronLeftRounded />
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <IconButton sx={{ border: "1px solid lightgray", boxShadow: 1 }} onClick={goToNext}>
                <ChevronRightRounded />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <label style={{...styles.monthLabel, padding:'0', margin:1}}>{toolbarLabel()}</label>
          </Grid>
        </Grid>

        <Grid item md={4} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <div style={styles.toolbarStyle}>
            <Button
              style={viewState === CalendarView.DAY ? styles.btnStyle : styles.btnWrapperStyle}
              onClick={() => changeView(CalendarView.DAY)}
            >
              <span>Day</span>
            </Button>
            <Button
              style={viewState === CalendarView.WEEK ? styles.btnStyle : styles.btnWrapperStyle}
              onClick={() => changeView(CalendarView.WEEK)}
            >
              <span>Week</span>
            </Button>
            <Button
              style={viewState === CalendarView.MONTH ? styles.btnStyle : styles.btnWrapperStyle}
              onClick={() => changeView(CalendarView.MONTH)}
            >
              <span>Month</span>
            </Button>
          </div>
        </Grid>
        <Grid
          item
          md={4}
          style={{ display: "flex", flexDirection: "row-reverse", alignItems: "center" }}
        >
          <Button onClick={() => setShowScheduledServiceModal(true)} variant="contained">
            <AddCircleOutline />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
