import React from 'react'
import { Modal } from '@mui/material'
import Box from '@mui/material/Box'
import ClearIcon from '@mui/icons-material/Clear'
import Typography from '@mui/material/Typography'
import MDBox from '../MDBox'
import DeleteIcon from '@mui/icons-material/Delete'

const ModalItem = ({
  minWidth,
  children,
  title,
  titleColor = 'primary',
  handleClose = null,
  handleDelete = null,
  open,
  closeOnClickOutside = true,
  scrollable = true,
  height = 600,
  width = '90%',
  paddingStyle = 4,
  marginRightIcon,
  marginTopIcon,
  fontSizeIcon,
  widthContainer,
  heightContainer,
  contentContainterStyle,
  contentStyle
}) => {
  return (
    <Modal
      open={open}
      onClose={(event) => {
        if (closeOnClickOutside && handleClose) {
          handleClose(event)
        }
      }}
    >
      <Box
        sx={{
          width: { xs: width, sm: '60%'  },
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          minWidth: minWidth || 332,
          height: height || '80%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: paddingStyle,
          borderRadius: 5,
          display: 'flex',
          transition: 'width 0.5s ease-in-out',
          flexDirection: 'column',
          flex: 1,
          '&:focus-visible': {
            outline: 'transparent',
          },
          ...contentContainterStyle,
        }}
      >
        <MDBox style={{ display: 'flex', flexDirection: 'row' }}>
          {handleDelete !== null && (
            <DeleteIcon
              sx={{
                marginRight: 'auto',
                cursor: 'pointer',
                marginLeft: marginRightIcon,
                marginTop: marginTopIcon,
              }}
              color={'secondary'}
              onClick={handleDelete}
              fontSize={fontSizeIcon}
            />
          )}
          {handleClose && (
            <ClearIcon
              sx={{
                marginLeft: 'auto',
                cursor: 'pointer',
                marginRight: marginRightIcon,
                marginTop: marginTopIcon,
              }}
              color={'secondary'}
              onClick={handleClose}
              fontSize={fontSizeIcon}
            />
          )}
        </MDBox>
        <Typography color={titleColor} variant="h3" textAlign={'center'} mb={2}>
          {title}
        </Typography>
        <MDBox
          sx={{
            overflowY: scrollable ? 'auto' : 'unset',
            overflowX: 'hidden',
            ...contentStyle,
          }}
          display={'flex'}
          height={heightContainer || height}
          width={widthContainer || width}
        >
          {children}
        </MDBox>
      </Box>
    </Modal>
  )
}

export default ModalItem
