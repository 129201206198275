
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import clip from "../../assets/icons/clip.svg";
import book from "../../assets/icons/book.svg";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import QuizIcon from "@mui/icons-material/Quiz";

const LESSON_RESOURCES = {
  MATERIAL: "material",
  PRESENTATION: "presentation",
  QUESTIONNAIRE: "questionnaire",
  TEST: "test",
};

const LESSON_RESOURCE_ICONS = {
  [LESSON_RESOURCES.MATERIAL]: <MenuBookIcon />,
  [LESSON_RESOURCES.PRESENTATION]: <SlideshowIcon />,
  [LESSON_RESOURCES.QUESTIONNAIRE]: <AttachFileIcon />,
  [LESSON_RESOURCES.TEST]: <QuizIcon />,
};


const LessonCourseHome = ({lesson}) => {

  return (
    <>
      <MDTypography flex={1} ml={2} variant="h4" color="primary" fontWeight="regular">
        {lesson?.name}
      </MDTypography>
      <MDTypography flex={1} ml={2} variant="h6" color="secondary" fontWeight="regular" borderBottom="1px solid rgba(0, 0, 0, 0.25)">
        {lesson?.description}
      </MDTypography>
      {lesson?.resources?.length > 0 &&
        <>
          {lesson?.resources.map(resourse => (
            <MDBox key={resourse.name} display={"flex"} ml={2} alignItems={"center"} borderBottom="1px solid rgba(0, 0, 0, 0.25)">
              {resourse.type === LESSON_RESOURCES.MATERIAL &&
                <>
                  <img src={clip} alt="clip"/>
                  <MDTypography flex={1} variant="h4" color="secondary" fontWeight="regular">
                    {resourse.name}
                  </MDTypography>
                </>
              }
              {resourse.type === LESSON_RESOURCES.PRESENTATION &&
                <>
                  <img src={book} alt="book"/>
                  <MDTypography flex={1} variant="h4" color="secondary" fontWeight="regular">
                    {resourse.name}
                  </MDTypography>
                </>
              }
              {resourse.type === LESSON_RESOURCES.QUESTIONNAIRE &&
                <>
                  <AttachFileIcon />
                  <MDTypography flex={1} variant="h4" color="secondary" fontWeight="regular">
                    {resourse.name}
                  </MDTypography>
                </>
              }
            </MDBox>
          ))}
        </>
      }
    </>
  );
};

export default LessonCourseHome;