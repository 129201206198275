/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import {Link, useNavigate} from "react-router-dom";


// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Image
import bgImage from '../../assets/images/background/background.png';
import {observer} from "mobx-react";
import {useRef, useState} from "react";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {Container} from "@mui/material";
import {showMessage, useApi} from "../../services/helpers";
import {useStores} from "../../models";
import {ROUTES} from "../../services/constants";
import FormikInput from "../../components/FormikInput";
import pxToRem from "../../assets/theme/functions/pxToRem";
const sideBar = require("../../assets/images/background/sidebar.png");
const logo = require("../../assets/images/logos/logo.png");

function SetNewPassword() {
  const api = useApi()
  const rootStore = useStores()
  const navigate = useNavigate()
  const formikRef = useRef();
  const [loading, setLoading] = useState(false);
  const getUrls = window.location.href.split('set-new-password/')[1].split('/')

  const resetPassword = (data) => {
    const form = {
      new_password1: data.password,
      new_password2: data.new_password2,
      uidb64: getUrls[0],
      token: getUrls[1]
    }
    setLoading(true)
    api.resetPassword(form).then((result) => {
      if (result.kind === "ok") {
        showMessage('Password reset successful', 'success')
        navigate(ROUTES.LOGIN)
      } else if(result.kind === "bad-data") {
        if (result.errors.token) {
          showMessage(result.errors.token)
        } else {
          showMessage(result.errors?.errors)
          formikRef.current.setErrors(result.errors)
        }
      } else {
        showMessage()
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  const validationSchema =
    Yup.object().shape({
      password: Yup.string().required('Password is required'),
      new_password2: Yup.string().required('Confirm password is required').oneOf([Yup.ref('password'), null], "Passwords doesn't match"),
    })

  const initialValues = {
    password: "",
    new_password2: "",
  };

  return (
    <MDBox
      sx={{
        overflowX: "hidden",
        overflowY: "hidden",
        justifyItems: "center",
      }}
    >
      <MDBox
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: -1,
        }}
      >
        <img
          alt="background"
          src={sideBar}
          style={{ height: "100vh", width: "10%", objectFit: "cover" }}
        />

        <img alt="side" src={bgImage} style={{ height: "100vh", width: "90%" }} />
      </MDBox>
      <Container
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <MDBox
          // maxWidth="332px"
          bgColor="white"
          p={4}
          borderRadius="xl"
          sx={{
            width:{xs:'100%', sm:'442px'},
            margin: "auto",
            backgroundColor: "white",
          }}
        >
          <img
            src={logo}
            style={{ display: "block", margin: "auto", marginBottom: 50 }}
            alt="logo"
          />
          <MDTypography color="primary" variant="h4" fontWeight="bold" textAlign="center" mb={4}>
            Recover your account
          </MDTypography>
          <MDTypography variant="body2" textAlign="center" color="black" mb={4}>
            Enter your email to recover your account.
          </MDTypography>

          <Formik
            innerRef={formikRef}
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={values => {
              resetPassword(values);
            }}
          >
            {({errors, isValid, dirty, handleSubmit}) => (
              <Form style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                <FormikInput name={'password'} label={'New Password'} type={'password'} errors={errors} mb={1}/>
                <FormikInput name={'new_password2'} label={'Confirm New Password'} type={'password'} errors={errors}/>
                <MDBox mt={5} mb={1} textAlign="center" display="flex" gap={3} mx={3} flexDirection="row">
                  <MDButton
                    variant="contained"
                    color="primary"
                    loading={loading}
                    disabled={loading || !isValid || !dirty}
                    onClick={() => handleSubmit}
                    sx={{ width: "-webkit-fill-available" }}
                    type="submit"
                  >
                    Save
                  </MDButton>
                </MDBox>
              </Form>
            )}
          </Formik>
        </MDBox>
      </Container>
    </MDBox>
  );
}

export default observer(SetNewPassword);
