import React from "react";
import PageLayout from "components/PageLayout";
import { Navigate } from "react-router-dom";
import { ROUTES } from "services/constants";
import TeachersPresentation from "./TeachersPresentation";

const Presentation = () => {
  return (
    <PageLayout
      teacherComponent={<TeachersPresentation />}
      studentComponent={<Navigate to={ROUTES.NOT_FOUND} />}
    />
  );
};

export default Presentation;
