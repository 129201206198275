/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMaterialUIController } from "context";
import { observer } from "mobx-react";

import TeacherPageLayout from "./TeacherPageLayout";
import { useEffect, useMemo } from "react";
import MDBox from "components/MDBox";
import StudentPageLayout from "./StudentPageLayout";
import { LAYOUT_TYPES } from "context";
import { useLoginStore } from "services/helpers";
import { useLocation, useNavigate } from "react-router-dom";
import { setLayout } from "context";
import { useMediaQuery, useTheme } from "@mui/material";

function PageLayout({ children, studentComponent, teacherComponent }) {
  const theme = useTheme()
  const marginHidden = useMediaQuery(theme.breakpoints.down('sm'))
  const [controller, dispatch] = useMaterialUIController();
  const { isLoggedIn, teacher, student } = useLoginStore();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      setLayout(dispatch, LAYOUT_TYPES.PAGE);
      navigate(`/login?redirect=${encodeURIComponent(location.pathname + location.search)}`);
    }
  }, [isLoggedIn, dispatch, navigate, location.pathname, location.search]);

  useEffect(() => {
    if (teacher) setLayout(dispatch, LAYOUT_TYPES.TEACHER);
    if (student) setLayout(dispatch, LAYOUT_TYPES.STUDENT);
  }, [teacher, student, dispatch]);

  const Layout = useMemo(
    () => (controller.layout === LAYOUT_TYPES.STUDENT ? StudentPageLayout : TeacherPageLayout),
    [controller.layout]
  );
  const content = useMemo(() => {
    switch (controller.layout) {
      case LAYOUT_TYPES.STUDENT:
        return studentComponent || children || "Page not defined";
      case LAYOUT_TYPES.TEACHER:
        return teacherComponent || children || "Page not defined";
      default:
        return children || "Page not defined";
    }
  }, [controller.layout, children, studentComponent, teacherComponent]);
  
  return (
    <Layout>
      <MDBox sx={{ marginLeft: !controller.hideNavbar ? marginHidden ? 0 : '150px' : 0 , padding: "32px", overflowX:'hidden' }}>
        {content}
      </MDBox>
    </Layout>
  );
}

export default observer(PageLayout);
