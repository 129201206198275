import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import {useEffect, useState} from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import defaultImg from "../../assets/images/default-profile.png";
import {useApi} from "../../services/helpers";
import location from "../../assets/icons/location.svg"
import {CREATE_COURSE_STEPS, MODALITY_OPTIONS} from "../../utils/helpers";
import CourseProfile from "../new-course/CourseProfile";
import {useApiMessages} from "../../services/api/api-messages";
import logo from "../../assets/images/logos/logo.png"
import { Grid } from "@mui/material";

const TeacherCourseProfile = ({course, getCourse}) => {
  const api = useApi();
  const [userInfo, setUserInfo] = useState(null);
  const [isEditing, setIsEditing] = useState(false)
  const [loading, setLoading] = useState(false);
  const messages = useApiMessages('course', 'courses')

  const maxHeightm = {maxHeight:'100px'}

  const updateCourse = (data) => {
    setLoading(true)
    api.updateTeacherCourseProfile(course.id, data).handle({
      onSuccess: (res) => {
        setIsEditing(false)
        getCourse()
      },
      successMessage: messages.update.success,
      errorMessage: messages.update.error,
      onFinally: () => setLoading(false)
    });
  }

  const fetchUserInfo = () => {
    api.getUserInfo().handle({
      onSuccess: (res) => {
        setUserInfo(res.data);
      },
      errorMessage: "Error fetching user info",
    });
  }

  useEffect(() => {
    fetchUserInfo()
  }, []);

  return (
    <MDBox width={"100%"}>
              { isEditing?
        <CourseProfile loading={loading} courseData={course} onUpdate={updateCourse} setIsEditing={setIsEditing}/>
        :
        <>
      <MDBox display="flex" py={1} gap={1} borderBottom="1px solid rgba(0, 0, 0, 0.25)" justifyContent={"space-between"} alignItems={'center'}>
        <MDTypography flex={1} fontSize={{xs:'24px', md:"30px"}} variant="h3" color="primary" fontWeight="regular">
          Course Profile
        </MDTypography>
        <MDButton variant="contained" onClick={() => setIsEditing(true)} size="small">
          <MDTypography variant="subtitle2" color="dark">
            Edit Profile
          </MDTypography>
        </MDButton>
      </MDBox>

      <MDBox display={"flex"} flexDirection={"column"} >
        <Grid container
          alignItems={"center"}
          justifyContent={"space-between"}
          py={"10px"}
          sx={{borderBottom: "2px solid rgba(0, 0, 0, 0.10)", mb: "19px",}}
        >
          <Grid item xs={12} md={6}>
          <MDTypography fontSize={{xs:'28px', md:"36px"}} variant="h2" color="primary" fontWeight="regular">
            {course?.name}
          </MDTypography>
          </Grid>
         <Grid container item xs={12} md={6}>
          <Grid item xs={12} display={"flex"} justifyContent={{xs:"space-between", md:'flex-end'}} alignItems={"center"}>
            <MDTypography fontSize={{xs:'20px', md:"24px"}} variant="h4" color="primary" fontWeight="regular">
              Modality:
            </MDTypography>
            <MDTypography  fontSize={{xs:'18px', md:"20px"}} variant="h5" color="dark" pt={"4px"} pl={1} fontWeight="regular">
              {MODALITY_OPTIONS._LABELS[course?.modality] || 'No modality defined'}
            </MDTypography>
          </Grid>
          <Grid item xs={12} display={"flex"}  justifyContent={"flex-end"} alignItems={"stretch"} gap={"15px"}>
            <MDTypography variant="body2" color="dark" fontWeight="regular" textAlign={"end"} >
              {course?.location || "No location defined."}
            </MDTypography>
            <MDBox><img src={location} alt="location" /></MDBox>
          </Grid>
         </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={4} my={"15px"}>
          <MDBox component={"img"}
                 src={course?.image || logo}
                 width={"443.879px"}
                 maxHeight={"230px"}
                 sx={{objectFit: "contain", mr: "19px" }}
                 p={2}
                 
          /></Grid>
          <Grid item xs={12} md={8}>
          <MDBox display={"flex"} flexDirection={"column"}>
          <MDBox display={"flex"} justifyContent={"flex-start"} sx={{mb: "19px"}}>
            <div dangerouslySetInnerHTML={{__html: course?.summary || "No course summary."}} />
          </MDBox>
          <TeacherCard teacher={userInfo}/>
          </MDBox>
          </Grid>
        </Grid>
      </MDBox>
         </>
      }
    </MDBox>
  );
};

export default TeacherCourseProfile;

const TeacherCard = ({teacher}) => {

  return (
    <Card
      sx={{ backgroundColor: "#5F63680D"}}
    >
      <MDBox display="flex" alignContent={'center'}>
        <MDBox m={2} sx={{display:'flex', flexDirection:'column', width:'auto', justifyContent:"center",}}>
          {teacher?.teacher.image?
            <MDBox sx={{width:{xs:'80px', md:'150px'}, height:{xs:'80px', md:'150px'}, borderRadius:'100%'}}>
            <MDBox
              component={"img"} src={teacher?.teacher.image} style={{ padding:0, width:'100%', height:'100%', borderRadius: "50%", objectFit:'cover' }}
            />
            </MDBox>
            :
              <>
                <MDBox sx={{width:{xs:'80px', md:'150px'}, height:{xs:'80px', md:'150px'}}}>
            <MDBox
              component={"img"} src={defaultImg} style={{ padding:0, width:'100%', height:'100%', borderRadius: "50%", objectFit:'cover' }}
            />
                </MDBox>
              </>
          }
          <MDTypography variant="subtitle1" color="primary" fontWeight="bold" textAlign={"center"}>
            {teacher?.name}
          </MDTypography>
        </MDBox>

        <CardContent>
          <MDTypography variant="subtitle2" color="dark" fontWeight="bold">
            School Name
          </MDTypography>
          <MDTypography variant="subtitle2" color="dark" mb={1}>
            {teacher?.teacher.school}
          </MDTypography>
          <MDTypography variant="subtitle2" color="dark" fontWeight="bold">
            Email
          </MDTypography>
          <MDTypography variant="subtitle2" color="dark" mb={1}>
            {teacher?.email}
          </MDTypography>
          <MDTypography variant="subtitle2" color="dark" fontWeight="bold">
            Phone Number
          </MDTypography>
          <MDTypography variant="subtitle2" color="dark"  mb={1}>
            {teacher?.teacher.phone_number}
          </MDTypography>
          <MDTypography variant="subtitle2" color="dark" fontWeight="bold">
            Subject
          </MDTypography>
          <MDTypography variant="subtitle2" color="dark">
            {teacher?.teacher.subject}
          </MDTypography>
        </CardContent>
      </MDBox>
    </Card>
  );
};
