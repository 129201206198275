import { CircularProgress, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { CollapsableCard } from "components/CollapsableCard";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";
import React from "react";
import { useParams } from "react-router-dom";
import MDInput from "../../components/MDInput";
import { useApi } from "../../services/helpers";
import { StudentUnderstanding, StudentsEngagement } from "../admin-panel/charts";
import { QuestionStatsSmall } from "./charts";
import { StudentAnswersModal } from "./studentAnswersModal";

const MeetingTitleComponent = ({meeting}) => {
  return <MDBox display={"flex"} justifyContent={"space-between"}>
    <MDBox display={'flex'} flexDirection={"column"}>
      <MDTypography variant="h5" fontWeight={"bold"} color="primary">
        {meeting.lesson?.name}
        </MDTypography>
      <MDTypography variant="subtitle2" color="secondary" fontWeight="regular">
        Total questions asked: {meeting.questions_asked_count}
        </MDTypography>
    </MDBox>
    {/*  */}
    <MDBox display={'flex'} flexDirection={"column"}>
      <MDTypography variant="subtitle2" color="secondary" fontWeight="medium">
      {meeting.title}
      </MDTypography>
      <MDTypography variant="subtitle2" color="secondary" fontWeight="regular">
        {moment(`${meeting?.date} 00:00:00`).format("MMMM DD, YYYY")}
      </MDTypography>
      <MDTypography variant="subtitle2" color="secondary" fontWeight="regular">
        {`${moment(`${meeting?.date} ${meeting?.start_time}`).format("LT")} - 
          ${moment(`${meeting?.date} ${meeting?.end_time}`).format("LT")}`}
      </MDTypography>
    </MDBox>

  </MDBox>
}

const currentDate = new Date();
const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
const initiFilterParams =  {
  search: "",
  from_date: moment(firstDayOfMonth).format("YYYY-MM-DD"),
  to_date: moment(lastDayOfMonth).format("YYYY-MM-DD"),
  page: 1,
  page_size: 50
}


const TeacherCourseHistory = () => {
  const {courseId} = useParams()
  const api = useApi();
  const [meetings, setMeetings] = React.useState([])
  const [meetingStats, setMeetingStats] = React.useState({})
  const [meetingStatsLoading, setMeetingStatsLoading] = React.useState(false)
  const [showAnswersModal, setShowAnswersModal] = React.useState(false)
  const [selectedQuestion, setSelectedQuestion] = React.useState({})
  const [filterParams, setFilterParams] = React.useState(initiFilterParams)
  const {search, from_date: fromDate, to_date: toDate, page, page_size} = filterParams

  const setFilter = (field, value) => {
    setFilterParams({...filterParams, [field]: value})
  }

  const getCourseMeetings = (courseId, data = {}) => {
    api.listCourseMeetings(courseId, data).handle({
      onSuccess: (res) => {
        setMeetings(res.data.results);
      },
      errorMessage: "Error getting meetings",
    });
  };

  const getStats = (meetingId) => {
    setMeetingStatsLoading(true)
    api.meetingStats(meetingId).handle({
      onSuccess: (res) => {
        setMeetingStats({...meetingStats, [meetingId]: res.data})
      },
      onFinally: () => setMeetingStatsLoading(false),
    })
  }

  const onCollapse = (meetingId) => (isCollapsed) => {
    if (!isCollapsed) {
      getStats(meetingId)
    }
  }

  const onSelectQuestion = (question) => () => {
    setSelectedQuestion(question)
    setShowAnswersModal(true)
  }

  React.useEffect(() => {
    getCourseMeetings(courseId, filterParams);
  }, [courseId, filterParams]);

  return (
    <MDBox width={"100%"}>
      <MDBox py={1} gap={1} borderBottom="1px solid rgba(0, 0, 0, 0.25)">
        <MDTypography flex={1} variant="h3" color="primary" fontWeight="regular">
          Course history
        </MDTypography>
      </MDBox>
      <Grid container mt={4}>
        {/* FILTERS */}
        <Box item xs={12} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"space-between"} gap={2}>
          <Box width={{xs:'100%', md:"30%"}}>
            <MDInput
              placeholder="Search..."
              size="small"
              fullWidth
              sx={{marginBottom: 0}}
              value={search}
              onChange={({currentTarget}) => {
                setFilter("search", currentTarget.value);
              }}
            />
          </Box>
          <Box display={"flex"} gap={2}>
            <DatePicker
              sx={{border: "1px solid lightgray", borderRadius: 2}}
              value={moment(fromDate, "YYYY-MM-DD")}
              onChange={(value, errors) => {
                setFilter("from_date", value.format("YYYY-MM-DD"));
              }}
            />
            <DatePicker
              sx={{border: "1px solid lightgray", borderRadius: 2}}
              value={moment(toDate, "YYYY-MM-DD")}
              onChange={(value, errors) => {
                setFilter("to_date", value.format("YYYY-MM-DD"));
              }}
            />
          </Box>
        </Box>
        {/* LIST */}
        <Grid item xs={12} mt={4}>
        {meetings.map((meeting, midx) =>
          <CollapsableCard
            key={`meeting-${meeting.id}`}
            TitleComponent={<MeetingTitleComponent meeting={meeting}/>}
            onCollapse={onCollapse(meeting.id)}
          >
            {!meetingStatsLoading &&
            <Grid container gap={2} alignItems={"center"}>
                <Grid item xs={3} display={"flex"} flexDirection={"column"} gap={2}>
                  <StudentsEngagement stattsData={meetingStats[meeting.id]?.engagements} />
                  <StudentUnderstanding stattsData={meetingStats[meeting.id]?.understandings} />
                </Grid>
                <Grid item xs={8}>
                  <Grid container display={"flex"} flexWrap={"wrap"}>
                  {meetingStats[meeting.id]?.understandings?.map((stat, index) => {
                    const date = moment(stat.created)
                    // console.log("QuestionStats stat", stat)
                    return (
                      <Grid item xs={6} onClick={onSelectQuestion({meeting_id: meeting.id, ...stat})}>
                        <QuestionStatsSmall
                          key={stat.id}
                          heigth={'40px'}
                          width={"200px"}
                          title={`${index + 1}. ${stat.prompt}`}
                          data={stat}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
            </Grid>}
            {!!meetingStatsLoading &&
              <Box display={"flex"} justifyContent={"center"}>
                <CircularProgress color="secondary" />
              </Box>}
          </CollapsableCard>
        )}
      </Grid>
      </Grid>
      {/*  */}
      <StudentAnswersModal
        question={selectedQuestion}
        showModal={showAnswersModal}
        setShowModal={setShowAnswersModal}
      />
    </MDBox>
  );
};

export default TeacherCourseHistory;
