import React, {useState} from "react";
import {Avatar, Box, Grid, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, SvgIcon} from "@mui/material";
import chat from "../../assets/icons/chat.svg";
import defaultProfile from "../../assets/images/default-profile.png";
import MDTypography from "../../components/MDTypography";
import studentOnline from "../../assets/icons/studentOnline.svg";
import studentOffline from "../../assets/icons/studentOffline.svg";
import studentPresent from "../../assets/icons/studentPresent.svg";
import studentTardy from "../../assets/icons/studentTardy.svg";
import studentAbsent from "../../assets/icons/studentAbsent.svg";
import toiletPaper from "../../assets/icons/toilet-paper.svg";
import flag from "../../assets/icons/flag.svg";
import thumbsUp from "../../assets/icons/thumbs-up.svg";
import handsUp from "../../assets/icons/hands-up.svg";
import thumbsDown from "../../assets/icons/thumbs-down.svg";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import calendarOrder from "../../assets/icons/calendar-order.svg";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import thermometerYellow from "../../assets/icons/thermometer-yellow.svg";
import signalHigh from "../../assets/icons/signal-high.svg";
import PaidIcon from "@mui/icons-material/Paid";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import {ChatSection} from "./StudentTabRowChat";
import {BARS, CONNECTED_FLAG, STUDENT_FLAGS, THERMOMETER} from "../../services/constants";
import {ACTIVE_BUTTON_COLOR, INACTIVE_BUTTON_COLOR} from "../game-view/common_subscreens";
import {useManualBucksModal} from "./StudentManualBuckModal";
import {useApi} from "../../services/helpers";
import {useParams} from "react-router-dom";
import {BarsIcon} from "../../components/BarsIcon";
import {ThermometerIcon} from "../../components/ThermometerIcon";
import Toast from "../../components/Toast";


export const StudentRow = ({ student, onSelect, isExpanded, absent, tardy, balances, flags, plusBucksPresets, minusBucksPresets }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const {courseId, meetingId} = useParams()
    const open = Boolean(anchorEl);
    const api = useApi()
    const [bucksPresets, setBucksPresets] = useState([])

    const overrideAttendance = (meetingId, data) => {
        api.overrideAttendance(meetingId, data).handle({
            successMessage: "Attendance marked succesfully",
            errorMessage: "Error marking attendance",
        })
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const updateStatus = (status) => {
        handleClose()
        const data = {
            student: student.id,
            action: status
        }
        overrideAttendance(meetingId, data)
    };

    const sendBucks = (plus) => {
        setBucksPresets(!!plus ? plusBucksPresets : minusBucksPresets)
        showModal({
            studentName: student.name,
            studentId: student.id,
            plus,
        })
    }

    const renderStudentBalance = (student) => {
        const value = balances[student?.user_id]
        return value !== undefined ? value : '-'
    }

    const [dialog, showModal] = useManualBucksModal({ sendBucks: (data, values) => {
            api.manualBucks(meetingId, {
                student: data.studentId,
                amount: values.amount,
                description: values.message,
                plus: data.plus,
            }).handle({
                successMessage: data.plus ? "Bucks sent" : "Bucks taken",
                errorMessage:data.plus ? "Error sending bucks" : "Error taking bucks",
            })
        }, bucksPresets: bucksPresets })

    const studentConnectionStatusIcon = (flags[CONNECTED_FLAG] || false) ? studentOnline : studentOffline;
    const studentAttendanceStatusIcon = tardy ? studentTardy : studentPresent;

    const showToast = (text, title="") => {
        Toast.fire({
            icon: 'success',
            title: title,
            timer: 10000,
            html: `<span style="font-size: 12px">
                ${text}
           </span>`
        })
    }

    return (
        <>
            {dialog}
            <Grid
               xs={12}
               container
               flexDirection="row"
               alignItems="center"
               bgcolor={isExpanded ? "rgba(106, 153, 78, 0.25)" : undefined}
               py={'8px'}
               overflow={"auto"}
               flexWrap={'wrap'}
               borderBottom="2px solid rgba(106, 153, 78, 0.25)"

            >
                <Grid container item xs={12} md={6} xl={6} xxl={absent ? 6 : 4} flexWrap={'nowrap'} flexDirection={'row'} >
                    <Box display="flex" flexWrap="nowrap" flexDirection="row" alignItems="center" width={'100%'}>
                        <IconButton onClick={onSelect}>
                            <Icon>
                                <img src={chat} alt="chat-icon" />
                            </Icon>
                        </IconButton>
                        <Avatar src={defaultProfile} />
                        <MDTypography
                            color="dark"
                            variant="body1"
                            ml={1}
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                flexGrow: 1, // Esto asegura que el texto crezca con el contenedor
                                minWidth: 0 // Es necesario para que el texto no fuerce el crecimiento del contenedor
                            }}
                        >
                            {student.name}
                        </MDTypography>
                    </Box>
                </Grid>
                {!absent &&
                <Grid container item justifyContent={{xs:'start',md:'end', xxl:'start'}} xs={12} md={6}  xl={6} xxl={3} flexWrap={'nowrap'} flexDirection={'row'} >

                <Grid item flexWrap={'nowrap'} px={1}
                      // borderRight="2px solid lightgray"
                >
                    <IconButton sx={{ boxShadow: 2, mx: "2px" }}>
                        <Icon>
                            <img src={studentConnectionStatusIcon} alt="connection-status-icon" />
                        </Icon>
                    </IconButton>
                    <IconButton sx={{ boxShadow: 2, mx: "2px" }}>
                        <Icon>
                            <img src={studentAttendanceStatusIcon} alt="attendance-status-icon" />
                        </Icon>
                    </IconButton>
                    <IconButton sx={{
                        boxShadow: 2,
                        mx: "2px",
                        backgroundColor: flags[STUDENT_FLAGS.BATHROOM] ? ACTIVE_BUTTON_COLOR : INACTIVE_BUTTON_COLOR
                    }}>
                        <Icon>
                            <img src={toiletPaper} alt="toilet-paper" />
                        </Icon>
                    </IconButton>
                    <IconButton sx={{
                        boxShadow: 2,
                        mx: "2px",
                        backgroundColor: flags[STUDENT_FLAGS.FLAG] ? ACTIVE_BUTTON_COLOR : INACTIVE_BUTTON_COLOR
                    }}>
                        <Icon>
                            <img src={flag} alt="flag" />
                        </Icon>
                    </IconButton>
                    <IconButton sx={{
                        boxShadow: 2,
                        mx: "2px",
                        backgroundColor: flags[STUDENT_FLAGS.THUMBS_UP] ? ACTIVE_BUTTON_COLOR : INACTIVE_BUTTON_COLOR
                    }}>
                        <Icon>
                            <img src={thumbsUp} alt="thumbs-up" />
                        </Icon>
                    </IconButton>
                    <IconButton sx={{
                        boxShadow: 2,
                        mx: "2px",
                        backgroundColor: flags[STUDENT_FLAGS.RAISE_HAND] ? ACTIVE_BUTTON_COLOR : INACTIVE_BUTTON_COLOR
                    }}>
                        <Icon>
                            <img src={handsUp} alt="hands-up" />
                        </Icon>
                    </IconButton>
                    <IconButton sx={{
                        boxShadow: 2,
                        mx: "2px",
                        backgroundColor: flags[STUDENT_FLAGS.THUMBS_DOWN] ? ACTIVE_BUTTON_COLOR : INACTIVE_BUTTON_COLOR
                    }}>
                        <Icon>
                            <img src={thumbsDown} alt="thumbs-down" />
                        </Icon>
                    </IconButton>
                </Grid>
                </Grid>}
                <Grid container item xs={12} md={12} lg={absent ? 6 : 12}  xxl={absent ? 6 : 5} flexWrap={'nowrap'} justifyContent={{xs:'start', md:absent ? 'start' : 'end', lg:'end', xxl:'end'}} flexDirection={'row'}  >
                  <Box display={'flex'} alignItems={'center'} justifyContent={'center'} flexWrap={"nowrap"} px={1} borderRight="3px solid lightgray">
                    <IconButton color="primary" onClick={() => sendBucks(true)}>
                        <AddRoundedIcon />
                    </IconButton>
                    {/*<IconButton>*/}
                        <Icon>
                            <img src={calendarOrder} alt="thumbs-down" />
                        </Icon>
                    {/*</IconButton>*/}
                    <IconButton color="error" onClick={() => sendBucks(false)}>
                        <RemoveRoundedIcon />
                    </IconButton>
                </Box>
                {!absent && <>

                 <Box textAlign={'center'} flexWrap={'nowrap'} borderRight="3px solid lightgray">
                    <IconButton
                        onClick={() => {
                            if (flags[THERMOMETER]) {
                                const text = `Student: ${student.name}<br />${(flags[THERMOMETER]?.desc || '').replaceAll('\n', '<br />')}`
                                showToast(text, "Thermometer explanation")
                            }
                        }}
                    >
                        <Icon>
                            <ThermometerIcon level={flags[THERMOMETER]?.value || 0} fill={"#6A994E"}/>
                        </Icon>
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            if (flags[BARS]) {
                                const text = `Student: ${student.name}<br />${(flags[BARS]?.desc || '').replaceAll('\n', '<br />')}`
                                showToast(text, "Signal bars explanation")
                            }
                        }}
                    >
                        <BarsIcon bars={flags[BARS]?.bars !== undefined ? flags[BARS]?.bars : 5} color={flags[BARS]?.color || 'grey'}/>
                    </IconButton>
                </Box>
                  </>}
                 <Box display="flex" flexDirection="row" alignItems="center" px={1}>
                    <MDTypography color="dark">{renderStudentBalance(student)}</MDTypography>
                    <PaidIcon color="primary" sx={{marginLeft: 1}}/>
                </Box>
                 <Box px={1} borderLeft="3px solid lightgray">
                    <IconButton onClick={handleClick}>
                        <MoreVertRoundedIcon/>
                    </IconButton>
                 </Box>
            </Grid>
            <Box display={isExpanded ? "flex" : "none"} mb={3} p={1} width={'100%'}>
                <ChatSection />
            </Box>
            </Grid>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem onClick={() => updateStatus("present")}>
                    <ListItemText>Mark present</ListItemText>
                    <ListItemIcon sx={{ ml: 1 }}>
                        <img src={studentPresent} alt="student-attended" />
                    </ListItemIcon>
                </MenuItem>
                <MenuItem onClick={() => updateStatus("tardy")}>
                    <ListItemText>Mark tardy</ListItemText>
                    <ListItemIcon sx={{ ml: 1 }}>
                        <img src={studentTardy} alt="student-attended" />
                    </ListItemIcon>
                </MenuItem>
                <MenuItem onClick={() => updateStatus("absent")}>
                    <ListItemText>Mark absent</ListItemText>
                    <ListItemIcon sx={{ ml: 1 }}>
                        <img src={studentAbsent} alt="student-attended" />
                    </ListItemIcon>
                </MenuItem>
            </Menu>
        </>
    );
};
