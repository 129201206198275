import React from "react";

import moment from "moment";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./styles.css";
import "./paper-dashboard.css";
import { styles } from "./calendar.styles";
import MDTypography from "../../components/MDTypography";
import dayjs from "dayjs";
import { date_fmt } from "../../services/helpers";

export const REPEAT_OPTIONS = {
  daily: "daily",
  weekly: "weekly",
  biweekly: "biweekly",
  monthly: "monthly",
};

export const REPEAT_OPTIONS_LIST = [
  { key: REPEAT_OPTIONS.daily, label: "Daily", id: 1 },
  { key: REPEAT_OPTIONS.weekly, label: "Weekly", id: 2 },
  { key: REPEAT_OPTIONS.biweekly, label: "Bi-Weekly", id: 3 },
  { key: REPEAT_OPTIONS.monthly, label: "Monthly", id: 4 },
];
export const DAYS_OF_WEEK = [
  { day: "Monday", id: 0 },
  { day: "Tuesday", id: 1 },
  { day: "Wednesday", id: 2 },
  { day: "Thursday", id: 3 },
  { day: "Friday", id: 4 },
  { day: "Saturday", id: 5 },
  { day: "Sunday", id: 6 },
];
export const EventViewWeek = ({ event }) => {
  return (
    <div style={styles.eventViewWeekContent}>
      <div>
        <span style={styles.eventViewWeekTitle}>{event.title}</span>
      </div>
    </div>
  );
};
export const WeekHeaderCellContent = (props) => {
  const { date } = props;
  return (
    <div style={{ height: 90, paddingTop: 20, marginBottom: 20 }}>
      <div style={styles.dayWeekStyle} component="span">
        {moment(date).format("ddd")}
      </div>
      <div component="span" style={styles.dayWeekTextStyle}>
        {moment(date).format("D")}
      </div>
    </div>
  );
};
export const MonthHeaderCellContent = (props) => {
  const { date } = props;
  return (
    <div style={styles.monthHeaderStyle}>
      <div style={styles.dayMonthStyle} component="span">
        {moment(date).format("ddd")}
      </div>
    </div>
  );
};
export const DateCellWrapper = ({ date, label }) => {
  return (
    <div style={styles.monthDayCellStyle}>
      <span>{label}</span>
    </div>
  );
};
export const EventViewMonth = ({ event }) => {
  return (
    <div style={styles.eventViewMonthContent}>
      <span style={styles.eventViewMonthTitle}>{event.title}</span>
    </div>
  );
};
export const EventViewDay = ({ event, ...props }) => {
  let backgroundColor = event?.color || "#6A994E";
  return (
    <div style={{ ...styles.eventViewDayContent, backgroundColor }} {...props}>
      <MDTypography
        style={{
          color: "white",
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        {` ${event.title} `}
      </MDTypography>
      <span
        style={{
          ...styles.desStyle,
          color: "white",
          fontWeight: "bold",
        }}
      >{`${moment(event.start).format("h:mm A")} - ${moment(event.end).format("h:mm A")}`}</span>
    </div>
  );
};
export const AllDayEvent = ({ event, viewState }) => {
  const addTeamDrageOver = (e) => {
    e.preventDefault();
  };
  return (
    <div
      className={viewState === 3 ? "" : "pt-2"}
      draggable
      onDragOver={(e) => event.allDay && addTeamDrageOver(e)}
    >
      <span
        style={{
          fontWeight: event.allDay ? "500" : "600",
          fontFamily: "Montserrat",
          fontSize: 12,
          color: "white",
        }}
      >
        {event.title}
      </span>
    </div>
  );
};

export const combineDate = (date_s, time_s) => {
  return moment(`${date_s}T${time_s}`).toDate();
};

export const minMaxTimeValidator = (hour) =>
  dayjs().set("hour", moment(hour, "HH:mm").hours()).startOf("hour");
export const updateTimeValidator = (time) =>
  date_fmt(time, "HH:mm:ss") === "Invalid date" ? time : date_fmt(time, "HH:mm:ss");
export const dateValidator = (date) =>
  date_fmt(date, "YYYY-MM-DD") === "Invalid date" ? date : date_fmt(date, "YYYY-MM-DD");
