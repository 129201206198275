import {Box, IconButton, TextField, Typography} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import React from "react";

export const ChatSection = () => {
    const messages = [
        { id: 1, message: "Hi there this is a message", sender: "student" },
        { id: 2, message: "Hi there this is a message", sender: "student" },
        { id: 3, message: "Hi there this is a message", sender: "teacher" },
        { id: 54, message: "Hi there this is a message", sender: "teacher" },
        { id: 5, message: "Hi there this is a message", sender: "teacher" },
    ];
    return (
        <Box width="100%" border="1px solid lightgray" borderRadius={2}>
            <Box maxHeight="200px" sx={{ overflowY: "scroll" }}>
                {messages.map((message) => (
                    <Box
                        width="100%"
                        key={message.id}
                        display="flex"
                        justifyContent={message.sender === "student" ? "flex-start" : "flex-end"}
                    >
                        <Typography
                            boxShadow={1}
                            variant="body2"
                            p={1}
                            borderRadius={2}
                            m="5px"
                            bgcolor={message.sender === "teacher" ? "rgba(106, 153, 78, 0.25)" : undefined}
                        >
                            {message.message}
                        </Typography>
                    </Box>
                ))}
            </Box>
            <Box display="flex" flexDirection="row" borderTop="1px solid lightgray">
                <TextField fullWidth variant="outlined" />
                <IconButton size="small" color="primary">
                    <SendIcon />
                </IconButton>
            </Box>
        </Box>
    );
};
