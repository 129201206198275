import React, { useEffect, useState } from "react";
import MDTypography from "components/MDTypography";
import CourseProfile from "./CourseProfile";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Grid } from "@mui/material";
import CourseStudentList from "./CourseStudentList";
import CalendarPage from "pages/calendar";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import {useNavigate, useSearchParams} from "react-router-dom";
import {CREATE_COURSE_STEPS} from "../../utils/helpers";
import {useApi} from "../../services/helpers";
import {useApiMessages} from "../../services/api/api-messages";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import {ROUTES} from "../../services/constants";

const TeacherNewCourse = () => {
  const api = useApi();
  const navigate = useNavigate();
  const messages = useApiMessages('course', 'courses')
  const [searchParams, setSearchParams] = useSearchParams();
  const [step, setStep] = useState(parseInt(searchParams.get('step')) || CREATE_COURSE_STEPS.STEP_1);
  const [courseId, setCourseId] = useState(searchParams.get('course') || null);
  const [loading, setLoading] = useState(false);

  const createCourse = (data) => {
    setLoading(true)
    api.createTeacherCourse(data).handle({
      onSuccess: (res) => {
        setCourseId(res.response.id)
        setSearchParams({step: CREATE_COURSE_STEPS.STEP_2, course: res.response.id})
      },
      successMessage: messages.create.success,
      errorMessage: messages.create.error,
      onFinally: () => setLoading(false)
    });
  }

  useEffect(() => {
    // Updating step and set course id after creation
    setStep(parseInt(searchParams.get('step')) || CREATE_COURSE_STEPS.STEP_1)
    setCourseId(parseInt(searchParams.get('course')) || null)
  }, [searchParams]);
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {

  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: theme.palette.primary.main,
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    width: 'auto',
    backgroundColor:'grey',
    opacity:'50%',
    borderRadius: 1
  }
}))
const steps = [
  "Course profile",
  "Invite students",
  "Calendar",
];

  return (
    <>
      <Grid container py={1} borderBottom="1px solid rgba(0, 0, 0, 0.25)">
        <Grid item xs={12} container direction={'row'} justifyContent={'space-between'} gap={2}>
        <Grid item xs={12} md={6} alignItems="center">
          <MDTypography flex={1} fontSize={{xs:'24px', md:"30px"}} variant="h3" color="dark" fontWeight="regular">
            New Course
          </MDTypography>
        </Grid>
        <Grid item xs={12} md={5} display={'flex'} justifyContent={'flex-end'}>
          <Stepper alternativeLabel connector={<ColorlibConnector/>} activeStep={step} style={{fontSize:'25px', width:'100%'}} >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel><MDTypography color='dark' fontWeight="regular" fontSize={{xs:'16px', md:"20px"}} >{label}</MDTypography></StepLabel>
              </Step>
            ))}
          </Stepper>
          </Grid>

        </Grid>
      </Grid>

      <MDTypography flex={1} fontSize={{xs:'24px', md:"30px"}} variant="h3" color="dark" fontWeight="regular">
        {step === CREATE_COURSE_STEPS.STEP_1 && <CourseProfile loading={loading} onCreate={createCourse} />}

        {step === CREATE_COURSE_STEPS.STEP_2 &&
            <>
              <CourseStudentList courseId={courseId}/>
              <MDBox
                  display="flex"
                  justifyContent="flex-end"
                  py={3}
              >
                <MDButton
                    color="primary"
                    onClick={() => setSearchParams({step: CREATE_COURSE_STEPS.STEP_3, course: courseId})}
                >
                  Skip / Next
                </MDButton>
              </MDBox>
            </>

            }

        {step === CREATE_COURSE_STEPS.STEP_3 && (
          <>
            <CalendarPage courseId={courseId} />
            <MDBox
              display="flex"
              justifyContent="flex-end"
              py={3}
            >
              <MDButton
                color="primary"
                onClick={() => navigate(`${ROUTES.COURSES}/${courseId}/home`)}
              >
                Go to course
              </MDButton>
            </MDBox>
          </>
        )}

      </MDTypography>
    </>
  );
};

export default TeacherNewCourse;
