import { Grid } from "@mui/material";
import FormikInput from "components/FormikInput";
import MDButton from "components/MDButton";
import ModalItem from "components/ModalItem";
import {Form, FormikProvider, useFormik} from "formik";
import * as Yup from "yup";

const LessonModal = ({ showModal, handleClose, onSave, lesson }) => {

  const lessonValidationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    description: Yup.string().required("Description is required"),
  })

  const onSubmit = (values) => {
    onSave(values)
  };

  const formik = useFormik({
    initialValues: lesson || {name: null, description: null},
    validateOnChange: false,
    validationSchema: lessonValidationSchema,
    enableReinitialize: true,
    onSubmit: onSubmit,
  })

  return (
    <ModalItem
      scrollable={false}
      open={showModal}
      closeOnClickOutside={false}
      height="358px"
      width='90%'
      title="Edit Lesson"
      handleClose={() => handleClose()}
    >
      <FormikProvider value={formik}>
        <Form style={{ width: "100%" }}>
          <Grid container>
            <Grid item xs={12}>
              <FormikInput name="name" value={formik.values.name} placeholder="Name" errors={formik.errors} fullWidth />
              <FormikInput name="description" placeholder="Description" type={"textarea"} rows={3} errors={formik.errors} fullWidth />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <MDButton color="primary" onClick={formik.handleSubmit}>
                Save
              </MDButton>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </ModalItem>
  );
};

export default LessonModal;
