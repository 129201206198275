import RefreshIcon from "@mui/icons-material/Refresh"
import DoneIcon from "@mui/icons-material/Done"
import EditIcon from "@mui/icons-material/Edit"
import PhotoIcon from "@mui/icons-material/Photo"
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import PaidIcon from "@mui/icons-material/Paid"
import { Avatar, Box, Button, Card, CardContent, Grid, Stack, Typography } from "@mui/material"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import { MathJax } from "better-react-mathjax"
import Tooltip from "@mui/material/Tooltip"
import ModalItem from "components/ModalItem"
import { Form, Formik } from "formik"
import React from "react"
import { useParams } from "react-router-dom"
import * as Yup from "yup"
import paperPlane from "../../assets/icons/PaperPlaneTilt.svg"
import FormikInput from "../../components/FormikInput"
import MDTypography from "../../components/MDTypography"
import { useApi } from "../../services/helpers"
import { STUDENT_TASK_STATUSES } from "../../services/constants"
import MDBox from "../../components/MDBox"
import MDButton from "../../components/MDButton"
import { makeStyles } from "@mui/styles"

const TaskCompletionCard = ({
                              student,
                              task = {},
                              image,
                              completedAt,
                              loading = false,
                              onApprove = () => {
                              },
                              onReject = () => {
                              },
                              onEdit = () => {
                              },
                              onViewImage,
                            }) => {
  const useStyles = makeStyles((theme) => ({
    popper: {
      backgroundColor: "transparent",
    },
    arrow: {
      "&:before": {
        border: "1px solid #E6E8ED",
      },
      color: theme.palette.common.white,
    },
    tooltip: {
      backgroundColor: theme.palette.common.white,
      border: "1px solid #E6E8ED",
      color: "#4A4A4A",
      fontSize: 11,
    },
  }))
  let classes = useStyles()
  //
  return (
    <Card sx={{ display: "flex", alignItems: "center", mb: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
        <Avatar alt={student.name} src={student.image} sx={{ width: 56, height: 56, mr: 2 }} />
        <Box>
          <Typography variant="h6" color="primary">
            {student.name}
          </Typography>
        </Box>
      </Box>
      <CardContent sx={{ flex: 1, width: "100%" }}>
        <Typography variant="h6" color="primary">
          <Tooltip
            title={task?.description}
            placement={"top"}
            classes={{ arrow: classes.arrow, tooltip: classes.tooltip, popper: classes.popper }}
          >
            {task?.name}
          </Tooltip>
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {task?.description}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {/*Reward: {task.reward}*/}
        </Typography>
        {/* <Typography variant="body2" color="textSecondary">
          Completed At: {new Date(completedAt).toLocaleString()}
        </Typography> */}
        {/* BUTTONS */}
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1, mt: 2, width: "100%" }}>
          {/* APPROVE */}
          <MDButton
            disabled={loading}
            color="primary"
            variant="contained"
            size="medium"
            onClick={() => onApprove()}
          >
            <DoneIcon fontSize="large" />
          </MDButton>
          {/* REJECT */}
          <Button
            disabled={loading}
            color="error"
            variant="contained"
            size="medium"
            sx={{ backgroundColor: "#D94F4F" }}
            onClick={() => onReject()}
          >
            <RefreshIcon color={"white"} fontSize="large" />
          </Button>
          {/* EDIT REWARD */}
          <Button
            disabled={loading}
            color="secondary"
            variant="outlined"
            size="medium"
            onClick={() => onEdit()}
          >
            <EditIcon color={"primary"} fontSize="large" />
          </Button>
          {!!onViewImage &&
            <Button
              disabled={loading}
              color="secondary"
              variant="outlined"
              size="medium"
              onClick={() => onViewImage()}
            >
              <PhotoIcon color={"primary"} fontSize="large" />
            </Button>}
        </Box>
      </CardContent>
    </Card>
  )
}

const EditTaskRewardModal = ({
                               showModal = false,
                               handleClose,
                               task = {},
                               loading = false,
                               onSubmit = () => {
                               },
                             }) => {
  const { id: eventId, student = {}, task: taskItem = {} } = task
  const initialValues = {
    rewardAmount: task.task?.reward || 0,
    justification: "",
  }

  const validationSchema = Yup.object({
    rewardAmount: Yup.number()
      .min(0, "Reward amount cannot be negative")
      .required("Reward amount is required"),
    justification: Yup.string().required("Justification is required"),
  })

  const onFormikSubmit = (values) => {
    const { rewardAmount, justification } = values
    // sendTaskApproval
    onSubmit(
      eventId,
      student.id,
      taskItem.id,
      STUDENT_TASK_STATUSES.APPROVED,
      rewardAmount,
      justification,
    )
  }
  //
  return (
    <ModalItem
      scrollable={true}
      open={showModal}
      closeOnClickOutside={false}
      title={`Edit reward`}
      handleClose={handleClose}
      widthContainer={"100%"}
    >
      <Box sx={{ width: "100%" }}>
        <Box>
          <Stack direction="row" alignItems="center" spacing={2}>
            {task.student?.image ? (
              <Avatar src={task.student.image} alt={task.student.name} />
            ) : (
              <Avatar>{task.student?.name?.charAt(0)}</Avatar>
            )}
            <Typography>{task.student?.name}</Typography>
          </Stack>
        </Box>
        {/*  */}
        <Box sx={{ marginTop: 2, marginBottom: 4 }}>
          <Box display={"flex"} gap={2} alignItems={"center"}>
            <Typography>Task: </Typography>
            <Typography fontSize={"medium"}>{task.task?.name}</Typography>
          </Box>
          <Typography fontSize={"small"}>{task.task?.description}</Typography>
        </Box>
        {/*  */}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onFormikSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Box sx={{ mt: 2 }}>
                <FormikInput name="rewardAmount" type={"number"} label="Reward Amount" />
              </Box>
              <Box sx={{ mt: 2 }}>
                <FormikInput
                  name="justification"
                  type={"textarea"}
                  label="Justification"
                  rows={2}
                />
              </Box>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  // border: '2px solid red',
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  sx={{ width: "100%" }}
                >
                  Submit
                </Button>
                {/* <Button type="button" variant="outlined" color="secondary" onClick={handleClose}>
                  Cancel
                </Button> */}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </ModalItem>
  )
}

const TaskGroupDetailModal = ({ showModal = false, handleClose, taskGroup = {} }) => {
  const { tasks = [] } = taskGroup
  //
  return (
    <ModalItem
      scrollable={true}
      open={showModal}
      closeOnClickOutside={false}
      // height="70%"
      title={`${taskGroup?.name}`}
      handleClose={handleClose}
      // width={'100%'}
      // widthContainer={'100%'}
    >
      <List
        sx={{
          width: "100%",
          maxWidth: 360,
          bgcolor: "background.paper",
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        {!!tasks &&
          tasks.length > 0 &&
          tasks.map((task, tidx) => {
            const { name, description, reward } = task
            return (
              <ListItem key={`task-${task.id}`}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: 'space-around',
                  }}
                >
                  <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                    <MDTypography color="primary">{name}</MDTypography>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <MDTypography color={"secondary"}>{reward}</MDTypography>
                      <PaidIcon color="primary" />
                    </Box>
                  </Box>
                  <Box fontSize="small">{description}</Box>
                </Box>
              </ListItem>
            )
          })}
      </List>
    </ModalItem>
  )
}

export const TaskGroupsTab = ({ taskGroups = [], studentTasks = [], loading = false }) => {
  const { courseId, meetingId } = useParams()
  const api = useApi()
  // task group detail modal
  const [selectedTask, setSelectedTask] = React.useState({})
  const [showTaskDetailModal, setShowTaskDetailModal] = React.useState(false)
  // override reward modal
  const [selectedStudentTask, setSelectedStudentTask] = React.useState({})
  const [showEditTaskRewardModal, setShowEditTaskRewardModal] = React.useState(false)
  const [showTaskImageModal, setShowTaskImageModal] = React.useState(false)
  const [currentTaskImage, setCurrentTaskImage] = React.useState(null)
  //
  const [sendTaskApprovalLoading, setSendTaskApprovalLoading] = React.useState(false)

  const openTaskDetailModalHandler = (taskGroup) => {
    setSelectedTask(taskGroup)
    setShowTaskDetailModal(true)
  }
  const closeTaskDetailModalHandler = () => {
    setSelectedTask({})
    setShowTaskDetailModal(false)
  }
  //
  const openEditTaskRewardModalHandler = (studentTask) => {
    setSelectedStudentTask(studentTask)
    setShowEditTaskRewardModal(true)
  }
  const closeEditTaskRewardModalHandler = () => {
    setSelectedStudentTask({})
    setShowEditTaskRewardModal(false)
  }

  const sendTaskGroup = (taskGroup) => {
    api.sendTaskGroup(meetingId, taskGroup.id).handle({
      successMessage: "Tasks sent!",
      errorMessage: "Error sending tasks",
    })
  }

  const sendTaskApproval = (
    eventId,
    studentId,
    taskId,
    status = STUDENT_TASK_STATUSES.APPROVED,
    reward = 0,
    justification,
  ) => {
    const data = {
      meeting_event: eventId,
      student: studentId,
      task: taskId,
      status: status,
      reward: reward,
      custom_reward_justification: justification,
    }
    //
    setSendTaskApprovalLoading(true)
    api.sendTaskApproval(meetingId, data).handle({
      successMessage: `Student task ${status} successfully!`,
      errorMessage: "Error updating student task",
      onFinally: () => {
        setSendTaskApprovalLoading(false)
        closeEditTaskRewardModalHandler()
      },
    })
  }

  return (
    <Grid container mt={3}>
      <Grid
        item
        container
        xs={12}
        md={4}
        lg={3}
        direction="column"
        wrap={"nowrap"}
        sx={{ height: "100vh", }}
      >
        <Grid item flexBasis={"0 !important"} xs={12}>
          <Typography color="primary" fontWeight="bold">
            Submitted Tasks
          </Typography>
        </Grid>
        <Grid item xs={12} py={2} pr={2} sx={{ overflowY: "auto" }}>
          {studentTasks.map((record, index) => (
            <MDBox key={index}>
              <TaskCompletionCard
                student={record.student}
                image={record.task_submit_image}
                task={record.task}
                completedAt={record.completed_at}
                loading={sendTaskApprovalLoading}
                onApprove={() =>
                  sendTaskApproval(
                    record.id,
                    record.student.id,
                    record.task.id,
                    STUDENT_TASK_STATUSES.APPROVED,
                    record.task.reward,
                  )
                }
                onReject={() =>
                  sendTaskApproval(
                    record.id,
                    record.student.id,
                    record.task.id,
                    STUDENT_TASK_STATUSES.REJECTED,
                    record.task.reward,
                  )
                }
                onEdit={() => {
                  openEditTaskRewardModalHandler(record)
                }}
                onViewImage={record.task_submit_image ? () => {
                  setShowTaskImageModal(true)
                  setCurrentTaskImage(record.task_submit_image)
                } : null}
              />
            </MDBox>
          ))}
        </Grid>
      </Grid>
      {/* ============= */}
      <Grid container item xs={12} md={8} lg={9} direction={"column"} wrap={"nowrap"}>
        <Grid
          item
          flexBasis={"0 !important"}
          xs={12}
          sx={{ borderLeft: { xs: "none", md: "1px solid rgba(0, 0, 0, 0.25)" } }}
        >
          <Typography color="primary" fontWeight="bold" ml={2}>
            Tasks Groups
          </Typography>
        </Grid>
        {/* TASKS GRID CONTAINER */}
        <Grid
          item
          container
          direction={"row"}
          xs={12}
          py={2}
          sx={{ borderLeft: { xs: "none", md: "1px solid rgba(0, 0, 0, 0.25)" } }}
          alignContent={"flex-start"}
        >
          {/* TASKS */}
          {taskGroups?.map((tg, index) => (
            <Grid key={tg.id} item xs={12} sm={6} lg={4} px={2} py={2} minHeight={"200px"}>
              <Card sx={{ p: 3, height: "100%", justifyContent: "space-between" }}>
                <MDTypography fontSize={{ xs: "16px", md: "20px" }} color={"#000000DE"}>
                  <MathJax>{`${index + 1}. ${tg.name}`}</MathJax>
                </MDTypography>
                <Box
                  display="flex"
                  flexDirection="row"
                  flexWrap="nowrap"
                  alignItems="center"
                  justifyContent={"space-between"}
                  mt={1}
                  pt={1}
                  gap={1}
                  borderTop="1px solid lightgray"
                >
                  <Box display="flex" alignItems="center">
                    <Button
                      color="secondary"
                      variant="outlined"
                      size="medium"
                      onClick={() => openTaskDetailModalHandler(tg)}
                    >
                      <FormatListBulletedIcon color={"primary"} fontSize="large" />
                    </Button>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Button
                      color="primary"
                      variant="contained"
                      size="medium"
                      onClick={() => sendTaskGroup(tg)}
                    >
                      <img src={paperPlane} alt={"sent"} />
                    </Button>
                  </Box>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
      {/*  */}
      <TaskGroupDetailModal
        showModal={showTaskDetailModal}
        handleClose={closeTaskDetailModalHandler}
        taskGroup={selectedTask}
      />
      <EditTaskRewardModal
        showModal={showEditTaskRewardModal}
        handleClose={closeEditTaskRewardModalHandler}
        task={selectedStudentTask}
        loading={sendTaskApprovalLoading}
        onSubmit={sendTaskApproval}
      />
      <ModalItem
        scrollable={false}
        open={showTaskImageModal}
        closeOnClickOutside={false}
        height="auto"
        title="Task Image"
        handleClose={() => {
          setShowTaskImageModal(false)
          setCurrentTaskImage(null)
        }}
        contentStyle={{justifyContent: "center", width: "100%"}}
      >
        <MDBox width={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
          {!!currentTaskImage && <a href={currentTaskImage} target={"_blank"}>
            <MDBox component={"img"} src={currentTaskImage} maxWidth={"60vw"} maxHeight={"50vh"}/>
          </a>}
        </MDBox>
      </ModalItem>
    </Grid>
  )
}
