import { useState, useRef } from 'react';
import { showMessage } from "../services/helpers"

const useTaskImageUpload = () => {
  const [taskImages, setTaskImages] = useState({});
  const inputRef = useRef({});

  const handleImageChange = (taskId, e) => {
    const file = e.target.files[0];
    const maxSizeInBytes = 2 * 1024 * 1024; // 2MB
    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];

    if (file) {
      if (file.size > maxSizeInBytes) {
        showMessage("File size exceeds the 2MB limit. Please choose a smaller file.", "warning")
        e.target.value = '';
      } else if (!validImageTypes.includes(file.type)) {
        showMessage("Invalid file format. Please upload an image (JPG, PNG, GIF).", "warning");
        e.target.value = '';
      } else {
        setTaskImages((prevState) => ({ ...prevState, [taskId]: file }));
      }
    }
  };

  return { taskImages, inputRef, handleImageChange };
};

export default useTaskImageUpload;
