import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    IconButton
} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";

export const CollapsableCard = ({TitleComponent, onCollapse, children, ...props}) => {
    const [isCollapsed, setIsCollapsed] = React.useState(true)
    const onClickCollapse = () => {
        setIsCollapsed(!isCollapsed)
        if (!!onCollapse) {
            onCollapse(!isCollapsed)
        }
    }
    
    return (
    <Box borderRadius={2} boxShadow={2} p={2} mb={3} {...props}>
        <Box display={"flex"} width={"100%"} justifyContent={"space-between"}>
            {!!TitleComponent && <Box width={"100%"}>{TitleComponent}</Box>}
            <IconButton onClick={onClickCollapse}>
                {!isCollapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
        </Box>
        {!isCollapsed && children}
    </Box>
    )
}