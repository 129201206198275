import PageLayout from "components/PageLayout";
import React from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CalendarPage from "pages/calendar";


const Calendar = () => {
  return (
    <PageLayout
      teacherComponent={
        <>
          <MDBox display="flex" py={1} borderBottom="1px solid rgba(0, 0, 0, 0.25)">
            <MDTypography flex={1} variant="h3" color="dark" fontWeight="regular">
              Class Meetings
            </MDTypography>
          </MDBox>
          <CalendarPage />
        </>
      }
      studentComponent={"Calendar"}
    />
  );
};

export default Calendar;
