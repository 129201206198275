import React, {useMemo} from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Line, Doughnut } from "react-chartjs-2";
import {Box, Typography} from "@mui/material";
import moment from "moment/moment";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export function StudentsEngagement({width, height, stattsData}) {

  const reversed = stattsData?.slice().reverse()

  const options = {
    responsive: true,
    maintainAspectRatio: false, // This allows you to control the chart's aspect ratio
    scales: {
      x: { ticks: { color: "white" } },
      y: {
        beginAtZero: true,
        ticks: {
          color: "white",
          stepSize: 10, // Set the interval between y-axis ticks
        },
      },
    },
    interaction: {
      intersect: false,
      axis : 'x',
      mode : 'nearest',
    },
    // color: "#E14942",
    borderColor: "white",
    pointRadius: 0,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Student Engagement",
        color: "white",
      },
    },
  };

  const labels = reversed?.length > 0? reversed.map(data => moment(data.created).format("HH:mm")) : [""];
  // const correct = stattsData?.length > 0? stattsData.map(data => data.total === 0? 0 : data.engagement_correct/data.total * 100) : [0];
  // const answered = stattsData?.length > 0? stattsData.map(data => data.students === 0? 0 : data.total/data.students * 100) : [0];
  const correct = reversed?.length > 0? reversed.map(data => data.total === 0? 0 : data.engagement_correct) : [0];
  const answered = reversed?.length > 0? reversed.map(data => data.students === 0? 0 : data.total) : [0];

  const data = {
    labels,
    datasets: [
      {
        label: "Correct",
        data: correct,
        borderColor: "rgb(106, 153, 78)",
        pointRadius: 5,
        pointHoverRadius: 10
      },
      {
        label: "Answered",
        data: answered,
        borderColor: "#1010A0",
        pointRadius: 5,
        pointHoverRadius: 10
      },
    ],
  };
  return (
    <Box bgcolor="#2F3E46" borderRadius={2} p={2} width={width || '280px'} height={ height || "250px"}>
      <Line options={options} data={data} />
    </Box>
  );
}

export function StudentUnderstanding({width, height, stattsData}) {
  const reversed = stattsData?.slice().reverse()


  const options = {
    responsive: true,
    maintainAspectRatio: false, // This allows you to control the chart's aspect ratio
    scales: {
      x: { ticks: { color: "white" } },
      y: {
        beginAtZero: true,
        ticks: {
          color: "white",
          stepSize: 5, // Set the interval between y-axis ticks
        },
      },
    },
    interaction: {
      intersect: false,
      axis : 'x',
      mode : 'nearest',
    },
    // color: "#E14942",
    borderColor: "white",
    pointRadius: 0,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Student Understanding",
        color: "white",
      },
    },
  };

  const labels = reversed?.length > 0? reversed.map(data => moment(data.created).format("HH:mm")) : [""];
  // const correct = reversed?.length > 0? reversed.map(data => data.total === 0? 0 : data.understanding_correct/data.total *100) : [0];
  // const answered = reversed?.length > 0? reversed.map(data => data.students === 0? 0 : data.total/data.students * 100) : [0];
  const correct = reversed?.length > 0? reversed.map(data => data.total === 0? 0 : data.understanding_correct) : [0];
  const answered = reversed?.length > 0? reversed.map(data => data.students === 0? 0 : data.total) : [0];

  const data = {
    labels,
    datasets: [
      {
        label: "Correct",
        data: correct,
        borderColor: "rgb(106, 153, 78)",
        pointRadius: 5,
        pointHoverRadius: 10
      },
      {
        label: "Answered",
        data: answered,
        borderColor: "#1010A0",
        pointRadius: 5,
        pointHoverRadius: 10
      },
    ],
  };
  return (
    <Box bgcolor="#2F3E46" borderRadius={2} mt={3} width={width || '280px'} height={ height || "250px"}>
      <Line options={options} data={data} />
    </Box>
  );
}

export const QuestionStats = ({title, data, width, heigth}) => {
  // Sample data for the chart
  const labels = [""]

  const charData = useMemo(() => {
    const newdata = {
      labels: labels,
      datasets: data.answers.map((answer, index) => {
        return {
          label: answer.text,
          backgroundColor: answer.correct ?  "green" : "red",
          data: [answer.count],
        }
      })
    }
    // console.log(newdata)
    return newdata
  }, [data])

  const charData2 = useMemo(() => {
    const newdata = {
      labels: [''],
      datasets: [{
        label: 'Correct Answers',
        backgroundColor: 'green',
        data: [data.understanding_correct]
      },
        {
          label: 'Wrong Answers',
          backgroundColor: 'red',
          data: [data.total - data.understanding_correct]
        },
        {
          label: 'Did not reply / Pending',
          backgroundColor: '#FFC130',
          data: [data.students - data.total]
        },
      ]
    }
    return newdata
  }, [data])

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "y",
    barThickness: 24,
    plugins: {
      legend: {
        display: false,
        position: 'right',
      },
      // title: {
      //   display: true,
      //   text: title,
      //   color: "white",
      //   align: 'start',
      // },
    },
    interaction: {
      intersect: false,
      axis : 'y',
      mode : 'nearest',
    },
    scales: {
      x: {
       stacked: true,
        ticks: { display: false }
      },
      y: {
        stacked: true,
        beginAtZero: true,
        min: 0,
        max: 100,
        ticks: {
          color: "white",
          stepSize: 10,
        },
      },
    },
  };

  return (
    <Box bgcolor="#2F3E46" borderRadius={2} p={1} m={1}>
      <Typography color={'primary'} fontWeight="bold">
        {title}
      </Typography>
      <Typography color={'white'} fontWeight="bold" variant={'h6'}>
        Responses
      </Typography>
      <Box height={heigth} width={width}>
      <Bar data={charData} options={chartOptions} />
      </Box>
      <Typography color={'white'} fontWeight="bold" variant={'h6'}>
        Overall
      </Typography>
      <Box height={heigth} width={width}>
      <Bar  data={charData2} options={chartOptions} />
      </Box>
    </Box>
  );
};

export const UnderstandingChart = ({ understandingPercentage }) => {
  const chartData = {
    labels: ["Understanding", "Remaining"],
    datasets: [
      {
        data: [understandingPercentage, 100 - understandingPercentage],
        backgroundColor: ["#FFE661", "#B6B6B6"], // You can customize the colors
        borderColor: "transparent",
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      title: {
        display: true,
        text: "Understanding",
        color: "white",
      },
    },
  };

  return (
    <Box bgcolor="#2F3E46" borderRadius={2} p={1}>
      <Doughnut data={chartData} options={chartOptions} />
    </Box>
  );
};
export const StudentEngagement = () => {
  // Sample data for the chart
  const chartData = {
    labels: ["8:00 am", "8:30 am", "9:00 am", "9:30 am", "10:00 am"],
    datasets: [
      {
        label: "Wrong answer",
        backgroundColor: "yellow",
        data: [49, 60, 0, 0, 0],
      },
      {
        label: "Correct anwsers",
        backgroundColor: "green",
        data: [0, 0, 100, 0, 0],
      },
      {
        label: "Mid understanding",
        backgroundColor: "red",
        data: [0, 0, 100, 10, 30],
      },
    ],
  };

  const chartOptions = {
    barThickness: 24,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Student Understanding",
        color: "white",
      },
    },
    scales: {
      x: { stacked: true, ticks: { color: "white" } },
      y: {
        stacked: true,
        beginAtZero: true,
        min: 0,
        max: 100,
        ticks: {
          color: "white",
          stepSize: 10,
        },
      },
    },
  };

  return (
    <Box bgcolor="#2F3E46" borderRadius={2} p={1} mt={3}>
      <Bar data={chartData} options={chartOptions} />
    </Box>
  );
};
