import DragHandleIcon from '@mui/icons-material/DragHandle'
import { List, ListItem, ListItemIcon, Skeleton } from '@mui/material'
import { arrayMoveImmutable as arrayMove } from 'array-move'
import React from 'react'
import { Container, Draggable } from 'react-smooth-dnd'

const SortableList = ({
  items = [],
  setItems = () => {},
  renderItemComponent,
  containerStyle,
  itemContainerStyle,
  emptyMessage = 'No items founds',
  storingIndexField = 'custom_order',
  setItemsChanged = () => {},
  loading = false,
  dragIconPosition = 'right',
  errors = [],
  setFieldValue,
}) => {
  const onDrop = ({ removedIndex, addedIndex }) => {
    const newItems = arrayMove(items, removedIndex, addedIndex)
    const updatedItems = newItems.map((item, index) => {
      return {
        ...item,
        [storingIndexField]: index,
      }
    })
    setItems(updatedItems)
    setItemsChanged(true)
  }

  return (
    <List style={containerStyle}>
      {!loading && (
        <Container
          dragHandleSelector=".drag-handle"
          lockAxis="y"
          onDrop={onDrop}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
          }}
        >
          {items.length > 0 &&
            items.map((item, index) => (
              <Draggable key={`dlist-item-${item.id || index}`} style={itemContainerStyle}>
                <ListItem
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  {dragIconPosition === 'left' && (
                    <ListItemIcon className="drag-handle">
                      <DragHandleIcon />
                    </ListItemIcon>
                  )}
                  {!!setFieldValue ? renderItemComponent(item, index, errors, setFieldValue) : renderItemComponent(item, index, errors)}
                  {dragIconPosition === 'right' && (
                    <ListItemIcon className="drag-handle">
                      <DragHandleIcon />
                    </ListItemIcon>
                  )}
                </ListItem>
              </Draggable>
            ))}
          {items.length < 1 && <ListItem>{emptyMessage}</ListItem>}
        </Container>
      )}
      {/*  */}
      {loading && (
        <Container style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          {[1, 2, 3, 4, 5].map((_, index) => (
            <ListItem key={`loading-${index}`} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Skeleton
                variant="rectangular"
                width="100%"
                height={40}
                style={{ borderRadius: 8 }}
              />
              <ListItemIcon className="drag-handle">
                <Skeleton variant="circular" width={24} height={24} />
              </ListItemIcon>
            </ListItem>
          ))}
        </Container>
      )}
    </List>
  )
}

export default SortableList
