import React, { useCallback } from "react";
import { Autocomplete, FormControl } from "@mui/material";
import MDInput from "../MDInput";
import debounce from "lodash.debounce";
import { useField } from "formik";
import MDBox from "../MDBox";

const AutocompleteFK = React.memo(
  ({
    width,
    onChange,
    value,
    fieldName,
    options,
    label,
    onInputChange,
    accessKey = "name",
    styleContainer,
    overrideError,
    getOptionLabel,
    filterOptions, // (options, state) => options // disable local filtering
  }) => {
    const [, meta] = useField({ name: fieldName });
    const errorText = meta.error && meta.touched ? meta.error : "";

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedInputChange = useCallback(
      debounce(function () {
        onInputChange?.(arguments?.[0]);
      }, 300),
      []
    );

    return (
      <FormControl sx={{ width: width || "100%" }}>
        <MDBox {...styleContainer}>
          {/* eslint-disable-next-line jsx-a11y/no-access-key */}
          <Autocomplete
            accessKey={accessKey}
            size={"small"}
            value={value}
            getOptionLabel={getOptionLabel || ((option) => option[accessKey])}
            filterOptions={filterOptions} // disable local filtering
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, value) => onChange(value, fieldName)}
            onInputChange={(event, value, reason) => {
              if (event && (event.type !== "click" || event.type !== "change")) {
                debouncedInputChange(value);
              }
            }}
            options={options}
            sx={{ width: "100%" }}
            renderInput={(params) => {
              let aux = { ...params };
              const { InputProps } = params;
              aux["InputProps"] = { ...InputProps, sx: { height: "44px" } };
              return (
                <MDInput
                  name={fieldName}
                  variant="outlined"
                  {...aux}
                  helperText={overrideError && !!errorText ? overrideError : errorText}
                  error={!!errorText}
                  label={label}
                  type={"text"}
                />
              );
            }}
          />
        </MDBox>
      </FormControl>
    );
  }
);

// AutocompleteFK.propTypes = {
//   type: PropTypes.oneOf(["simple", "formik"]),
// };

export default AutocompleteFK;
