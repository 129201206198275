import MDBox from "../../components/MDBox";
import {Box, Grid, Icon, Typography} from "@mui/material";
import {Paid} from "@mui/icons-material";
import React, {useState} from "react";
import LockIcon from "@mui/icons-material/Lock";
import reward from "assets/images/background/reward.gif";
import unbox from "assets/images/background/unbox.png";
import {BUTTON_OPTIONS} from "../meeting/button_options";
import Avatar from "@mui/material/Avatar";
import thermometerYellow from "../../assets/icons/thermometer-yellow.svg";
import signalHigh from "../../assets/icons/signal-high.svg";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import {MathJax} from "better-react-mathjax";
import {BARS, THERMOMETER} from "../../services/constants";
import {ThermometerIcon} from "../../components/ThermometerIcon";
import {BarsIcon} from "../../components/BarsIcon";

export const ACTIVE_BUTTON_COLOR = "#FFC130";
export const INACTIVE_BUTTON_COLOR = "#fff";

export const TopBar = ({meeting, balance, flags}) => {
    return (<Box px={1} bgcolor="white.main" m={1} borderRadius={2}>
        <Box display="flex" alignItems="end">
            <Typography flex={1} color="warning.main" fontWeight="bold">
                {meeting.title || ''}
            </Typography>
            <Box display="flex" alignItems="center">
                <Typography variant="body2">Current Balance {balance}</Typography>
                <Paid color="primary" />
            </Box>
        </Box>
        <Box display="flex">
            <Typography flex={1} variant="body2">
                {meeting?.course || ''}
            </Typography>
            <Icon width={'20px'} height={'20px'}>
                <ThermometerIcon level={flags[THERMOMETER]?.value || 0} fill={"#6A994E"}/>
            </Icon>
                <BarsIcon bars={flags[BARS]?.bars !== undefined ? flags[BARS]?.bars : 5} color={flags[BARS]?.color || 'grey'}/>
        </Box>
    </Box>)
}
export const CorrectAnswerScreen = ({rewardAmount, bonusAmount}) =>{
    const bonustext = !!bonusAmount ? `, +${bonusAmount} ⌛` : '';
    return (
        <MDBox
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            height={"100%"}
            sx={{ position: "relative" }}
        >
            <MDBox
                component={"img"}
                src={reward}
                alt={"unbox"}
                width={"100%"}
                sx={{ height: "100%", position: "absolute" }}
            />
            <MDBox
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={2}
            >
                <Typography
                    textAlign="center"
                    fontWeight={"600"}
                    variant="h5"
                    color={"#FFC130"}
                    width={"200px"}
                >
                    Correct answer you have won
                </Typography>
                <MDBox
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <MDBox
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        gap={1}
                        bgColor={"#6A994E"}
                        p={0.5}
                        borderRadius={"md"}
                    >
                        <Typography fontWeight={"600"} variant="h3" color={"#FFF"}>
                            +{rewardAmount}{bonustext}
                        </Typography>
                        <Paid color="white" sx={{ width: 40, height: 40 }} />
                    </MDBox>
                </MDBox>
            </MDBox>
        </MDBox>
    )
}



export const WrongAnswerScreen = ({horizontal, reward, bonusAmount}) => {
    const extraStyle = horizontal ? {flexDirection:"column"} : {};
    const bonustext = !!bonusAmount ? `, +${bonusAmount} ⌛` : '';

    return (
        <MDBox
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            height={"100%"}
            {...extraStyle}
        >
            {!reward &&<Typography textAlign="center" fontWeight={"600"} variant="h5" color={"#FFC130"}>
                Thanks for your reply
            </Typography>}
            {!!reward && <>
                <Typography textAlign="center" fontWeight={"600"} variant="h5" color={"#FFC130"}>
                Thanks for your reply,<br />you have won
                </Typography>
                <MDBox
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <MDBox
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        gap={1}
                        bgColor={"#6A994E"}
                        p={0.5}
                        borderRadius={"md"}
                    >
                        <Typography fontWeight={"600"} variant="h3" color={"#FFF"}>
                            +{reward}{bonustext}
                        </Typography>
                        <Paid color="white" sx={{ width: 40, height: 40 }} />
                    </MDBox>
                </MDBox>
            </>}
        </MDBox>
    )
}


export const LockScreen = () => {
    return (
        <MDBox
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            height={"100%"}
        >
           <MDBox>
               <Typography color={"secondary"} fontWeight={"600"} fontSize={14} textAlign={"center"} mb={2}>
                   Engage with the lesson and earn class bucks
               </Typography>
           </MDBox>
            <LockIcon sx={{ width: 150, height: 150}}  />
        </MDBox>
    )
}


export const ForcedDeduction = ({reason, amount}) => {
    return (
        <MDBox display={"flex"} flexDirection={"column"} alignItems={"center"} gap={1}>
            <Typography
                textAlign="center"
                fontWeight={"600"}
                variant="h5"
                color={"#FFC130"}
                width={"220px"}
            >
                {reason}
            </Typography>
            <MDBox
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ position: "relative" }}
            >
                <MDBox
                    component={"img"}
                    src={unbox}
                    alt={"unbox"}
                    width={"133px"}
                    height={"144px"}
                />
                <MDBox
                    width={"90%"}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    gap={1}
                    bgColor={"#FF6058"}
                    p={0.5}
                    borderRadius={"md"}
                    sx={{ position: "absolute", top: 40 }}
                >
                    <Typography fontWeight={"600"} variant="h3" color={"#FFF"}>
                        -{amount}
                    </Typography>
                    <Paid color="white" sx={{ width: 40, height: 40 }} />
                </MDBox>
            </MDBox>
        </MDBox>
    )
}

export const ExtraReward = ({ reason, amount }) => {
    return (
        <MDBox
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={1}
            height={"100%"}
        >
            <Typography
                textAlign="center"
                fontWeight={"600"}
                variant="h6"
                color={"#FFC130"}
                width={"200px"}
            >
                {reason}
            </Typography>
            <MDBox
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ position: "relative" }}
            >
                <MDBox component={"img"} src={unbox} alt={"unbox"} width={"133px"} height={"144px"} />
                <MDBox
                    width={"90%"}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    gap={1}
                    bgColor={"#6A994E"}
                    p={0.5}
                    borderRadius={"md"}
                    sx={{ position: "absolute", top: 40 }}
                >
                    <Typography fontWeight={"600"} variant="h3" color={"#FFF"}>
                        +{amount}
                    </Typography>
                    <Paid color="white" sx={{ width: 40, height: 40 }} />
                </MDBox>
            </MDBox>
        </MDBox>
    )
}
//
// {answer === 3 && (
//     <MDBox
//         display={"flex"}
//         flexDirection={"column"}
//         justifyContent={"center"}
//         alignItems={"center"}
//         gap={1}
//         height={"100%"}
//     >
//         <Typography
//             textAlign="center"
//             fontWeight={"600"}
//             variant="h5"
//             color={"#FFC130"}
//             width={"220px"}
//         >
//             You have been caught talking in class
//         </Typography>
//         <MDBox
//             display={"flex"}
//             flexDirection={"column"}
//             alignItems={"center"}
//             justifyContent={"center"}
//             sx={{ position: "relative" }}
//         >
//             <MDBox component={"img"} src={unbox} alt={"unbox"} width={"133px"} height={"144px"} />
//             <MDBox
//                 width={"90%"}
//                 display={"flex"}
//                 justifyContent={"space-between"}
//                 alignItems={"center"}
//                 gap={1}
//                 bgColor={"#FF6058"}
//                 p={0.5}
//                 borderRadius={"md"}
//                 sx={{ position: "absolute", top: 40 }}
//             >
//                 <Typography fontWeight={"600"} variant="h3" color={"#FFF"}>
//                     -10
//                 </Typography>
//                 <Paid color="white" sx={{ width: 40, height: 40 }} />
//             </MDBox>
//         </MDBox>
//     </MDBox>
// )}


export const ChatBubbleSection = () => {
    return null

    // return (<Box px={1} bgcolor="white.main" m={1} borderRadius={2}>
    //     <Typography color="warning.main" fontWeight="bold">
    //         Message from Teacher
    //     </Typography>
    //     <Typography variant="body2">Hi</Typography>
    // </Box>)
}


export const EngagementQueryScreen = ({horizontal, button}) => {
    const buttonObj = BUTTON_OPTIONS.find((b) => b.id === button);
    // const extraStyle = horizontal ? {flexDirection:"column"} : {};
    return (
        <MDBox
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            height={"100%"}
            flexDirection={"column"}

        >
            <Typography textAlign="center" fontWeight={"600"} variant="h5" color={"#FFC130"}>
                Please press the button
            </Typography>
            <Avatar sx={{
                bgcolor: buttonObj.color,
                width: "50px",
                height: "50px",
                strokeWidth: "1px",
                stroke: "rgba(0, 0, 0, 0.10)",
                boxShadow: "-2px -1px 2px 0px rgba(0, 0, 0, 0.25) inset",
                filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                mt: 2
            }}>
                {/*<Typography color={"black"} variant="h6" sx={{ textTransform: 'uppercase' }}>*/}
                {/*    {button.button}*/}
                {/*</Typography>*/}

                {buttonObj.button}
            </Avatar>
            <Typography textAlign="center" fontWeight={"600"} variant="h5" color={"#FFC130"} mt={2}>
                To continue
            </Typography>
        </MDBox>
    )
}


export const OpenQuestionQueryScreen = ({horizontal, prompt, userInteraction, reward}) => {
    const [value,setvalue] = useState('')

    return (
        <MDBox
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            height={"100%"}
            flexDirection={"column"}

        >
            <Typography textAlign="center" fontWeight={"600"}  mb={2} color={"#FFC130"}>
                <MathJax>{prompt}</MathJax>
            </Typography>
            <MDInput
                type={'textarea'}
                multiline={true}
                placeholder={'Your answer here'}
                rows={3}
                fullWidth={false}
                sx={{borderRadius: '5px', backgroundColor: '#fff', color: '#000', width: '90%'}}
                value={value}
                onChange={(e) => setvalue(e.target.value)}
            />
            <MDButton
                color="primary"
                onClick={() => userInteraction({text: value})}
                sx={{borderRadius: '5px', width: '90%'}}
            >
                Send response
            </MDButton>

            {!!reward &&<Grid item container mt={2}>
                <Grid
                    container
                    item
                    xs={12}
                    mx={2}
                    p={1}
                    bgcolor={"#3A532B"}
                    sx={{ borderRadius: 2, height: "40px" }}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Typography fontWeight={"600"} variant="body2" color={"#fff"}>
                        Reward
                    </Typography>
                    <MDBox display={"flex"} justifyContent={"center"} alignItems={"center"} gap={1}>
                        <Typography fontWeight={"600"} variant="body2" color={"#fff"}>
                            {reward}
                        </Typography>
                        <Paid color="white" />
                    </MDBox>
                </Grid>
            </Grid>}
        </MDBox>
    )
}

