import {useEffect, useRef, useState} from "react";
import {rtcConfig} from "../constants";
import {add_other_events_listeners, addiceeveents} from "./provider_streaming_helpers";


export const useServerStreamingOps = (imServer, myid, channel) => {
    const connectedChilds = useRef({})
    const sharedStreamRef = useRef(null)
    const [sharedStream, setSharedStream] = useState(null)
    const [isStreaming, setIsStreaming] = useState(false)
    const [streamingId, setStreamingId] = useState(null)

    // const getSharedStream =  () => {
    //     return sharedStream
    // }

    const addServerPeerConectionlisteners = (peerConnection) => {
        peerConnection.addEventListener('track', (event) => {
            console.log('got stream track:', event.streams[0], ' (server)')
            // setSharedStream(event.streams[0])
            // const videoElement = document.createElement('video');
            // videoElement.srcObject = event.streams[0];
            // document.body.appendChild(videoElement);
            // videoElement.play();
        })
        peerConnection.addEventListener('connectionstatechange', (event) => {
            console.log('connection state change', peerConnection.connectionState, event, ' (server)')
        })
        peerConnection.addEventListener('negotiationneeded', (event) => {
            console.log('negotiation needed', event, ' (server)')
            // const offer = await peerConnection.createOffer();
            // await peerConnection.setLocalDescription(offer);
            // channel.postMessage({type: 'offer', data: offer.toJSON(), id: myid})
        })
    }

    // const addiceeveents = (peerConnection, myid, other_id) => {
    //     peerConnection.addEventListener('icecandidate', (event) => {
    //         console.log('ice candidate', event, ' (server)')
    //         if (event.candidate) {
    //             channel.postMessage({type: 'ice', data: event.candidate.toJSON(), id: myid, original_id: other_id})
    //         }
    //     })
    // }

    const serverChannelListener = async (message) => {
        if (message?.type === 'seekServerStream') {
            const peerConnection = new RTCPeerConnection(rtcConfig)
            const stream2 = sharedStreamRef.current
            stream2.getTracks().forEach((track) => peerConnection.addTrack(track, stream2));

            const offer = await peerConnection.createOffer();
            await peerConnection.setLocalDescription(offer);
            addServerPeerConectionlisteners(peerConnection)
            addiceeveents(peerConnection, myid, message.id, channel, true)
            add_other_events_listeners(peerConnection, true)

            // console.log("offer", offer, offer.toJSON?.(), peerConnection.localDescription, ' (server)')
            const data_json = {sdp: peerConnection.localDescription.sdp, type: offer.type}
            channel.postMessage({type: 'offer', data: data_json, id: myid, original_id: message.id})
            connectedChilds.current[message.id] = peerConnection
            // console.log('connected childs set to', connectedChilds.current, ' (server)')

        }

        if (message?.type === 'answer'  && message?.original_id === myid) {
            const answer = new RTCSessionDescription(message.data)
            // console.log('got streaming answer', answer, ' (server)')
            // console.log('connected childs', connectedChilds.current, ' (server)')
            // console.log('answer messaage', message, ' (server)')
            const peerConnection = connectedChilds.current?.[message.id]
            await peerConnection.setRemoteDescription(answer);

            //     const stream = getSharedStream()
            // console.log('shared stream', stream)
            // console.log('shared stream 2', sharedStreamRef.current)
            // const stream2 = sharedStreamRef.current
            // stream2.getTracks().forEach((track) => peerConnection.addTrack(track, stream2));


        }

        if (message?.type === 'ice'  && message?.original_id === myid) {
            console.log('got ice candidate from the other side', message, ' (server)')
            const peerConnection = connectedChilds.current?.[message.id]
            if (!peerConnection || !message.data){
                console.log('no peer connection or no data', peerConnection, message.data, ' (server)')
                return
            }
            await peerConnection.addIceCandidate(new RTCIceCandidate(message.data))
        }
    }

    useEffect(() => {
        if (imServer) {
            console.log('my id is: ', myid, ' (server)')
            const listener = async (e) => {
                await serverChannelListener(e.data)
            }
            channel.addEventListener('message', listener)

            return () => { channel.removeEventListener('message', listener) }
        }

    }, [channel, myid, imServer])


    useEffect(() => {
        if (!sharedStream) return;

        sharedStream.addEventListener('inactive', (e) => {
            console.log("stream inactive (server)", e)
            setIsStreaming(false)
            setStreamingId(null)
            setSharedStream(null)
        })
    }, [sharedStream]);

    const startCapture = async (meetingId) => {
        try {
            const stream = await navigator.mediaDevices.getDisplayMedia();
            //setSharedStream(stream)
            sharedStreamRef.current = stream
            setSharedStream(stream)

            setIsStreaming(true)
            setStreamingId(meetingId)
            stream.addEventListener('removetrack', (e) => {
                console.log("onremovetrack", e, ' (server)')
                setIsStreaming(false)
                setStreamingId(null)
            })
            stream.addEventListener('addtrack', (e) => {
                setIsStreaming(true)
                setStreamingId(meetingId)
                console.log("onaddtrack", e, ' (server)')
            })


            console.log("srcObject", stream, ' (server)')

            // stream.getTracks().forEach((track) => peerConnection.addTrack(track, stream));

            // dumpOptionsInfo();
        } catch (err) {
            console.error(err);
        }
    }

    const stopCapture = () => {
        let tracks = sharedStreamRef.current.getTracks();

        tracks.forEach(track => track.stop());
        sharedStreamRef.current = null
        setSharedStream(null)
        setIsStreaming(false)

        channel.postMessage({type: 'serverStreamStopped', id: myid})
    }

    return {startCapture, stopCapture, serverStream: sharedStream, isServerStreaming: isStreaming, streamingId}
}
