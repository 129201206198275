import Draggable from "react-draggable";
import {Resizable} from "re-resizable";
import {IconButton, Typography} from "@mui/material";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import MDBox from "../../components/MDBox";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import React, {useState} from "react";
import {observer} from "mobx-react";
import {useStores} from "../../models";


const MeetingDisplayLeaderboard = observer(({
    leaderboard,
    defaultX = 50,
    defaultY = 400,
}) => {
    const rootStore = useStores();

    const [isBubble, setIsBubble] = useState(true);
    const [mySize, setMySize] = useState({width: 320});
    const [isAnimating, setIsAnimating] = useState(false);
    const [dragDisabled, setDragDisabled] = useState(false);
    const [show5or10, setShow5or10] = useState(true);

    const qrAnimation = 'width 0.1s linear, height 0.1s linear'
    //const qrAnimation = 'width 0.1s ease-in-out, height 0.1s ease-in-out'

    const leaderBoard2 =  [...leaderboard,...leaderboard,...leaderboard,...leaderboard,...leaderboard,...leaderboard,...leaderboard,...leaderboard,]

    const onDragStop = (evt, { x, y }) => {
        rootStore?.setQRPosition(x, y);
    };

    const onResizeStart = () => {
        setDragDisabled(true);
    }

    const onResizeStop = (e, direction, ref, d) => {
        setDragDisabled(false);
        setMySize((prev) => ({
            width: prev.width + d.width,
            height: (prev.height || ref.getBoundingClientRect().height) + d.height,
        }));
    }

    const onResize = (evt, dir, elRef) => {
        const newShow = elRef.getBoundingClientRect().height > 244
        if (show5or10 !== newShow) {
            setShow5or10(newShow)
        }
    }

    // console.log(leaderboard)
    const bubbleProps = {
        true: {
            style: {
                background: "#6A994E",
                borderRadius: "30px",
                // margin: "20px",
                position: "absolute",
                top: 0,
                left: 0,
                transition: qrAnimation
            },
            size: {width: 62, height: 62},
            className: "handle",
            enable: false,
        },
        false: {
            ...(isAnimating ? {} : {
                minHeight: 150,
                minWidth: 170,
            }),
            // minHeight:170,
            // minWidth:170,
            style: {
                background: "#f0f0f0",
                borderRadius: "30px",
                // margin: "200px",
                position: "absolute",
                top: 0,
                left: 0,
                // transition:'width,height 1s ease-in-out'
                ...(dragDisabled ? {} : {transition: qrAnimation})
            },
            size: mySize,
            enable: {
                top: false,
                left: false,
                topRight: false,
                bottomLeft: false,
                topLeft: false,

                right: true,
                bottom: true,
                bottomRight: true,
            }
        }
    }

    return (
        <Draggable
            axis="both"
            bounds="body"
            handle=".handle"
            defaultPosition={{x: defaultX, y: defaultY}}
            // position={null}
            grid={[5, 5]}
            scale={1}
            disabled={dragDisabled}
        >

            <Resizable
                lockAspectRatio={false}
                onResizeStart={onResizeStart}
                onResizeStop={onResizeStop}
                onResize={onResize}
                {...bubbleProps[isBubble]}
            >
                {isBubble ?

                    <IconButton
                        style={{
                            margin: '5px',
                            color: 'white'
                        }}
                        size={"large"}
                        onClick={() => {
                            setIsBubble(false)
                            setIsAnimating(true)
                            setTimeout(() => {
                                setIsAnimating(false)
                            }, 200)
                        }}

                    >
                        <WorkspacePremiumIcon/>
                    </IconButton> :
                    isAnimating ? null : (<>
                        <MDBox
                            bgColor={"#6A994E"}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderTopLeftRadius: "30px",
                                borderTopRightRadius: "30px",
                                height: "60px",
                                width: "100%",
                            }}
                            className="handle"
                        >
                            <Typography color="white.main" fontWeight="bold" textOverflow={"ellipsis"}
                                        overflow={"hidden"}>
                                TOP {show5or10 ? '10' : '5'} EARNERS
                            </Typography>
                            <IconButton
                                style={{
                                    margin: '5px',
                                    color: 'white',
                                    position: "absolute",
                                    right: 0,
                                    top: 0,
                                }}
                                size={"large"}
                                onClick={() => setIsBubble(true)}
                            >
                                <RemoveCircleOutlineIcon/>
                            </IconButton>
                        </MDBox>
                        <MDBox display={"flex"} alignItems={"center"} justifyContent={"start"} height={"100%"}
                               flexDirection={"column"} style={{
                               overflow: "hidden",
                               padding: "10px",
                               height: "calc(100% - 60px)",
                               borderRadius: "30px",
                        }} className="handle">
                            {leaderBoard2.map((position, index) => (
                                index > (show5or10 ? 9: 4) ? null : (
                                    <MDBox
                                        key={'leaderboard' + index}
                                        display={"flex"}
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                        flexDirection={"row"}
                                        width={"100%"}
                                        sx={{paddingLeft: "10px", marginRight: "10px"}}
                                    >
                                        <Typography  color="black" fontWeight="bold" textOverflow={"ellipsis"}
                                                     overflow={"hidden"}>
                                            {index+1}. {position.name}
                                        </Typography>
                                        <Typography  color={position.total >= 0 ? "primary" : "error"} fontWeight="bold" textOverflow={"ellipsis"}
                                                     overflow={"hidden"}>
                                            {position.total >= 0 && "+"}{position.total}
                                        </Typography>
                                    </MDBox>

                                )
                            ))}
                        </MDBox></>)}
            </Resizable>
        </Draggable>
    )
})

export default MeetingDisplayLeaderboard;
