import ModalItemTimer from "../../components/ModalItemTimer";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Avatar from "@mui/material/Avatar";
import ClearIcon from "@mui/icons-material/Clear";
import React from "react";
import {ArrowDropUp} from "@mui/icons-material";
import {Box, Typography} from "@mui/material";
import {BUTTON_OPTIONS} from "./button_options";


export const EngagementQuestion = ({showModal, handleClose, question }) => {
    const isEngagement = question?.question === null
    const button = BUTTON_OPTIONS.find((b) => b.id === question?.random_button);
    return question ? (
        <ModalItemTimer
            scrollable={false}
            open={showModal}
            closeOnClickOutside={false}
            title={isEngagement ? "Engagement Question" : "Does it make cents?"}
            pillMode={isEngagement}
            timer={question?.q_timeout || question?.engagement_timeout || 10}
            reward={(isEngagement ? null : question?.question?.max_reward)}
            height={70}
            handleClose={handleClose}
            color={isEngagement ? '#394648' : '#654C4F'}
        >
            <MDBox  display={"flex"} flexDirection={"row"} alignItems={"center"} width={"100%"} gap={1} justifyContent={'center'}>
                <MDTypography color={"black"} variant="h6" pt={1} sx={{ textTransform: 'uppercase' }}>
                    {isEngagement ? "Are you engaged? Press" : "Press"}
                </MDTypography>
                <Avatar sx={{
                    bgcolor: button.color,
                    width: "50px",
                    height: "50px",
                    strokeWidth: "1px",
                    stroke: "rgba(0, 0, 0, 0.10)",
                    boxShadow: "-2px -1px 2px 0px rgba(0, 0, 0, 0.25) inset",
                    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                    textShadow:' -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;',
                    color:'white !important'
                }}>
                    {/*<Typography color={"black"} variant="h6" sx={{ textTransform: 'uppercase' }}>*/}
                    {/*    {button.button}*/}
                    {/*</Typography>*/}

                    {button.button}
                </Avatar>

                {/*<MDTypography color={"black"} variant="h6" sx={{ textTransform: 'uppercase', textAlign: 'center' }}>*/}
                {/*    {text}*/}
                {/*</MDTypography>*/}
            </MDBox>
        </ModalItemTimer>
    ) : null;
};
