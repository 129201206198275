import React from "react";
import PageLayout from "components/PageLayout";

import StudentHome from "./StudentHome";
import TeachersHome from "./TeacherHome";
const CoursesList = () => {
  return <PageLayout studentComponent={<StudentHome />} teacherComponent={<TeachersHome />} />;
};

export default CoursesList;
