import {useEffect, useRef, useState} from "react";
import {rtcConfig} from "../constants";
import {add_other_events_listeners, addiceeveents} from "./provider_streaming_helpers";


export const useClientStreamingOps = (imClient, myid, channel) => {
    const [clientPeerConnection, setClientPeerConnection] = useState(null)
    const [sharedStream, setSharedStream] = useState(null)
    const [isStreaming, setIsStreaming] = useState(false)

    const getClientStream =  () => {
        return sharedStream
    }


    // const addiceeveents = (peerConnection, myid, other_id) => {
    //     peerConnection.addEventListener('icecandidate', (event) => {
    //         console.log('ice candidate', event)
    //         if (event.candidate) {
    //             channel.postMessage({type: 'ice', data: event.candidate.toJSON(), id: myid, original_id: other_id})
    //         }
    //     })
    // }

    const clientChannelListener = async (message) => {
        if(message?.type === 'offer' && message?.original_id === myid) {
            //setGotOffer(true)
            // console.log('got streaming offer data', message.data)
            const offer = new RTCSessionDescription(message.data)
            // console.log('got streaming offer', offer)
            addiceeveents(clientPeerConnection, myid, message.id, channel, false)
            await clientPeerConnection.setRemoteDescription(offer);
            // console.log('creating answer, estate', clientPeerConnection.signalingState)
            const answer = await clientPeerConnection.createAnswer();
            await clientPeerConnection.setLocalDescription(answer);
            // console.log('sending answer', {type: 'answer', data: answer.toJSON(), id: myid, original_id: message.id})
            const answer_json = {sdp: answer.sdp, type: answer.type}
            channel.postMessage({type: 'answer', data: answer_json, id: myid, original_id: message.id})
        }

        if (message?.type === 'ice'  && message?.original_id === myid) {
            console.log('got ice candidate from the other side', message)
            if (!clientPeerConnection || !message.data){
                console.log('no peer connection or no data', clientPeerConnection, message.data, ' (client)')
                return
            }
            if (message.data.candidate === '') {
                console.log('empty candidate', message.data, ' (client)')
                return
            }
            await clientPeerConnection.addIceCandidate(new RTCIceCandidate(message.data))

        }
        if (message?.type === 'serverStreamStopped') {
            setIsStreaming(false)
            setSharedStream(null)
        }
    }

    useEffect(() => {
        if (imClient) {
            if (!clientPeerConnection) {
                setClientPeerConnection(new RTCPeerConnection(rtcConfig))
            }

            console.log('my id is: ', myid, ' (client)')
            const listener = async (e) => {
                await clientChannelListener(e.data)
            }
            channel.addEventListener('message', listener)

            return () => { channel.removeEventListener('message', listener) }
        }else{
            if (clientPeerConnection) {
                clientPeerConnection.close()
                setClientPeerConnection(null)
            }
        }

    }, [channel, clientPeerConnection, myid, imClient, clientChannelListener])


    useEffect(() => {
        if (!sharedStream) return;

        sharedStream.addEventListener('inactive', (e) => {
            console.log("stream inactive (client)", e)
        })
    }, [sharedStream]);



    useEffect(() => {
        if (!clientPeerConnection) return;
        console.log('client peer connection', clientPeerConnection, ' (client)')

        clientPeerConnection.addEventListener('track', (event) => {
            console.log('got stream track:', event, ' (client)')
            setSharedStream(event.streams[0])
            setIsStreaming(true) // TODO better placement
        })
        clientPeerConnection.addEventListener('connectionstatechange', (event) => {
            console.log('connection state change', clientPeerConnection.connectionState, event)
        })
        clientPeerConnection.addEventListener('negotiationneeded', (event) => {
            console.log('client negotiation needed', event)
            // const offer = await peerConnection.createOffer();
            // await peerConnection.setLocalDescription(offer);
            // channel.postMessage({type: 'offer', data: offer.toJSON(), id: myid})
        })

        add_other_events_listeners(clientPeerConnection, false)

    }, [clientPeerConnection]);

    const seekServerStream = () => {
        channel.postMessage({type: "seekServerStream", id: myid})
    }


    return {seekServerStream, clientStream: sharedStream, isClientStreaming: isStreaming}
}
