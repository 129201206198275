import React from "react";
import PageLayout from "components/PageLayout";
import TeacherNewCourse from "./TeacherNewCourse";
import { Navigate } from "react-router-dom";
import { ROUTES } from "services/constants";

const NewCourse = () => {
  return (
    <PageLayout
      teacherComponent={<TeacherNewCourse />}
      studentComponent={<Navigate to={ROUTES.NOT_FOUND} />}
    />
  );
};

export default NewCourse;
