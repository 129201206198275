import React, {useEffect, useState} from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import CourseCard from "./CourseCard";
import {Card, Grid} from "@mui/material";
import logo from "../../assets/images/logos/logo.png"
import {useApi} from "../../services/helpers";
import sandClock2 from "../../assets/icons/sandClock2.svg";
import {ENROLMENT_STATUS, MODALITY_OPTIONS} from "../../utils/helpers";
import {EmptyResponse} from "../../components/EmptyResponse";


const StudentCourses = () => {
  const api = useApi();
  const [courses, setCourses] = useState([]);
  const [enrolmentCourses, setEnrolmentCourses] = useState([]);

  const getStudentCourses = () => {
    api.getStudentCourses().handle({
      onSuccess: (res) => {
        setCourses(res.data)
      },
      errorMessage: "Error getting courses",
    });
  }

  const getStudentEnrolmentCourses = () => {
    api.getStudentEnrolmentCourses().handle({
      onSuccess: (res) => {
        setEnrolmentCourses(res.data)
      },
      errorMessage: "Error getting courses",
    });
  }

  const studentAcceptInvite = (enrolmentId) => {
    api.studentAcceptInvite(enrolmentId).handle({
      onSuccess: (res) => {
        getStudentCourses()
        getStudentEnrolmentCourses()
      },
      errorMessage: "Error getting courses",
    });
  }

  const studentRejectInvite = (enrolmentId) => {
    api.studentRejectInvite(enrolmentId).handle({
      onSuccess: (res) => {
        getStudentCourses()
        getStudentEnrolmentCourses()
      },
      errorMessage: "Error getting courses",
    });
  }

  useEffect(() => {
    getStudentCourses()
    getStudentEnrolmentCourses()
  }, []);

  return (
    <>
      <MDBox display="flex" py={1} borderBottom="1px solid rgba(0, 0, 0, 0.25)">
        <MDTypography flex={1} fontSize={{xs:'24px', md:"30px"}} variant="h3" color="dark" fontWeight="regular">
          Courses
        </MDTypography>
        <MDButton variant="contained" iconOnly onClick={() => {}}>
          <TuneRoundedIcon fontSize="50px" sx={{ fontSize: "50px" }} />
        </MDButton>
      </MDBox>
      <Grid container  mt={3} direction={'row'} gap={2}>
        {courses?.length>0?
          courses.map((item) => (
              <Grid key={item} display={'contents'} direction={'row'} item xs={12} sm={5} md={4}>
                <CourseCard course={item} />
              </Grid>
          ))
          :
          <EmptyResponse text={"No courses found"} height={"55vh"}/>
        }
      </Grid>

      <MDBox display="flex" py={1} borderBottom="1px solid rgba(0, 0, 0, 0.25)">
        <MDTypography fontSize={{xs:'24px', md:"30px"}} flex={1} variant="h3" color="dark" fontWeight="regular">
          Course Enrollment
        </MDTypography>
      </MDBox>
      <Grid container mt={3} gap={1} >

      {enrolmentCourses?.length > 0?
        enrolmentCourses.map((item) => (
          <Grid item xs={12} >
              <Card >
                <Grid container key={item.id} >
                  <Grid item container xs={2} sx={{ padding: 0, borderRight: "6px solid #608A47", }} alignItems={'center'}  >
                    <Grid display={'contents'} xs={12} maxHeight={'107px'} >
                    <img
                      src={item.course.image || logo}
                      alt="course_image"
                      style={{ width: "100%", height: "107px",
                      borderRadius: "16px",
                      objectFit:item?.course?.image? 'cover' : 'contain',
                      opacity: item?.course?.image? "none":"50%",
                      padding:item?.course?.image? 0 : 5}}
                    />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      padding: 2,
                    }}
                  >
                    <MDTypography fontSize={{xs:'12px', md:"20px"}} color="primary">Course Name</MDTypography>
                    <MDTypography fontSize={{xs:'12px', md:"20px"}} color="dark">{item.course.name}</MDTypography>
                  </Grid>
                  <Grid
                  alignContent={'center'}
                    item
                    xs={3}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      padding: 2,
                    }}
                  >
                    <MDTypography fontSize={{xs:'12px', md:"20px"}} color="primary">Modality</MDTypography>
                    <MDTypography fontSize={{xs:'12px', md:"20px"}} color="dark">{MODALITY_OPTIONS._LABELS[item.course.modality]}</MDTypography>
                  </Grid>
                  { item.status===ENROLMENT_STATUS.INVITED?
                    <Grid item container xs={4} alignContent={'center'} my={1}
                    sx={{borderLeft: "6px dotted #608A47",}}>
                      <Grid item container xs={12} justifyContent={'center'} >
                      <Grid item >
                      <MDTypography fontSize={{xs:'12px', md:"20px"}} textAlign="center" color="dark">
                        Want to enroll in course?
                      </MDTypography>
                      </Grid>
                      </Grid>
                      <Grid item container xs={12} direction={'row'} justifyContent={'center'} >
                      <Grid item container xs={6} minWidth={'74px'} alignContent={'center'} alignItems={'center'} justifyContent={'center'} >
                        <Grid mb={1}>
                        <MDButton p={0}  size="small" color="primary"  onClick={() => studentAcceptInvite(item.id)}>
                          Yes
                        </MDButton>
                        </Grid>
                        </Grid>
                        <Grid item container minWidth={'74px'} xs={6} alignContent={'center'} alignItems={'center'} justifyContent={'center'}>
                          <Grid >
                            <MDButton size="small" onClick={() => studentRejectInvite(item.id)}>No</MDButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  :
                  <Grid
                    item
                    justifyContent={'center'}
                    alignItems={'center'}
                    xs={4}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      padding: 1,
                      borderLeft: "6px dotted #608A47"
                    }}
                  >
                    <Grid item container xs={12} height={'100%'} alignItems={'center'} alignContent={'center'} justifyContent={'center'} >
                      { item.status===ENROLMENT_STATUS.REQUESTED &&
                      <MDBox display={'flex'} alignItems="center" justifyContent="center" flexDirection={'row'}>
                        <img src={sandClock2} alt="sandclock2" width={"30px"} />
                        <MDTypography fontSize={{xs:'12px', md:"20px"}}  color="dark" fontWeight="bold" ml={1}>
                            Waiting for the teacher to accept the request...
                          </MDTypography>
                      </MDBox>
                      }
                      </Grid>
                    </Grid>
                  }
                </Grid>
              </Card>
              </Grid>
          ))
        :
        <EmptyResponse text={"No courses found"} height={"25vh"}/>
      }

          </Grid>
    </>
  );
};

export default StudentCourses;
