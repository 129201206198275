import React, {useEffect, useState} from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Box, Grid, IconButton } from "@mui/material";
import { AccountCircle, ExpandLess, ExpandMore, Paid } from "@mui/icons-material";
import {useApi} from "../../services/helpers";
import {TRANSACTION_TYPE} from "../../utils/helpers";
import theme from "../../assets/theme";

const StudentsEarningHistory = () => {

  const api = useApi();
  const [userInfo, setUserInfo] = useState({});
  const [courses, setCourses] = useState([]);


  const fetchUserInfo = () => {
    api.getUserInfo().handle({
      onSuccess: (res) => {
        setUserInfo(res.data);
      },
      errorMessage: "Error fetching user info",
    });
  }

  const studentCourses = () => {
    api.getStudentCourses().handle({
      onSuccess: (res) => {
        setCourses(res.data);
      },
      errorMessage: "Error getting courses",
    });
  }

  useEffect(() => {
    fetchUserInfo()
    studentCourses()
  }, []);


  return (
    <>
      <MDBox display="flex" py={1} borderBottom="1px solid rgba(0, 0, 0, 0.25)" gap={3}>
        <MDTypography flex={1} variant="h3" color="dark" fontWeight="regular">
          Transactions History
        </MDTypography>

        <MDBox display="flex" flexDirection="row" alignItems="center">
          <MDTypography mr={1} color="dark">
            {`Bucks: ${userInfo?.student?.balance}`}
          </MDTypography>
          <Paid color="primary" />
        </MDBox>
      </MDBox>
      <Grid container>
        <Grid item xs={3} display="flex" alignItems="center" flexDirection="column">
          {userInfo?.student?.image?
            <MDBox
              component={"img"}
              src={userInfo?.student?.image}
              alt={"Profile Picture"}
              borderRadius={'50%'}
              maxHeight={"125px"}
              width={"125px"}
              mt={1}
              sx={{ fontSize: "125px" }}
            />
            :
            <AccountCircle fontSize="150px" sx={{
              mx: "auto",
              fontSize: "150px",
              [theme.breakpoints.down("sm")]: {
                fontSize: "90px",
              }, }}
            />
          }
          <MDTypography mr={1} color="dark">
            {userInfo?.name}
          </MDTypography>
        </Grid>
        <Grid item xs={9}>
          {courses.length>0 &&
            courses.map((item) => (
            <Grid container key={item.id} px={3} my={1}>
              <Grid item xs={6} display="flex" alignContent="center">
                <MDTypography color="dark" style={{ textDecoration: "underline" }}>
                  {item.name}
                </MDTypography>
              </Grid>
              <Grid item xs={6} display="flex" alignItems="center" justifyContent="flex-end">
                <MDTypography color="dark">{item.balance}</MDTypography>
                <Paid color="primary" sx={{ ml: 1 }} />
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12}>
          <BalanceSheet />
        </Grid>
      </Grid>
    </>
  );
};
const BalanceSheet = () => {
  const api = useApi();
  const [earnings, setEarnings] = useState([]);

  const getEarningsHistory = () => {
    api.getEarningsHistory().handle({
      onSuccess: (res) => {
        setEarnings(res.data.results);
      },
      errorMessage: "Error getting earnings",
    });
  }

  useEffect(() => {
    getEarningsHistory()
  }, []);

  const [open, setOpen] = useState(false);

  return (
    <Box>
      <Box display="flex" flexDirection="row" mt={2} borderBottom="1px solid lightgray">
        <MDTypography flex={1} color="primary">
          Balance Sheet History
        </MDTypography>
        <IconButton onClick={() => setOpen(!open)}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>
      {open &&
        earnings.length > 0 &&
        earnings.map((item) => (
          <Grid container key={item.id} px={3} my={1}>
            <Grid item xs={6} display="flex" alignContent="center">
              <MDTypography color="dark" style={{ textDecoration: "underline" }}>
                {item.description}
              </MDTypography>
            </Grid>
            <Grid item xs={6} display="flex" alignItems="center" justifyContent="flex-end">
              {item.transaction_type === TRANSACTION_TYPE.ADDITION || item.transaction_type === TRANSACTION_TYPE.REFUND?
                <>
                  <MDTypography color="dark">{item.amount}</MDTypography>
                  <Paid color={"primary"} sx={{ ml: 1 }} />
                </>
              :
                <>
                  <MDTypography color="dark">{`-${item.amount}`}</MDTypography>
                  <Paid color={"error"} sx={{ ml: 1 }} />
                </>
              }
            </Grid>
          </Grid>
        ))}
    </Box>
  );
};

export default StudentsEarningHistory;
