import { Box, Typography } from "@mui/material";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import moment from "moment";
import React, { useMemo } from "react";
import { Bar, Doughnut, Line } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export function StudentsEngagementBars({statsData = [], width, height}) {
  const engagementUnreportedData = statsData.map(entry => entry.engagement_unreported);
  const engagementCorrectData = statsData.map(entry => entry.engagement_correct);
  const engagementIncorrectData = statsData.map(entry => entry.engagement_incorrect);
  const maxTotalEngagement = Math.max(...statsData.map(entry => entry.total_engagement_asked));

  const options = {
    responsive: true,
    maintainAspectRatio: false, // This allows you to control the chart's aspect ratio
    scales: {
      x: { stacked: true },
      y: {
        beginAtZero: true,
        stacked: true,
        min: 0, // Set the minimum value for the y-axis
        max: maxTotalEngagement, // Set the maximum value for the y-axis
        ticks: {
          stepSize: 10, // Set the interval between y-axis ticks
        },
      },
    },

    pointRadius: 0,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Student Engagement",
      },
    },
  };

  const labels = statsData.map(meeting => moment(`${meeting?.date} 00:00:00`).format("MMMM DD"));

  const datasets = [    
    {
      label: "Correct",
      data: engagementCorrectData,
      backgroundColor: "green",
      borderColor: "white",
      borderWidth: "5",
      borderRadius: "2px 2px 0px 0px",
    },
    {
      label: "Incorrect",
      data: engagementIncorrectData,
      backgroundColor: "red",
      borderColor: "white",
      borderWidth: "5",
      borderRadius: "2px 2px 0px 0px",
    },
    {
      label: "Unreported",
      data: engagementUnreportedData,
      backgroundColor: '#FFC130',
      borderColor: "white",
      borderWidth: "5",
      borderRadius: "2px 2px 0px 0px",
    }
  ];

  const data = {
    labels,
    datasets: datasets,
  };
  //
  return (
    <Box borderRadius={2} boxShadow={2} p={2} mb={3} width={width} height={height}>
      <Typography fontSize={18}>Student Engagement</Typography>
      <Box height="250px">
        <Bar options={options} data={data} />
      </Box>
    </Box>
  );
}

export function StudentUnderstandingBars({statsData = [], width, height}) {
  const totalQuestionsData = statsData.map(entry => entry.total_questions);
  const totalNoReply = statsData.map(entry => entry.unanswered);
  const correctResponsesData = statsData.map(entry => entry.correct_answers);
  const incorrectResponsesData = statsData.map(entry => entry.incorrect_answers);

  const labels = statsData.map(meeting => moment(`${meeting?.date} 00:00:00`).format("MMMM DD"));

  const options = {
    responsive: true,
    maintainAspectRatio: false, // This allows you to control the chart's aspect ratio
    scales: {
      x: { stacked: true },
      y: {
        beginAtZero: true,
        stacked: true,
        min: 0, // Set the minimum value for the y-axis
        max: Math.max(...totalQuestionsData), // Set the maximum value for the y-axis
        ticks: {
          stepSize: 10, // Set the interval between y-axis ticks
        },
      },
    },

    pointRadius: 0,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Student Engagement",
      },
    },
  };  
  
  const datasets = [
    {
      label: 'Correct',
      data: correctResponsesData,
      backgroundColor: 'green',
      borderColor: "white",
      borderWidth: "5",
      borderRadius: "2px 2px 0px 0px",
    },
    {
      label: 'Incorrect',
      data: incorrectResponsesData,
      backgroundColor: 'red',
      borderColor: "white",
      borderWidth: "5",
      borderRadius: "2px 2px 0px 0px",
    },
    {
      label: 'No Reply',
      data: totalNoReply,
      backgroundColor: '#FFC130',
      borderColor: "white",
      borderWidth: "5",
      borderRadius: "2px 2px 0px 0px",
    },
  ];

  const data = {
    labels,
    datasets: datasets
  };
  //
  return (
    <Box borderRadius={2} boxShadow={2} p={2} width={width} height={height}>
      <Typography fontSize={18}>Student Understanding</Typography>
      <Box height="250px">
        <Bar options={options} data={data} />
      </Box>
    </Box>
  );
}

export function StudentsEngagement({meetings = [], width, height}) {
  const labels = meetings.map(meeting => moment(`${meeting?.date} 00:00:00`).format("MMMM DD"));
  const engagementTotal = meetings.map(meeting => meeting.engagement_total);
  const engagementCorrect = meetings.map(meeting => meeting.engagement_correct);
  const engagementIncorrect = meetings.map(meeting => meeting.engagement_incorrect);
  const engagementUnreported = meetings.map(meeting => meeting.engagement_unreported);
  const maxEngagementTotal = Math.max(...engagementTotal);
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          color: 'white',
        },
      },
      y: {
        beginAtZero: true,
        min: 0,
        max: maxEngagementTotal + 1,
        ticks: {
          color: 'white',
          stepSize: 1,
        },
      },
    },
    borderColor: 'white',
    pointRadius: 0,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          color: 'white',
        },
      },
      title: {
        display: true,
        text: 'Student Understanding',
        color: 'white',
        align: 'start',
        font: {
          weight: 'bold',
          size: 12,
        },
      },
    },
  };

  const datasets = [
    {
      label: 'Correct',
      borderColor: 'green',
      data: engagementCorrect,
      pointRadius: 5,
      pointHoverRadius: 10
    },
    {
      label: 'Incorrect',
      borderColor: 'red',
      data: engagementIncorrect,
      pointRadius: 5,
      pointHoverRadius: 10
    },
    {
      label: 'No reply',
      borderColor: '#FFC130',
      data: engagementUnreported,
      pointRadius: 5,
      pointHoverRadius: 10
    },
  ];

  const data = {
    labels,
    datasets: datasets,
  };
  //
  return (
    <Box bgcolor="#2F3E46" borderRadius={2} p={2} width={width} height={ height || "250px"}>
      <Line options={options} data={data} />
    </Box>
  );
}

export function StudentUnderstanding({meetings = [], width, height}) {
  const labels = meetings.map(meeting => moment(`${meeting?.date} 00:00:00`).format("MMMM DD"));
  const datasets = [
    {
      label: 'Correct',
      borderColor: 'green',
      data: meetings.map(meeting => meeting.correct),
      pointRadius: 5,
      pointHoverRadius: 10
    },
    {
      label: 'Incorrect',
      borderColor: 'red',
      data: meetings.map(meeting => meeting.incorrect),
      pointRadius: 5,
      pointHoverRadius: 10
    },
    {
      label: 'No reply',
      borderColor: '#FFC130',
      data: meetings.map(meeting => meeting.unanswered),
      pointRadius: 5,
      pointHoverRadius: 10
    },
  ];
  const totalQuestions = meetings.map(meeting => meeting.total_answers);
  const maxTotalQuestions = Math.max(...totalQuestions);
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          color: 'white',
        },
      },
      y: {
        beginAtZero: true,
        min: 0,
        max: maxTotalQuestions + 5,
        ticks: {
          color: 'white',
          stepSize: 1,
        },
      },
    },
    borderColor: 'white',
    pointRadius: 0,
    plugins: {
      legend: {
        display: true,
        position: 'top', // Puedes ajustar la posición de la leyenda según tus necesidades
        labels: {
          color: 'white',
        },
      },
      title: {
        display: true,
        text: 'Student Understanding',
        color: 'white',
        align: 'start',
        font: {
          weight: 'bold',
          size: 12,
        },
      },
    },
  };
  const data = {
    labels,
    datasets: datasets,
  };
  //
  return (
    <Box bgcolor="#2F3E46" borderRadius={2} p={2} width={width} height={ height || "250px"}>
      <Line options={options} data={data} />
    </Box>
  );
}

export const QuestionStats = () => {
  // Sample data for the chart
  const chartData = {
    labels: ["Response counter"],
    datasets: [
      {
        label: "Wrong answer",
        backgroundColor: "red",
        data: [51],
      },
      {
        label: "Correct",
        backgroundColor: "#00B447",
        data: [20],
      },
      {
        label: "Overall understanding",
        backgroundColor: "#FFC130",
        data: [5],
      },
    ],
  };
  const chartOptions = {
    indexAxis: "y",
    barThickness: 24,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Student Understanding",
        color: "white",
      },
    },
    scales: {
      x: { stacked: true, ticks: { display: false } },
      y: {
        stacked: true,
        beginAtZero: true,
        min: 0,
        max: 100,
        ticks: {
          color: "white",
          stepSize: 10,
        },
      },
    },
  };

  return (
    <Box bgcolor="#2F3E46" borderRadius={2} p={1}>
      <Bar data={chartData} options={chartOptions} />
      <Bar
        data={{
          labels: ["Overall understanding"],
          datasets: [
            {
              label: "Overall understanding",
              backgroundColor: "#00B447",
              data: [20],
            },
          ],
        }}
        options={{
          ...chartOptions,

          scales: {
            x: {
              min: 0, // Set the minimum value for the y-axis
              max: 100, // Set the maximum value for the y-axis
              ticks: { display: false },
            },
            y: {
              stacked: true,
              beginAtZero: true,
              min: 0,
              max: 100,
              ticks: {
                color: "white",
                stepSize: 10,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: false,
            },
          },
        }}
      />
    </Box>
  );
};

export const QuestionStatsSmall = ({title, data, width, heigth}) => {
  // Sample data for the chart
  const labels = [""]

  const charData = useMemo(() => {
    const newdata = {
      labels: labels,
      datasets: data.answers.map((answer, index) => {
        return {
          label: answer.text,
          backgroundColor: answer.correct ?  "green" : "red",
          data: [answer.count],
        }
      })
    }
    // console.log(newdata)
    return newdata
  }, [data])

  const charData2 = useMemo(() => {
    const newdata = {
      labels: [''],
      datasets: [{
        label: 'Correct Answers',
        backgroundColor: 'green',
        data: [data.understanding_correct]
      },
        {
          label: 'Wrong Answers',
          backgroundColor: 'red',
          data: [data.total - data.understanding_correct]
        },
        {
          label: 'Did not reply / Pending',
          backgroundColor: '#FFC130',
          data: [data.students - data.total]
        },
      ]
    }
    return newdata
  }, [data])

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "y",
    barThickness: 24,
    plugins: {
      legend: {
        display: false,
        position: 'right',
      },
      // title: {
      //   display: true,
      //   text: title,
      //   color: "white",
      //   align: 'start',
      // },
    },
    interaction: {
      intersect: false,
      axis : 'y',
      mode : 'nearest',
    },
    scales: {
      x: {
       stacked: true,
        ticks: { display: false }
      },
      y: {
        stacked: true,
        beginAtZero: true,
        min: 0,
        max: 100,
        ticks: {
          color: "white",
          stepSize: 10,
        },
      },
    },
  };

  return (
    <Box bgcolor="#2F3E46" borderRadius={2} p={1} m={1} width={"350px"}>
      <Box>
      <Typography color={'#FFF'} fontWeight="bold" fontSize={"12px"} borderBottom={"1px solid rgba(255, 255, 255, 0.50)"}>
        {title}
      </Typography>
      </Box>
      {/*  */}
      <Box display={"flex"} alignItems={'center'}>
        <Typography color={'#FFF'} fontSize={"11px"} variant={'p'}>
          Response Counter
        </Typography>
        <Box height={heigth} width={width}>
          <Bar data={charData} options={chartOptions} />
        </Box>
      </Box>
      {/*  */}
      <Box display={"flex"} alignItems={'center'}>
          <Typography color={'#FFF'} fontSize={"11px"} variant={'p'}>
            Overall Understanding
          </Typography>
          <Box height={heigth} width={width}>
            <Bar  data={charData2} options={chartOptions} />
          </Box>
      </Box>
    </Box>
  );
};

export const UnderstandingChart = ({ understandingPercentage }) => {
  const chartData = {
    labels: ["Understanding", "Remaining"],
    datasets: [
      {
        data: [understandingPercentage, 100 - understandingPercentage],
        backgroundColor: ["#FFE661", "#B6B6B6"], // You can customize the colors
        borderColor: "transparent",
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      title: {
        display: true,
        text: "Understanding",
        color: "white",
      },
    },
  };

  return (
    <Box bgcolor="#2F3E46" borderRadius={2} p={1}>
      <Doughnut data={chartData} options={chartOptions} />
    </Box>
  );
};
export const StudentEngagement = () => {
  // Sample data for the chart
  const chartData = {
    labels: ["8:00 am", "8:30 am", "9:00 am", "9:30 am", "10:00 am"],
    datasets: [
      {
        label: "Wrong answer",
        backgroundColor: "yellow",
        data: [49, 60, 0, 0, 0],
      },
      {
        label: "Correct anwsers",
        backgroundColor: "green",
        data: [0, 0, 100, 0, 0],
      },
      {
        label: "Mid understanding",
        backgroundColor: "red",
        data: [0, 0, 100, 10, 30],
      },
    ],
  };

  const chartOptions = {
    barThickness: 24,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Student Understanding",
        color: "white",
      },
    },
    scales: {
      x: { stacked: true, ticks: { color: "white" } },
      y: {
        stacked: true,
        beginAtZero: true,
        min: 0,
        max: 100,
        ticks: {
          color: "white",
          stepSize: 10,
        },
      },
    },
  };

  return (
    <Box bgcolor="#2F3E46" borderRadius={2} p={1} mt={3}>
      <Bar data={chartData} options={chartOptions} />
    </Box>
  );
};
