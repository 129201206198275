import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CourseStudentList from "../new-course/CourseStudentList";
import {useParams} from "react-router-dom";
import { Grid } from "@mui/material";

const TeacherCourseEnrolment = () => {
  const {courseId} = useParams()

  return (
    <MDBox width={"100%"}>
      <MDBox py={1} gap={1} borderBottom="1px solid rgba(0, 0, 0, 0.25)">
        <MDTypography flex={1} variant="h3" color="primary" fontWeight="regular">
          Students
        </MDTypography>
      </MDBox>
      <Grid container >
        <Grid item xs={12}>
      <CourseStudentList courseId={courseId}/>
      </Grid>
      </Grid>
    </MDBox>

  );
};

export default TeacherCourseEnrolment;