import React, { useState, useRef, useEffect, useCallback } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import PageLayout from "components/PageLayout";
import editIcon from "../../assets/icons/edit.svg";
import { Container, Grid, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import FormikInput from "../../components/FormikInput";
import { useApi } from "services/helpers";
import camera from "assets/icons/camera.svg";
import greenCircle from 'assets/icons/GreenCircle.svg'
import {Box} from "@mui/system";

const EditProfileInfo = ({ setIsEditing, userInfo }) => {
  const formikRef = useRef();
  const api = useApi();
  const [imgPreview, setImgPreview] = useState(userInfo.teacher?.image || userInfo.student?.image || null);
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
  });
  const [loading, setLoading] = useState(false);

  const initialValues = {
    image: imgPreview,
    name: userInfo.name || "",
    email: userInfo.email || "",
    grade: userInfo.teacher?.grades || userInfo.student?.grade || "",
    courses: userInfo.teacher?.courses || [],
    phone_number: userInfo.teacher?.phone_number || "",
    subject: userInfo.teacher?.subject || "",
    bio: userInfo.teacher?.bio || userInfo.student?.bio || "",
    school: userInfo.teacher?.school || userInfo.student?.school || "",
  };

  const handleUploadFile = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImgPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  }

  const updateUser = useCallback(async (data) => {
    setLoading(true)
    if (userInfo.teacher) {
      api.updateTeacherProfile({...data, grades: data.grade}).handle({
        onSuccess: (res) => {
          setIsEditing(false)
        },
        successMessage: "Profile updated successfully",
        errorMessage: "Error updating user info",
        onFinally: () => setLoading(false),
      });
    } else{
      api.updateStudentProfile(data).handle({
        onSuccess: (res) => {
          setIsEditing(false)
        },
        successMessage: "Profile updated successfully",
        errorMessage: "Error updating user info",
        onFinally: () => setLoading(false),
      });
    }
  }, [api]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={(values) => updateUser(values)}
    >
      {({ errors, isValid, setFieldValue }) => (
        <Form>
          <Container sx={{ display: "flex", flexDirection: "column", mt: 2}}>
            <MDBox sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
              <MDBox sx={{position: 'relative'}}>
                {imgPreview?
                  <Box component={"img"} src={imgPreview} alt={"Profile Picture"} borderRadius={'50%'} width={"132px"}
                       height={"132px"} sx={{ mx: "auto" }}/>
                  :
                  <AccountCircle fontSize="132px" sx={{ mx: "auto", fontSize: "132px" }} />
                }
                <input
                  type='file'
                  accept='image/*'
                  id={'input_file'}
                  onChange={(e) => {
                    const file = e.target.files[0]
                    handleUploadFile(e);
                    setFieldValue('image', file)
                  }}
                  style={{display: 'none'}}
                />
                <label htmlFor='input_file'>
                  <Box
                    component={"img"}
                    src={camera}
                    alt={"camera"}
                    sx={{position: 'absolute', bottom: 15, right: 0, cursor: 'pointer'}}
                  />
                </label>
              </MDBox>
            </MDBox>
            <MDBox style={{ width: "50%", margin: "auto" }}>
              <MDTypography color="primary" variant="h3" fontWeight="regular" textAlign="center">
                <FormikInput
                  name="name"
                  placeholder="Name"
                  errors={errors}
                />
              </MDTypography>
              <MDTypography color="#5F6368" fontWeight="bold">
                School Name
              </MDTypography>
              <MDTypography color="#5F6368" variant="body2">
                <FormikInput
                  name="school"
                  placeholder="School Name"
                  errors={errors}
                />
              </MDTypography>
              <MDTypography color="#5F6368" fontWeight="bold">
                Courses
              </MDTypography>
              { userInfo.teacher?.courses.map(course => {
                return (
                  <MDTypography color="#5F6368" variant="body2">
                    {course.name}
                  </MDTypography>
                )
              })}
              <MDTypography color="#5F6368" fontWeight="bold">
                Grade
              </MDTypography>
              <MDTypography color="#5F6368" variant="body2">
                <FormikInput
                  name="grade"
                  placeholder="Grade"
                  errors={errors}
                />
              </MDTypography>
              <MDTypography color="#5F6368" fontWeight="bold">
                Email
              </MDTypography>
              <MDTypography color="#5F6368" variant="body2">
                <FormikInput
                  name="email"
                  placeholder="Email"
                  errors={errors}
                  disabled
                />
              </MDTypography>
              { userInfo.teacher &&
                <>
                  <MDTypography color="#5F6368" fontWeight="bold">
                    Phone Number
                  </MDTypography>
                  <MDTypography color="#5F6368" variant="body2">
                    <FormikInput
                      name="phone_number"
                      placeholder="Phone number"
                      errors={errors}
                    />
                  </MDTypography>
                </>
              }
              { userInfo.teacher?
                <>
                  <MDTypography color="#5F6368" fontWeight="bold">
                    Subject
                  </MDTypography>
                  <MDTypography color="#5F6368" variant="body2">
                    <FormikInput
                      name="subject"
                      placeholder="Subject"
                      errors={errors}
                    />
                  </MDTypography>
                </>
                :
                <>
                  <MDTypography color="#5F6368" fontWeight="bold">
                    About me
                  </MDTypography>
                  <MDTypography color="#5F6368" variant="body2">
                    <FormikInput
                      type="textarea"
                      rows={3}
                      name="bio"
                      placeholder="About me"
                      errors={errors}
                    />
                  </MDTypography>
                </>
              }
            </MDBox>

            <MDBox display="flex" gap={2} justifyContent="end">
              <MDButton variant="contained" onClick={() => setIsEditing(false)}>
                Cancel
              </MDButton>
              <MDButton color="primary" type="submit" loading={loading} disabled={loading}>
                Save
              </MDButton>
            </MDBox>
          </Container>
        </Form>
      )}
    </Formik>
  );
};
const ProfileInfo = ({ name, schoolName, course, grade, email, aboutMe, phoneNumber, subject, image }) => {
  return (
    <MDBox sx={{ mt: 3, maxWidth: 350, mx: "auto" }}>
      <Grid item display="flex">
        {image?
          <Box component={"img"} src={image} alt={"Profile Picture"} borderRadius={'50%'} width={"132px"}
               height={"132px"} sx={{ mx: "auto" }}/>
          :
          <AccountCircle fontSize="132px" sx={{ mx: "auto", fontSize: "132px" }} />
        }
      </Grid>
      <MDBox>
        <MDTypography fontSize={{xs:'24px', md:'30px'}} color="primary" variant="h3" fontWeight="regular">
          {name}
        </MDTypography>
        <MDTypography fontSize={{xs:'18px', md:'24px'}} color="#5F6368" fontWeight="bold">
          School Name
        </MDTypography>
        <MDTypography fontSize={{xs:'18px', md:'24px'}} color="#5F6368" variant="body2">
          {schoolName}
        </MDTypography>
        {course && (
          <>
            <MDTypography fontSize={{xs:'18px', md:'24px'}} color="#5F6368" fontWeight="bold">
              Courses
            </MDTypography>
            { course.map(course => {
              return (
            <List> 
            <ListItem>
            <ListItemIcon sx={{minWidth:'20px'}}>
            <Box component={"img"}
                    src={greenCircle}
                    alt={"greenCircle"}
                    sx={{width:'8px'}}/>
              </ListItemIcon>
              <ListItemText >
              {course.name}
              </ListItemText>
            </ListItem>
           </List>
              )})}
          </>
        )}
        {grade && (
          <>
            <MDTypography fontSize={{xs:'18px', md:'24px'}} color="#5F6368" fontWeight="bold">
              Grade
            </MDTypography>
            <MDTypography color="#5F6368" variant="body2">
              {grade}
            </MDTypography>
          </>
        )}
        <MDTypography fontSize={{xs:'18px', md:'24px'}} color="#5F6368" fontWeight="bold">
          Email
        </MDTypography>
        <MDTypography color="#5F6368" variant="body2">
          {email}
        </MDTypography>
        { phoneNumber &&
          <>
            <MDTypography color="#5F6368" fontSize={{xs:'18px', md:'24px'}} fontWeight="bold">
              Phone Number
            </MDTypography>
            <MDTypography color="#5F6368" variant="body2" fontSize={{xs:'18px', md:'24px'}} >
              {phoneNumber}
            </MDTypography>
          </>
        }
        {subject &&
          <>
            <MDTypography color="#5F6368" fontWeight="bold" fontSize={{xs:'18px', md:'24px'}}>
              Subject
            </MDTypography>
            <MDTypography color="#5F6368" variant="body2" fontSize={{xs:'18px', md:'24px'}}>
              {subject}
            </MDTypography>
          </>
        }
        {aboutMe &&
          <>
            <MDTypography color="#5F6368" fontWeight="bold">
              About me
            </MDTypography>
            <MDTypography color="#5F6368" variant="body2">
              {aboutMe}
            </MDTypography>
          </>
        }
      </MDBox>
    </MDBox>
  );
};
const Profile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const api = useApi();
  const fetchUserInfo = useCallback(async () => {
    api.getUserInfo().handle({
      onSuccess: (res) => {
        setUserInfo(res.data);
      },
      errorMessage: "Error fetching user info",
    });
  }, [api]);

  useEffect(() => {
    fetchUserInfo();
  }, [fetchUserInfo, isEditing]);

  return (
    <PageLayout>
      <MDBox display="flex" py={1} borderBottom="1px solid rgba(0, 0, 0, 0.25)">
        <MDTypography fontSize={{xs:'24px', md:'30px'}} flex={1} variant="h3" color="dark" fontWeight="regular">
          Profile
        </MDTypography>
        {!isEditing && (
          <MDButton variant="contained" size="small" onClick={() => setIsEditing(true)}>
            <MDTypography variant="button" color="#5F6368" fontWeight="bold">
              Edit profile
            </MDTypography>
            <img src={editIcon} alt="edit" style={{ marginLeft: "8px" }} />
          </MDButton>
        )}
      </MDBox>
      {!isEditing ? (
        <ProfileInfo
          name={userInfo.name}
          aboutMe={userInfo.teacher?.bio || userInfo.student?.bio}
          course={userInfo.teacher?.courses}
          grade={userInfo.teacher?.grades || userInfo.student?.grade}
          schoolName={userInfo.teacher?.school || userInfo.student?.school}
          email={userInfo.email}
          phoneNumber={userInfo.teacher?.phone_number}
          subject={userInfo.teacher?.subject}
          image={userInfo.teacher?.image || userInfo.student?.image}
        />
      ) : (
        <EditProfileInfo userInfo={userInfo} setIsEditing={setIsEditing} />
      )}
    </PageLayout>
  );
};

export default Profile;
