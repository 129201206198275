import React from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import ProductCard, { AddCourseCard } from "./ProductCard";
import { Grid, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const TeachersMarketplace = () => {
  return (
    <>
      <MDBox display="flex" py={1} borderBottom="1px solid rgba(0, 0, 0, 0.25)" gap={3}>
        <MDTypography flex={1} variant="h3" color="dark" fontWeight="regular">
          Marketplace
        </MDTypography>

        <MDButton variant="contained" onClick={() => {}}>
          New Category
        </MDButton>
      </MDBox>
      <MDBox px={3}>
        <MDBox sx={{ my: 2 }} display="flex" borderBottom={"1px solid lightgray"}>
          <MDTypography color="primary" flex={1}>
            <IconButton color="primary">
              <EditIcon />
            </IconButton>
            Category
          </MDTypography>
          <IconButton color="primary">
            <ExpandMoreIcon />
          </IconButton>
        </MDBox>
        <Grid container mt={3} spacing={{ xs: 0, md: 4 }}>
          {[1, 2, 3].map((item) => (
            <Grid key={item} item xs={12} sm={6} lg={3} mt={4}>
              <ProductCard />
            </Grid>
          ))}
          <Grid item xs={12} sm={6} lg={3} mt={4}>
            <AddCourseCard />
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
};

export default TeachersMarketplace;
