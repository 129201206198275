import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import MDBox from "../../components/MDBox";
import ListItemIcon from "@mui/material/ListItemIcon";

export const CheckboxList = ({options, changeOption, getOptionLabel, id}) => {
    const listid = id || `checkbox-list`;
    const getOptionLabelInternal = getOptionLabel || ((key, value) => key);

    return (
        <List dense sx={{ width: '100%', maxWidth: 450, bgcolor: 'background.paper' }}>
            {Object.entries(options).map(([key, value]) => {
                const labelId = `${listid}-${key}`;
                return (
                    <ListItem
                        key={`${listid}-${key}-item`}
                        disablePadding
                    >
                        <ListItemButton onClick={() => changeOption(key, value)}>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={value}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                            />
                        </ListItemIcon>
                            <ListItemText id={labelId} primary={getOptionLabelInternal(key, value)} />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    );
}
