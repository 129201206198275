import React, {useRef} from "react";

export const WakeLockContext = React.createContext();
export const WakeLockContextProvider = ({ children }) => {
    const isWakeLockSupported = 'wakeLock' in navigator;


    const wakeLockRef = useRef(null)

    const requestWakeLock = async () => {
        try {
            if (isWakeLockSupported) {
                wakeLockRef.current = await navigator.wakeLock.request('screen');
                console.log('Wake Lock activated!');
            }
        } catch (err) {
            console.error(`Failed to request Wake Lock: ${err}`);
        }
    };

    const releaseWakeLock = () => {
        if (wakeLockRef.current !== null) {
            wakeLockRef.current.release();
            wakeLockRef.current  = null;
            console.log('Wake Lock released!');
        }
    };


    return (
        <WakeLockContext.Provider value={{
            requestWakeLock, releaseWakeLock
        }}>
            {children}
        </WakeLockContext.Provider>
    );
}
