import { Box, Grid, Typography } from "@mui/material";
import MDBox from "../../../components/MDBox";
import {MathJax} from "better-react-mathjax";

export const buttonXZBARender = (option, index, handleOptionClick) => {
  const height = window.innerHeight * 0.2;

  return (
    <Grid item xs={3} key={`id-${index}`} sx={{ position: "relative" }} onClick={option?.action}>
      <MDBox
        height={`${height}px`}
        p={1}
        display={"flex"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        border={option?.text ? `2px solid ${option?.color}` : "none"}
        sx={{ borderRadius: 2, overflow: "auto" }}
        onClick={()=> handleOptionClick(option)}
      >
        <MDBox
          sx={{
            overflowX: "auto",
            whiteSpace: "wrap",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Typography variant="body2" fontSize={12} fontWeight={"600"} color={"#fff"}>
              <MathJax>{option?.text}</MathJax>
          </Typography>
        </MDBox>
      </MDBox>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="20px"
        height="20px"
        bgcolor={`${option?.color}`}
        borderRadius="25px"
        sx={{ bottom: -10, position: "absolute", left: 16, right: 0, margin: "0 auto" }}
      >
        <Typography textAlign="center" fontSize={12} sx={{color:'white !important', textShadow:' -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;'}} >
          {option?.button}
        </Typography>
      </Box>
    </Grid>
  );
};

export const buttonArrowsRender = (option, index, handleOptionClick) => {
  const height = window.innerHeight * 0.2;
  return (
    <Grid item xs={3} key={`id-${index}`} sx={{ position: "relative" }} onClick={option?.action}>
      <MDBox
        height={`${height}px`}
        bgColor={option?.text ? "#2c2d2c" : "transparent"}
        border={option?.text ? "2px solid #6A994E" : "transparent"}
        flexDirection={"column"}
        p={1}
        sx={{ borderRadius: 2, overflow: "auto" }}
        onClick={()=> handleOptionClick(option)}
      >
        <MDBox
          sx={{
            overflowX: "auto",
            whiteSpace: "wrap",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Typography
            variant="body2"
            fontSize={"12px"}
            fontWeight={"600"}
            color={option?.text ? "#6A994E" : "transparent"}
          >
              <MathJax>{option?.text}</MathJax>
          </Typography>
        </MDBox>
      </MDBox>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="20px"
        height="20px"
        bgcolor={option?.text ? "#D9D9D9" : "transparent"}
        borderRadius="5px"
        sx={{ bottom: -10, position: "absolute", left: 16, right: 0, margin: "0 auto" }}
      >
        {option?.button}
      </Box>
    </Grid>
  );
};
