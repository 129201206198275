import MDBox from "../MDBox";

export const EmptyResponse = ({ text, height, isRow=false }) => {
  return (
    <MDBox display={"flex"} justifyContent={"center"} sx={{width:"100%"}}>
      <p
        style={{
          display: "flex",
          height: height,
          justifyContent: "center",
          alignItems: "center",
          fontSize: 20,
        }}
      >
        {text}
      </p>
    </MDBox>
  );
};
