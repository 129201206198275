import React, { useState } from "react";
import { QrReader } from "react-qr-reader";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../services/constants";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import { useScreenOrientation } from "../../utils/helpers";
import {useApi} from "../../services/helpers";

export const QrReaderTest = (props) => {
  const navigate = useNavigate();
  const orientation = useScreenOrientation();
  const [loading, setLoading] = useState(false);
  const api = useApi();

    const markAttendance = (code) => {
        setLoading(true)
        api.markAttendance(code).handle({
            onSuccess: (res) => {
                console.log("The response is:", res)
                navigate(ROUTES.GAME_VIEW(res?.data?.mid))
            },
            successMessage: "Entering into class...",
            errorMessage: "Error entering the class",
            onFinally: () => setLoading(false)
        })
    }

  return (
    <MDBox mt={2}>
      <MDBox
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >

        <MDTypography variant={"h3"} p={1}>
          Please scan the QR
          <br /> to enter the class
        </MDTypography>
      </MDBox>
      <MDBox display={"flex"} justifyContent={"center"} alignItems={"flex-start"}>
        <QrReader
          constraints={{ facingMode: "environment" }}
          onResult={(result, error) => {
            if (!!result) {
                if (!loading){
                    markAttendance(result?.text)
                }
            }
            if (!!error) {
              console.info(error);
            }
          }}
          containerStyle={{ width: orientation === "portrait-primary" ? "100%" : "40%" }}
          videoContainerStyle={{ width: "100%", height: "100%", display: "flex", paddingTop: 0 }}
          videoStyle={{ position: "relative" }}
        />
      </MDBox>
    </MDBox>
  );
};
