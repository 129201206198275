import {useEffect, useRef, useState} from "react";
import {useServerStreamingOps} from "./provider_streaming_server";
import {useClientStreamingOps} from "./provider_streaming_client";


export const useStreamingOps = (myid, channel) => {
    const [imServer, setImServer] = useState(false)
    const serverOps = useServerStreamingOps(imServer, myid, channel)
    const clientOps = useClientStreamingOps(!imServer, myid, channel)

    const markServer = () => {
        if (imServer) return
        setImServer(true)
        console.log("marking server")
    }

    return {imServer, ...serverOps, ...clientOps, markServer}
}
