import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { courseRoutes } from "./utils";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "services/constants";
import caretLeft from "../../assets/icons/menu.svg";
import { useMediaQuery, useTheme } from "@mui/material";


const TeacherCourseNav = ({ course }) => {
  const theme= useTheme()
  const { section } = useParams();
  const navigate = useNavigate();
  const [isContentVisible, setIsContentVisible] = useState(true);
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));


  // const imgStyle = {

  //   transform: isContentVisible ? "rotate(0deg)" : "rotate(-90deg)",
  //   transition: "transform 0.1s ease-in-out", // Añadí una transición para suavizar el cambio
  // };

  const toggleContentVisibility = () => {
    if (isXs) {
      setIsContentVisible(!isContentVisible);
    } else {
      setIsContentVisible(!isContentVisible);
    }
  };

  useEffect(() => {
    if (isXs) {

      const timer = setTimeout(() => {
        setIsContentVisible(false);
      }, 1000);

      return () => clearTimeout(timer);
    }

  }, [isXs]);

  const handleItemClick = () => {
   if (isXs){
      setIsContentVisible(false)};

  };

  return (
    <MDBox mr={3} maxWidth={"180px"} >
      <MDBox mt={isContentVisible ? '10px' : '18px'}
        display={"flex"}
        alignItems={"center"}
        onClick={toggleContentVisibility}
        sx={{ cursor: "pointer" }}
      >
        <MDBox  sx={{filter: isContentVisible
              ? "grayscale(0%)"
              : "grayscale(100%)",
            transition: "filter 0.3s ease-in-out", width:'20px', minWidth:'20px', marginRight:'5px'
          }}
            component={"img"} src={caretLeft} alt={"go back"} />
      </MDBox>

      {isContentVisible && (
        <MDBox py={1}>
          {courseRoutes.map((route) => (
            <MDTypography
            fontSize={{xs:'16px', md:"20px"}}
              color={route.key === section ? "primary" : "secondary"}
              borderLeft={
                route.key === section
                  ? "5px solid #6A994E"
                  : "5px solid transparent"
              }
              key={route.key}
              fontWeight="regular"
              sx={{ cursor: "pointer" }}
              whiteSpace={"nowrap"}
              pl={1}
              onClick={() => {
                if (route.key === 'calendar'){
                  navigate(`/calendar?cid=${course.id}`);
                } else {
                  navigate(`${ROUTES.COURSES}/${course.id}/${route.key}`);
                }
                handleItemClick();
              }}
              mt={1}
            >
              {route.name}
            </MDTypography>
          ))}
        </MDBox>
      )}
    </MDBox>
  );
};

export default TeacherCourseNav;
