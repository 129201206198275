import { Box, Grid, Icon, IconButton, Typography, styled } from "@mui/material";
import { verticalButtonArrowsRender, verticalButtonXZBARender } from "./verticalButtons";
import { Paid } from "@mui/icons-material";
import React, { useState } from "react";
import toiletPaper from "../../../assets/images/papelH.png";
import thumbsUp from "../../../assets/images/like.png";
import handsUp from "../../../assets/images/stop.png";
import thumbsDown from "../../../assets/images/dislike.png";
import flag from "../../../assets/images/flag.png";
import { useApi } from "../../../services/helpers";
import logo from "../../../assets/images/logos/logo.png";
import { STUDENT_FEEDBACK, STUDENT_FLAGS } from "../../../services/constants";
import { ACTIVE_BUTTON_COLOR, INACTIVE_BUTTON_COLOR } from "../common_subscreens";
import MDBox from "../../../components/MDBox";
import { useParams } from "react-router-dom";
import { MathJax } from "better-react-mathjax";

export const VerticalMultipleChoiceScreen = ({ XZBA_OPTIONS, ARROW_OPTIONS, prompt, reward }) => {
    const [selectedOptions, setSelectedOptions] = useState(null);
    const handleOptionClick = (option) => {
        setSelectedOptions(option);
    };
    const handleClosedClick = () => {
        setSelectedOptions(null);
    };

    return (
    <>
      <MDBox pb={2}>
        <Typography textAlign="center" fontWeight={"600"} fontSize={20} mb={2} color={"#FFC130"}>
          <MathJax>{prompt}</MathJax>
        </Typography>
      </MDBox>
      <MDBox
        display={"flex"}
        justifyContent={"space-between"}
        alingItems={"flex-start"}
        height={"100%"}
        gap={1}
        pb={2}
      >
          {selectedOptions ?(
                  <MDBox
                      width={"100%"}
                      border={selectedOptions?.color ? `2px solid ${selectedOptions.color}` : "2px solid #0B0F08"}
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={'center'}
                      gap={2}
                      onClick={handleClosedClick}
                      borderRadius="10px"
                      p={2}
                  >
                      <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          width="50px"
                          height="50px"
                          p={1}
                          bgcolor={selectedOptions?.color}
                          borderRadius="100%"
                      >
                          <Typography textAlign="center" fontWeight={600} color={'#babdb7 !important'} verticalAlign={'center'} sx={{alignSelf:'normal', color:'white !important', textShadow:' -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;',}}>
                              {selectedOptions?.button}
                          </Typography>
                      </Box>
                      <Typography p={2} variant="body2" fontSize={12} color={"#fff"}>
                          <MathJax>{selectedOptions?.text}</MathJax>
                      </Typography>
                  </MDBox>
              )
              : (
                  <>
        <MDBox width={"50%"} display={"flex"} flexDirection={"column"} gap={1}>
          {XZBA_OPTIONS.map((option, index) => verticalButtonXZBARender(option, index, handleOptionClick))}
        </MDBox>
        <MDBox width={"50%"} display={"flex"} flexDirection={"column"} gap={1}>
          {ARROW_OPTIONS.map((option, index) => verticalButtonArrowsRender(option, index, handleOptionClick))}
        </MDBox>
                  </>
      )}

      </MDBox>
      {!!reward && (
        <MDBox
          bgColor={"#3A532B"}
          p={0.5}
          borderRadius={"md"}
          display={"flex"}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography flex={1} fontWeight={"600"} variant="body2" color={"#fff"}>
            Reward
          </Typography>
          <MDBox display={"flex"} direction={"row"} alignItems={"center"} gap={1}>
            <Typography flex={1} fontWeight={"600"} variant="body2" color={"#fff"} sx={{textShadow:' -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;',}}>
              {reward}
            </Typography>
            <Paid color="white" />
          </MDBox>
        </MDBox>
      )}
    </>
  );
};

export const VerticalActionItems = ({ flags, pad1Component, pad2Component }) => {
  const { meetingId } = useParams();
  const api = useApi();

  const sendFeedback = (feedback) => {
    api.sendMeetingFeedback(meetingId, feedback).handle({
      successMessage: "Feedback sent",
      errorMessage: "Error sending feedback",
    });
  };

  const manageFlag = (flag) => {
    api.manageFlag(meetingId, flag, !flags[flag]).handle({
      // successMessage: "Message sent",
      errorMessage: "Error sending message",
    });
  };

  return (
    <Box color="white.main" borderTop="1px solid gray">
      <Grid container>
        <Grid item xs={5} p={3}>
          {pad1Component}
        </Grid>
        <Grid item xs={2} />
        <Grid item display={'flex'} justifyContent={'flex-end'} xs={5} p={3}>
          {pad2Component}
        </Grid>
      </Grid>
      <MDBox
        component={"img"}
        src={logo}
        style={{
          width: "30%",
          display: "block",
          margin: "auto",
          marginTop: -40,
          marginBottom: 10,
        }}
        alt="logo"
      />
      <Grid item xs={12} display="flex" px={2}>
        <Box pt={3}>
          <MDBox
            sx={{
              boxShadow: 2,
              backgroundColor: flags[STUDENT_FLAGS.BATHROOM] ? ACTIVE_BUTTON_COLOR : INACTIVE_BUTTON_COLOR,
              width: "40px",
              height: "40px",
              p: "8px",
              borderRadius: "50%",
            }}
            onClick={() => manageFlag(STUDENT_FLAGS.BATHROOM)}
          >
            <MDBox component={"img"} src={toiletPaper} width={"auto"} alt='toilet-paper' />
          </MDBox>

          {/* <IconButton
            disableHoverRipple={true}
            disableFocusRipple={true}
            sx={{
              boxShadow: 2,
              backgroundColor: isActive ? ACTIVE_BUTTON_COLOR : INACTIVE_BUTTON_COLOR,

            }}

          >
            <Icon>
              <img src={toiletPaper} alt="toilet-paper" />
            </Icon>
          </IconButton> */}
        </Box>
        <Box
          flex={1}
          border="1px solid rgba(255, 255, 255, 0.20)"
          py={1}
          m={1}
          borderRadius={2}
          display="flex"
          justifyContent="space-around"
        >
          <MDBox
            sx={{
              boxShadow: 2,
              backgroundColor: flags[STUDENT_FLAGS.THUMBS_UP] ? ACTIVE_BUTTON_COLOR : INACTIVE_BUTTON_COLOR,
              width: "40px",
              height: "40px",
              p: "8px",
              borderRadius: "50%",
            }}
            onClick={() => manageFlag(STUDENT_FLAGS.THUMBS_UP)}
          >
            <MDBox component={"img"} src={thumbsUp} width={"auto"} alt='thumbs-up' />
          </MDBox>
          {/*
          <IconButton
            sx={{
              boxShadow: 2,
              backgroundColor: flags[STUDENT_FLAGS.THUMBS_UP]
                ? ACTIVE_BUTTON_COLOR
                : INACTIVE_BUTTON_COLOR,
            }}
            onClick={() => manageFlag(STUDENT_FLAGS.THUMBS_UP)}
          >
            <Icon>
              <img src={thumbsUp} alt="toilet-paper" />
            </Icon>
          </IconButton> */}
          <MDBox
            sx={{
              boxShadow: 2,
              backgroundColor: flags[STUDENT_FLAGS.RAISE_HAND] ? ACTIVE_BUTTON_COLOR : INACTIVE_BUTTON_COLOR,
              width: "40px",
              height: "40px",
              p: "8px",
              borderRadius: "50%",
            }}
            onClick={() => manageFlag(STUDENT_FLAGS.RAISE_HAND)}
          >
            <MDBox component={"img"} src={handsUp} width={"auto"} alt='hands-up'/>
          </MDBox>
          {/* <IconButton
            sx={{
              boxShadow: 2,
              backgroundColor: flags[STUDENT_FLAGS.RAISE_HAND]
                ? ACTIVE_BUTTON_COLOR
                : INACTIVE_BUTTON_COLOR,
            }}
            onClick={() => manageFlag(STUDENT_FLAGS.RAISE_HAND)}
          >
            <Icon>
              <img src={handsUp} alt="toilet-paper" />
            </Icon>
          </IconButton> */}
          <MDBox
            sx={{
              boxShadow: 2,
              backgroundColor: flags[STUDENT_FLAGS.THUMBS_DOWN] ? ACTIVE_BUTTON_COLOR : INACTIVE_BUTTON_COLOR,
              width: "40px",
              height: "40px",
              p: "8px",
              borderRadius: "50%",
            }}
            onClick={() => manageFlag(STUDENT_FLAGS.THUMBS_DOWN)}
          >
            <MDBox component={"img"} src={thumbsDown} width={"auto"} alt='thumbs-down'/>
          </MDBox>
          {/* <IconButton
            sx={{
              boxShadow: 2,
              backgroundColor: flags[STUDENT_FLAGS.THUMBS_DOWN]
                ? ACTIVE_BUTTON_COLOR
                : INACTIVE_BUTTON_COLOR,
            }}
            onClick={() => manageFlag(STUDENT_FLAGS.THUMBS_DOWN)}
          >
            <Icon>
              <img src={thumbsDown} alt="toilet-paper" />
            </Icon>
          </IconButton> */}
        </Box>
        <Box pt={3}>
          <MDBox
            sx={{
              boxShadow: 2,
              backgroundColor: flags[STUDENT_FLAGS.FLAG] ? ACTIVE_BUTTON_COLOR : INACTIVE_BUTTON_COLOR,
              width: "40px",
              height: "40px",
              p: "8px",
              borderRadius: "50%",
            }}
            onClick={() => manageFlag(STUDENT_FLAGS.FLAG)}
          >
            <MDBox component={"img"} src={flag} width={"auto"} alt='flag'/>
          </MDBox>
          {/* <IconButton
            sx={{
              boxShadow: 2,
              backgroundColor: flags[STUDENT_FLAGS.FLAG]
                ? ACTIVE_BUTTON_COLOR
                : INACTIVE_BUTTON_COLOR,
            }}
            onClick={() => manageFlag(STUDENT_FLAGS.FLAG)}
          >
            <Icon>
              <img src={flag} alt="flag" />
            </Icon>
          </IconButton> */}
        </Box>
      </Grid>
    </Box>
  );
};
