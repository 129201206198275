import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import React, { useState } from "react";
import QuestionModal from "./QuestionModal";
import { Box, Grid, Radio } from "@mui/material";
import { Paid } from "@mui/icons-material";

const TeachersPresentation = () => {
  const [questionModal, setQuestionModal] = useState(false);

  const QuestionCardList = () => {
    return (
      <div>
        {[...data, ...data].map((item, index) => (
          <QuestionCard
            key={index}
            index={item.index}
            question={item.question}
            bucks={item.bucks}
            options={item.options}
          />
        ))}
      </div>
    );
  };
  return (
    <>
      <MDBox>
        <MDBox display="flex" py={1} borderBottom="1px solid rgba(0, 0, 0, 0.25)" gap={3}>
          <MDTypography flex={1} variant="h3" color="dark" fontWeight="regular">
            Questionnaire
          </MDTypography>

          <MDButton variant="contained" onClick={() => {}}>
            Add Questions
          </MDButton>
        </MDBox>
        <MDBox display={"flex"} flexDirection={"row"} gap={2}>
          <QuestionCardList />
          <QuestionCardList />
        </MDBox>
      </MDBox>
      <QuestionModal showModal={questionModal} handleClose={() => setQuestionModal(false)} />
    </>
  );
};
const QuestionCard = ({ index, question, bucks, options }) => {
  return (
    <MDBox sx={{ width: "335px" }}>
      <Box borderRadius={1} border="1px solid rgba(0, 0, 0, 0.25)" p={2} mt={2}>
        <Box display="flex" alignItems="center" borderBottom="1px solid rgba(0, 0, 0, 0.25)">
          <MDTypography mr={1} color="dark" variant="h5">
            {index}.
          </MDTypography>
          <MDTypography flex={1} color="dark" variant="h5">
            {question}
          </MDTypography>
          <Box></Box>
          <MDTypography color="dark" mx={1}>
            {bucks}
          </MDTypography>
          <Paid color="primary" />
        </Box>
        {options.map((option, i) => (
          <Box
            key={i} // O usa una clave única de opción si la tienes
            display="flex"
            borderBottom="1px solid rgba(0, 0, 0, 0.25)"
            py={1}
            alignItems="center"
          >
            {option.icon}
            <MDTypography flex={1} px={1} color="dark">
              {option.option}
            </MDTypography>
            <Radio />
          </Box>
        ))}
      </Box>
    </MDBox>
  );
};

const data = [
  {
    index: 1,
    question: "¿Cuál es tu color favorito?",
    bucks: "$10",
    options: [
      {
        option: "Rojo",
        icon: (
          <span
            style={{
              width: "20px",
              borderRadius: "100%",
              height: "18px",
              backgroundColor: "#ff2d3a",
            }}
          />
        ),
      },
      {
        option: "Azul",
        icon: (
          <span
            style={{
              width: "20px",
              borderRadius: "100%",
              height: "18px",
              backgroundColor: "#010dff",
            }}
          />
        ),
      },
      {
        option: "Amarillo",
        icon: (
          <span
            style={{
              width: "20px",
              borderRadius: "100%",
              height: "18px",
              backgroundColor: "#fef200",
            }}
          />
        ),
      },
      {
        option: "Verde",
        icon: (
          <span
            style={{
              width: "20px",
              borderRadius: "100%",
              height: "18px",
              backgroundColor: "#00B447",
            }}
          />
        ),
      },
    ],
  },
];

export default TeachersPresentation;
