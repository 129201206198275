import {
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    InputAdornment,
    Radio,
    RadioGroup,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
} from "@mui/material";
import FormikInput from "components/FormikInput";
import MDButton from "components/MDButton";
import ModalItem from "components/ModalItem";
import {Form, Formik, useFormikContext} from "formik";
import React, {useEffect, useRef, useState} from "react";
import {useApi} from "../../services/helpers";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import MDTypography from "../../components/MDTypography";
import PaidIcon from "@mui/icons-material/Paid";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import MDBox from "../../components/MDBox";
import {
    BUTTON_TYPE,
    TYPE_OF_INTERACTION_OPTIONS,
    TYPE_OF_QUESTIONNAIRE_OPTIONS,
} from "../../utils/helpers";
import {ArrowDropDown, ArrowDropUp, ArrowLeft, ArrowRight} from "@mui/icons-material";
import {OptionField} from "../presentation/QuestionModal";
import buttons from "../../assets/images/game-buttons.svg";
import dpad from "../../assets/images/dpad.svg";
import {MathJax} from "better-react-mathjax";
import {MutatingDots} from "react-loader-spinner";
//

const TYPE_QUESTION_TOGGLE = {
    MULTIPLE: 2,
    OPEN: 3,
    EXPLAIN: 1,
};

const IAQuestionModal = ({showModal, lessonSelected, handleClose, onAdd, courseSelected}) => {
    const api = useApi();
    const formikRef = useRef();
    const [loading, setLoading] = useState(false);
    const [questionType, setQuestionType] = React.useState(TYPE_QUESTION_TOGGLE.MULTIPLE);
    const [questionList, setQuestionList] = React.useState([]);
    const [usedQuestions, setUsedQuestions] = React.useState([]);
    const [blockGenerateButton, setBlockGenerateButton] = React.useState(false);

    const validationSchema = Yup.object().shape({
        question_type: Yup.number().required("This field is required"),
        quantity: Yup.number().required("This field is required").min(1).max(8),
    });

    const initialValues = {
        question_type: TYPE_QUESTION_TOGGLE.MULTIPLE,
        quantity: 4,
    };

    // FAKE QUESTION DATA SAMPLES, here needs to be the api call
    const generateQuestions = (data, reset=false) => {
        if (reset) {
            setUsedQuestions([])
            setQuestionList([])
            setBlockGenerateButton(false)
        } else {
            setLoading(true)
            setBlockGenerateButton(true)
            api.generateAiQuestions(courseSelected, {
                lesson: lessonSelected.id,
                prompt: data.description,
                question_type: questionType,
                responses: data.quantity,
                used_questions: usedQuestions,
            }).handle({
                onSuccess: (result) => {

                    const {response} = result;
                    const [questions, usage] = response;
                    console.log('tokens usage:', usage)

                    const qtype = questionType === TYPE_QUESTION_TOGGLE.MULTIPLE ? TYPE_OF_QUESTIONNAIRE_OPTIONS.MULTIPLE_CHOICE : TYPE_OF_QUESTIONNAIRE_OPTIONS.OPEN;
                    setQuestionList([...questionList, ...questions.map(q => {
                        let answers = data.question_type === TYPE_QUESTION_TOGGLE.EXPLAIN ? [] : q.answers.map(([answer, correct]) => ({
                            answer,
                            correct
                        }))
                        answers = questionType === TYPE_QUESTION_TOGGLE.MULTIPLE ? answers : answers.filter(({correct}) => correct)
                        return {
                            // id: null,
                            // timeout: null,
                            prompt: q.question,
                            question_type: qtype,
                            answer_type: TYPE_OF_INTERACTION_OPTIONS.BUTTONS,
                            answers: answers,
                            // reward: null,
                            // max_reward: 0,
                            randomize_answers: false,
                            // custom_order: null,
                        }
                    })]);
                    // console.log(response)
                },
                errorMessage: "Error generating questions",
                onError: (result) => {
                    console.log(result);
                    setBlockGenerateButton(false)
                    //formikRef.current.setErrors(result.errors)
                },
                onFinally: () => setLoading(false),
            })
        }

    }

    const handleChangeToggle = (event, questionType) => {
        if (questionType !== null) {
            setQuestionType(questionType);
        }
    };

    const handleRemoveQuestion = (index) => {
        setUsedQuestions([...usedQuestions, questionList[index].prompt])
        const newList = [...questionList];
        newList.splice(index, 1);
        setQuestionList(newList);
    }

    const handleAddQuestion = (index, questionData) => {
        handleRemoveQuestion(index)
        onAdd(questionData)
    }

    useEffect(() => {
        if (questionList?.length === 1 && formikRef.current) {
            generateQuestions(formikRef.current.values)
        }

    }, [questionList]);

    const handleModalClose = () => {
        setUsedQuestions([])
        setBlockGenerateButton(false)
        setQuestionList([])
        formikRef.current.resetForm()
        handleClose()
    }

    return (
        <ModalItem
            heightContainer={'100%'}
            minWidth={'80%'}
            widthContainer={'100%'}
            scrollable={false}
            open={showModal}
            closeOnClickOutside={false}
            height="80%"
            title="Generate Questions"
            handleClose={handleModalClose}
        >
            <MDBox sx={{width:'100%', padding: '20px'}}>
            <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                validateOnBlur={false}
                onSubmit={(values) => generateQuestions(values, blockGenerateButton)}
            >
                {({errors, handleSubmit, setFieldValue}) => (
                    <Form>
                        <Grid container>
                            <Grid item container display={'flex'} margin={'auto'} flexDirection={'row'} wrap={'nowrap'}  justifyContent={'space-between'} xs={12}>
                                <Grid item container xs={8} flexDirection='column' wrap={'nowrap'} display={'flex'}>
                                    <Grid item xs={12}  md={12}>
                                        <MDTypography color="dark" mb={1} fontSize={{xs: "12px", md: "18px",}}>
                                            <strong>Lesson title:</strong> {lessonSelected?.name}
                                        </MDTypography>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <MDTypography color="dark" fontSize={{xs: "12px", md: "18px"}}>
                                            <strong>Explain what the lesson is about:</strong>
                                            <FormikInput
                                                type={"textarea"}
                                                name="description"
                                                errors={errors}
                                                width={"80%"}
                                                rows={4}
                                                disabled={blockGenerateButton}
                                            />
                                        </MDTypography>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={4} flexDirection='column' wrap={'nowrap'} display={'flex'} >
                                    <Grid item xs={12} md={6} mt={1}>
                                        <MDTypography
                                            color="dark"
                                            fontSize={{xs: "12px", md: "18px"}}
                                            fontWeight={"bold"}
                                            mb={1}
                                        >
                                            Questions type:
                                        </MDTypography>
                                        <ToggleButtonGroup
                                            size="small"
                                            value={questionType}
                                            onChange={handleChangeToggle}
                                            exclusive
                                            color="primary"
                                            sx={{mb: "18px", }}

                                        >
                                            <ToggleButton
                                                value={TYPE_QUESTION_TOGGLE.MULTIPLE}
                                                key={TYPE_QUESTION_TOGGLE.MULTIPLE}
                                                disabled={blockGenerateButton}
                                            >
                                                Multiple Choice
                                            </ToggleButton>
                                            <ToggleButton
                                                value={TYPE_QUESTION_TOGGLE.OPEN}
                                                key={TYPE_QUESTION_TOGGLE.OPEN}
                                                disabled={blockGenerateButton}
                                            >
                                                Short answer
                                            </ToggleButton>
                                            <ToggleButton
                                                value={TYPE_QUESTION_TOGGLE.EXPLAIN}
                                                key={TYPE_QUESTION_TOGGLE.EXPLAIN}
                                                disabled={blockGenerateButton}
                                            >
                                                Open response
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Grid>

                                    {questionType === TYPE_QUESTION_TOGGLE.MULTIPLE && (
                                        <Grid item xs={12} md={6} mt={1}>
                                            <MDTypography
                                                color="dark"
                                                fontSize={{xs: "12px", md: "18px"}}
                                                mb={1}
                                                fontWeight={"bold"}
                                            >
                                                Quantity of responses:
                                            </MDTypography>
                                            <FormikInput
                                                type={"number"}
                                                name="quantity"
                                                errors={errors}
                                                width={"60%"}
                                                defaultValue={4}
                                                disabled={blockGenerateButton}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            {!loading && <Grid item xs={12} mb={2}>
                                <MDButton
                                    color="primary"
                                    loading={loading}
                                    disabled={loading}
                                    onClick={handleSubmit}
                                >
                                    {`${blockGenerateButton ? "Reset" : "Generate"}`}
                                </MDButton>
                            </Grid>}
                            <MDBox width={"100%"}>
                                {questionList.map((questionData, index) => (
                                    <MDBox bgColor={"#EEE"} p={3} mb={2}>
                                        <Grid item container xs={12} display={"flex"}>
                                            <Grid item xs={9}>
                                                <MDTypography color="dark" mb={1}
                                                              fontSize={{xs: "12px", md: "18px"}}>
                                                    <strong>Question: </strong> {questionData?.prompt}
                                                </MDTypography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <MDBox display={"flex"} justifyContent={"right"} gap={1}>
                                                    <MDButton color="white" onClick={() => handleAddQuestion(index,questionData)}>
                                                        <CheckIcon color="primary"/>
                                                    </MDButton>
                                                    <MDButton color="white"
                                                              onClick={() => handleRemoveQuestion(index)}>
                                                        <ClearIcon color="error"/>
                                                    </MDButton>
                                                </MDBox>
                                            </Grid>
                                        </Grid>

                                        {questionType !== TYPE_QUESTION_TOGGLE.EXPLAIN && (
                                            <Grid item xs={12}>
                                                <MDTypography
                                                    color="dark"
                                                    mb={1}
                                                    fontSize={{xs: "12px", md: "18px"}}
                                                    fontWeight={"bold"}
                                                >
                                                    Responses:
                                                </MDTypography>
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            {questionType !== TYPE_QUESTION_TOGGLE.EXPLAIN &&
                                                questionData.answers?.map((answer, i) => (
                                                    <Box
                                                        display={"flex"}
                                                        gap={5}
                                                        justifyContent={"space-between"}
                                                        height={"29px"}
                                                    >
                                                        <Box display="flex" width={"60%"} alignItems="center">
                                                            <Checkbox checked={answer?.correct}/>
                                                            <MDTypography color="dark" sx={{p: 2}}>
                                                                {answer?.answer}
                                                            </MDTypography>

                                                        </Box>
                                                    </Box>
                                                ))}
                                        </Grid>
                                    </MDBox>
                                ))}
                            </MDBox>
                            <MDBox sx={{  width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                            }}>
                                {loading && <Grid item xs={12} justifyContent={'center'} mb={2}>
                                    <MDBox sx={{  width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}>
                                        <MutatingDots width={100} height={100}/>
                                    </MDBox>

                                </Grid>}
                            </MDBox>
                        </Grid>
                    </Form>
                )}
            </Formik>
            </MDBox>
        </ModalItem>
    );
};

export default IAQuestionModal;
