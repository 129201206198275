import React from "react";


export const BarsIcon = ({ bars= 5, color= "green" }) => {
    return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        {(bars >= 1) && <line x1="4.43164" y1="20" x2="4.43164" y2="19" stroke={color} strokeWidth="2" strokeLinecap="round"/>}
        {(bars >= 2) && <line x1="8.43164" y1="20" x2="8.43164" y2="16" stroke={color} strokeWidth="2" strokeLinecap="round"/>}
        {(bars >= 3) && <line x1="12.4316" y1="20" x2="12.4316" y2="12" stroke={color} strokeWidth="2" strokeLinecap="round"/>}
        {(bars >= 4) && <line x1="16.4316" y1="20" x2="16.4316" y2="8" stroke={color} strokeWidth="2" strokeLinecap="round"/>}
        {(bars >= 5) && <line x1="20.4316" y1="20" x2="20.4316" y2="4" stroke={color} strokeWidth="2" strokeLinecap="round"/>}
    </svg>
    )
}
