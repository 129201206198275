import { Box } from '@mui/material'
import { useState } from 'react'

function useTabs(initialValue = 0) {
  const [tabValue, setTabValue] = useState(initialValue)

  const handleTabValueChange = (event, newValue) => {
    setTabValue(newValue)
  }

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    )
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  return {
    tabValue,
    handleTabValueChange,
    CustomTabPanel,
    a11yProps,
  }
}

export default useTabs
