import {
  Box,
  IconButton,
} from "@mui/material";
import FormikInput from "components/FormikInput";
import MDButton from "components/MDButton";
import ModalItem from "components/ModalItem";
import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import MDBox from "../../components/MDBox";
import {useApi, useDebouncedInput} from "../../services/helpers";
import DataTable from "../../components/DataTable";
import viewIcon from "../../assets/icons/EyeGreen.svg";
import SubjectIcon from '@mui/icons-material/Subject';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

const dataTableModel = {
  columns: [
    {Header: "Course/Lesson", accessor: "course_lesson", disableOrdering: true},
    {Header: "Question", accessor: "prompt", disableOrdering: true},
    {Header: "", accessor: "actions", disableOrdering: true}
  ],
  rows: [],
};

const question_actions = (item, setShowQuestionModal, setShowQuestionViewModal, api) => {

  const getQuestion = (then) => {
    api.retrieveCourseLessonQuestion(item.course_id, item.lesson_id, item.id, {}).handle({
        onSuccess: then,
        errorMessage: "Error getting question",
        });
  }

  return (
      <MDBox display={'flex'}>
        <IconButton
            onClick={() => {
              getQuestion((res) => {
                setShowQuestionViewModal({question: res.data, isVisible: true})
              })
            }}
            width={"25px"} heigth={"25px"}
            sx={{mr:2}}
        >
          <MDBox component={"img"} src={viewIcon} alt="view" width={"25px"} heigth={"25px"} />
        </IconButton>
        {/*<MDButton color="secondary" variant="contained" iconOnly  ><MDBox component={"img"} src={viewIcon} alt="view" width={"25px"} heigth={"25px"} /></MDButton>*/}
        <MDButton color="primary"
          onClick={() => {
            getQuestion((res) => {
              const question = res.data
              const answers = question.answers.map((item) => ({...item, id: null}))
              setShowQuestionModal({question: {...question, id: null, answers}, isVisible: true})
            })
          }}
        >Import</MDButton>
      </MDBox>
  )
}

export const renderQuestionTableRow = (item, setShowQuestionModal, setShowQuestionViewModal, api, searchQuestions) => {
  item.actions = (question_actions(item, setShowQuestionModal, setShowQuestionViewModal, api))
  item.promptplus = <>
    {item.question_type === 1 ? <SubjectIcon /> :<FormatListBulletedIcon />}
    {item.prompt}
  </>
  return item
}


const QuestionImportModal = ({ showModal, handleClose, setShowQuestionModal, setShowQuestionViewModal, showQuestionModal }) => {
  const formikRef = useRef();
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [courses, setCourses] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [datatable, setDatatable] = useState({...dataTableModel});
  const [questions, setQuestions] = useState([]);
  const [search, setSearchDebounced] = useDebouncedInput({debounceTime: 300})


  const searchQuestions = () => {
    const data = {search}
    if (selectedCourse) {
      data.course = selectedCourse.id
    }
    if (selectedLesson) {
        data.lesson = selectedLesson.id
    }
    api.searchQuestions(data).handle({
      onSuccess: (res) => {
        console.log(res)
        //setQuestions(res.data)
        setDatatable({...dataTableModel, rows: res.data.map((item) =>
              renderQuestionTableRow(item, setShowQuestionModal, setShowQuestionViewModal, api, searchQuestions)
          )})
      },
      errorMessage: "Error searching question bank",
    });
  }

  const getTeacherCourses = () => {
    api.getTeacherCourses().handle({
      onSuccess: (res) => {
        setCourses(res.data)
      },
      errorMessage: "Error getting courses",
    });
  }

  const getLessonsCourse = () => {
    api.getLessonsByCourse(selectedCourse?.id).handle({
      onSuccess: (res) => {
        setLessons(res.data.results)
        setSelectedLesson(null)
      },
      errorMessage: "Error getting lessons",
    });
  }

  useEffect(() => {
    searchQuestions()
  }, [search, selectedCourse, selectedLesson]);

  useEffect(() => {
    if (selectedCourse) {
      getLessonsCourse()
    }
  }, [selectedCourse]);

  useEffect(() => {
    getTeacherCourses()
  }, []);

  useEffect(() => {
    searchQuestions()
  }, [showQuestionModal]);

  useEffect(() => {
    if(!showModal) {
      setSelectedLesson(null)
      setSelectedCourse(null)
      setSearchDebounced('')
    }else{
      searchQuestions()
    }
  }, [showModal]);

  return (
    <ModalItem
      scrollable={false}
      open={showModal}
      closeOnClickOutside={false}
      height='80%'
      heightContainer='100%'
      title="Question bank"
      handleClose={handleClose}
      width= '90%'
      widthContainer='100%'
    >
      <Formik
          initialValues={{ search: "", course: "", lesson: "" }}
        innerRef={formikRef}
        validateOnBlur={false}
        // onSubmit={(values) => (question ? onUpdate(values) : onCreated(values))}
        checked={false}
      >
        {({ values, errors, handleSubmit, setFieldValue, isValid, dirty, resetForm }) => {
          return (
            <Form style={{ width: "100%", height:'100%' }}>
              <Box display={"flex"} flexDirection={'row'} flexWrap='wrap' mt={1} gap={1} justifyContent={'space-between'} alignItems={'center'} padding={1}>
                <FormikInput
                    //value={formik.values.course}
                    name={"search"}
                    onChange={(ev) => {
                      //setFieldValue('course', value)
                      //setSelectedCourse(value)
                      setSearchDebounced(ev.target.value)
                      // console.log('search', value)
                    }}
                    // styleContainer={{mb: 2, mr: 1}}
                    label={"Search"}
                    width={{xs:'100%', md:'32%'}}
                />
                <FormikInput
                    type={"autocomplete"}
                    //value={formik.values.course}
                    fieldName={"course"}
                    options={courses}
                    accessKey={"name"}
                    onChange={(value) => {
                        //setFieldValue('course', value)
                        setSelectedCourse(value)
                    }}
                    width={{xs:'45%', md:'32%'}}
                    // styleContainer={{ mr: 1, ml: 1}}
                    label={"Filter by Course"}

                />
                <FormikInput
                    type={"autocomplete"}
                    //value={formik.values.course}
                    fieldName={"lesson"}
                    options={lessons}
                    accessKey={"name"}
                    onChange={(value) => {
                       // formik.setFieldValue('lesson', value)
                      //    setSelectedCourse(value)
                      setSelectedLesson(value)
                    }}
                    // styleContainer={{mb: 1}}
                    label={"Filter by Lesson"}
                    width={{xs:'45%', md:'32%'}}
                />
              </Box>
              <Box display={"flex"} mt={1} overflow={"scroll"} height='auto'>


                <DataTable
                    table={datatable}
                />


              </Box>
            </Form>
          );
        }}
      </Formik>
    </ModalItem>
  );
};

export default QuestionImportModal;
