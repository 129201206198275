/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";

import App from "App";
import "./index.css";

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";

import StoreApp from "./StoreApp";
import {MathJaxContext} from "better-react-mathjax";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://ec6fc155cb0b47159876dc4206f817e3@sentry.innovatica.com.py//50",
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "0.0.0.0",
          "localhost",
          /^https:\/\/class-bucks-42046\.botics\.co\/api/,
        ],
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  //  <React.StrictMode>
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <StoreApp>
          <MathJaxContext config={{ options: { renderActions: { addMenu: [] }} }}>
            <App />
          </MathJaxContext>
      </StoreApp>
    </MaterialUIControllerProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
