


import React from "react";
import {mapValue} from "../../utils/helpers";


export const ThermometerIcon = ({ level= 50, fill= "red" }) => {
    const height = mapValue(level, 0, 100, 15, 0.1)
    return (
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">

            <path fillRule="evenodd" clipRule="evenodd"
                  d="
                  M11.6106 10.1418
                  V9.92065V1.8
                C11.6106 1.06656 11.0562 0.5 10.35 0.5
                C7.80718 0.5 7.19627 1.09844 7.19627 1.8
                V9.92065
                V10.1166
                  H8.54527
                  V9.91983
                  C5.79388 10.8945 4.65867 12.5313 4.99075 14.3403
                  C5.31381 16.0953 6.71883 17.5363 8.47037 17.899
                  C11.3842 18.502 13.9316 16.2991 13.9316 13.4992
                  C13.9316 12.0322 13.013 10.7406 12.1106 9.91983
                  Z
                  "
                  fill={fill} />
            <rect x="4" y="1" width="11" height={height} fill="#fff"></rect>
        <path
            d="
                M11.6106 10.1418
                V9.92065V1.8
                C11.6106 1.06656 11.0562 0.5 10.35 0.5
                C7.80718 0.5 7.19627 1.09844 7.19627 1.8
                V9.92065
                V10.1166
                C6.177 11.2175 5.19665 12.6928 5.48249 14.2506
                M11.7742 10.2905
                C12.6297 11.0687 13.4316 12.2361 13.4316 13.5
                C13.4316 15.9894 11.1689 17.9477 8.57169 17.4102
                M8.57169 17.4102
                C7.01989 17.0888 5.76893 15.8061 5.48253 14.2508
                Z"
        stroke="#868686"/>






            {/*<path fill-rule="evenodd" clip-rule="evenodd"*/}
            {/*      d="*/}
            {/*      M12.1106 9.91983*/}
            {/*      V8.99922*/}
            {/*      C12.1106 8.00473 11.3472 7.19922 10.35 7.19922*/}
            {/*      H8.54527*/}
            {/*      C7.54813 7.19922 6.69627 8.00473 6.69627 8.99922*/}
            {/*      V9.91983*/}
            {/*      C5.79388 10.8945 4.65867 12.5313 4.99075 14.3403*/}
            {/*      C5.31381 16.0953 6.71883 17.5363 8.47037 17.899*/}
            {/*      C11.3842 18.502 13.9316 16.2991 13.9316 13.4992*/}
            {/*      C13.9316 12.0322 13.013 10.7406 12.1106 9.91983Z*/}
            {/*      "*/}
            {/*      fill={color}/>*/}
            {/*<path*/}
            {/*    d="M11.6106 10.1418V9.92065V1.8C11.6106 1.06656 11.0562 0.5 10.35 0.5L8.54527 0.5C7.80718 0.5 7.19627 1.09844 7.19627 1.8V9.92065V10.1166L7.06317 10.2603C6.177 11.2175 5.19665 12.6928 5.48249 14.2506L11.6106 10.1418ZM11.6106 10.1418L11.7742 10.2905M11.6106 10.1418L11.7742 10.2905M11.7742 10.2905C12.6297 11.0687 13.4316 12.2361 13.4316 13.5C13.4316 15.9894 11.1689 17.9477 8.57169 17.4102M11.7742 10.2905L8.57169 17.4102M8.57169 17.4102C7.01989 17.0888 5.76893 15.8061 5.48253 14.2508L8.57169 17.4102Z"*/}
            {/*    stroke="#868686"/>*/}
        </svg>
    )
}


