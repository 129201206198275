import MDBox from "../../components/MDBox";
import React, {useEffect, useState} from "react";
import {Box, Modal, Typography} from "@mui/material";
import MDTypography from "../../components/MDTypography";



export const RewardNotification = ({showModal, handleClose, title="", message="", timer=3000, sx }) => {

  useEffect(() => {
    setTimeout(() => {
        handleClose();
    }, timer);
  }, []);

    return (showModal?
          <Box
            bgcolor={"#6A994E"}
            sx={{
              position: "relative",
              top: "10%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              boxShadow: 24,
              borderRadius: 5,
              display: "flex",
              flexDirection: "column",
              // alignSelf: "center",
              // flex: 1,
              width: "250px",
              overflow: "hidden",
              "&:focus-visible": {
                outline: "transparent",
              },
              ...sx,
            }}
          >
            <Typography variant="h5" color={"white"} textAlign={"center"} pt={1} px={2} pb={1}>
              {title}
            </Typography>
            <MDBox
              display={"flex"}
              bgColor={"white"}
              justifyContent={"center"}
            >
              <MDTypography variant="h6" color={"secondary"} pt={1} px={2} pb={1}>
                {message}
              </MDTypography>
            </MDBox>
          </Box>
      : null
    );
};
