import MDBox from "../../../components/MDBox";
import {Grid, Typography} from "@mui/material";
import {Form, Formik} from "formik";
import FormikInput from "../../../components/FormikInput";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import MDBuckPresetCard from "./MDBuckPresetCard";
import ModalItem from "../../../components/ModalItem";
import React, {useEffect, useRef, useState} from "react";
import * as Yup from "yup";
import {useApi, useDebouncedInput} from "../../../services/helpers";
import DiscussionSuggestionsCard from "./DiscussionSuggestionsCard";
import Box from "@mui/material/Box";
import defaultImg from "../../../assets/images/default-profile.png";
import PaidIcon from "@mui/icons-material/Paid";

const DiscussionModal = ({ meetingId, showModal, handleClose, handleConfirm, selectedItem, renderStudentBalance}) => {
  const formikRef = useRef()
  const api = useApi()
  const imageProfile = selectedItem?.image
  const [filter, setFilter] = useState('all');
  // const title = `To: ${selectedItem?.name}`
  const [interactionsSuggestions, setInteractionsSuggestions] = useState({})
  const [search, setSearchDebounced] = useDebouncedInput({ debounceTime: 300 })

  const handleFilterClick = (filter) => {
    setFilter(filter);
  };

  const filteredSuggestions = () => {
    switch (filter) {
      case 'positive':
        return interactionsSuggestions?.positives || [];
      case 'negative':
        return interactionsSuggestions?.negatives || [];
      case 'all':
      default:
        return [
          ...(interactionsSuggestions?.positives || []),
          ...(interactionsSuggestions?.negatives || [])
        ];
    }
  };

  const getInteractionsSuggestions = (search) => {
    api.getInteractionsSuggestions(meetingId, search).handle({
      onSuccess: (response) => {
        setInteractionsSuggestions(response?.data)
      },
      errorMessage: 'Error getting interactions suggestions ',
    })
  }

  useEffect(() => {
    if (meetingId !== null) {
      getInteractionsSuggestions(search)
    }
  }, [meetingId, search])

  const initialValues = {
    action: "",
    reward: 0,
  }

  const validationSchema = Yup.object().shape({
    action: Yup.string().required(),
    reward: Yup.number().required().max(10000),
  })

  const submitValues = (values) => {
    handleClose()
    setSearchDebounced("")
    formikRef.current.resetForm()
    handleConfirm({...values, student: selectedItem})
  }

  const handlePresetClick = (preset) => {
    formikRef.current.setValues({
      ...formikRef.current.values,
      action: preset[0],
      reward: preset[1],
      student: selectedItem,
    })

    const amountInput = document.querySelector('input[name="reward"]');
    if (amountInput) {
      amountInput.focus();
    }
  }

  return (
    <ModalItem
      scrollable={false}
      open={showModal}
      // height="auto"
      closeOnClickOutside={false}
      heightContainer="100%"
      title={"Discussion"}
      handleClose={handleClose}
      widthContainer={'100%'}
      px={0}
    >
      <MDBox display={"flex"} flexDirection={"column"} width={"100%"}>
        <Grid container justifyContent={'space-between'} >
          <Grid item xs={12} md={5.5} mb={1} >
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnBlur={false}
              onSubmit={(values) => {
                submitValues(values)
                formikRef.current.resetForm()
              }}
              checked={true}
            >
              {({ handleSubmit, isValid, dirty }) => {
                return (
                  <Form style={{ width: "100%" }}>
                    <Grid container >
                      <Grid item xs={12} display={'flex'} flexDirection={'column'} rowGap={1}>
                        <Grid display={'flex'} alignItems={'center'} gap={2} >
                          {imageProfile
                            ? <Box component={"img"} src={imageProfile} alt={"profile_picture"} width={"80px"} height={"80px"}
                                   sx={{objectFit: 'cover'}} borderRadius={"50%"}/>
                            : <Box component={"img"} src={defaultImg} alt={"profile_picture"} width={"80px"} height={"80px"}
                                   borderRadius={"50%"}/>
                          }
                          <MDBox display={"flex"} flexDirection={"column"} width={"100%"}>
                            <Typography color={"secondary"} fontWeight="bold" fontSize={18} >
                              {selectedItem?.name}
                            </Typography>

                            <MDBox display={"flex"}justifyContent={'space-between'}>
                              <Typography color={"secondary"} fontWeight="bold" fontSize={15} >
                                Total amount:
                              </Typography>
                              <MDBox display={"flex"} alignItems={"center"}>
                                <Typography  color={"secondary"}  fontWeight="bold" fontSize={15} sx={{wordBreak: "break-word",}}>
                                  {renderStudentBalance(selectedItem)}
                                </Typography>
                                <PaidIcon color={"primary"}/>
                              </MDBox>

                            </MDBox>
                          </MDBox>
                          {/*<MDTypography color="dark" fontSize={{ xs: 18, md: 24 }} flexDirection="row" overflow="hidden" textOverflow="ellipsis" >*/}
                          {/*  {title}*/}
                          {/*</MDTypography>*/}
                        </Grid>
                        <FormikInput
                          name="action"
                          rows={3}
                          type={"textarea"}
                          label="Reason"
                          onChange={(e) => setSearchDebounced(e.target.value)}
                        />
                        <FormikInput
                          name="reward"
                          type={"number"}
                          label="Amount"
                        />
                      </Grid>
                      <Grid item xs={12} display="flex">
                        <MDButton
                          color="primary"
                          onClick={handleSubmit}
                          disabled={!isValid || !dirty}
                        >
                          {"Apply"}
                        </MDButton>
                      </Grid>
                    </Grid>
                  </Form>
                )
              }}
            </Formik>
          </Grid>
          <Grid item container xs={12} md={6} alignContent={'flex-start'} overflow={'hidden'} justifyContent={'center'} borderRadius={3}  maxHeight={"60vh"}>
            <Grid item justifyContent={'center'} xs={12} columnGap={2} px={1} wrap={'nowrap'} container>
              <MDButton onClick={() => handleFilterClick('all')}>All</MDButton>
              <MDButton onClick={() => handleFilterClick('positive')}>Positive</MDButton>
              <MDButton onClick={() => handleFilterClick('negative')}>Negative</MDButton>
            </Grid>

            <Grid bgcolor={'#E5F9DB'} container justifyContent={'space-between'} overflow={'auto'} spacing={'4px'} mt={2} p={1} borderRadius={3}  height={{xs:'200px', md:'400px', lg:'400px'}}>
              {filteredSuggestions().map((preset, index) => (
                <Grid
                  item xs={12} md={6}
                  maxHeight={'100px'}
                  key={`buck-preset-${preset.id}`}
                >

                  <DiscussionSuggestionsCard
                    title={preset[0]}
                    amount={preset[1]}
                    isAddition={preset[1] >= 0}
                    onClick={() => handlePresetClick(preset)}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </ModalItem>
  )
}

export default DiscussionModal
