import React, { useEffect, useState } from "react";

import PaidIcon from "@mui/icons-material/Paid";
import {
  Box
} from "@mui/material";
import borders from "assets/theme/base/borders";
import DataTable from "components/DataTable";
import MDTypography from "components/MDTypography";
import moment from "moment";
import defaultImg from "../../assets/images/default-profile.png";
import ModalItem from "../../components/ModalItem";
import { useApi } from "../../services/helpers";

const dataTableModel = {
  columns: [
    {Header: "", accessor: "profile_picture", width: "20px", disableOrdering: true, sort: false},
    {Header: "Name", accessor: "name"},
    {Header: "Reward", accessor: "reward"},
    {Header: "Engagement", accessor: "engagement_correct"},
    {Header: "Understanding", accessor: "understanding_correct"},
    {Header: "Time", accessor: "question_time"},
    {Header: "Answer", accessor: "answer", disableOrdering: true, sort: false},
  ],
  rows: [],
};

const renderProfilePicture = (item) => {
  return (
    <Box display={"flex"} alignItems={"center"} gap={1}>
      {item.image
        ? <Box component={"img"} src={item.image} alt={"profile_picture"} width={"40px"}
               sx={{objectFit: 'cover'}} borderRadius={"50%"}/>
        : <Box component={"img"} src={defaultImg} alt={"profile_picture"} width={"40px"}
               borderRadius={"50%"}/>
      }
      {/* {item.name} */}
    </Box>
  )
}

const renderReward = (item) => {
  const label = !!item.reward ? item.reward : "0"
  return (
    <Box>
      {label}<PaidIcon color="primary" sx={{ marginLeft: 1 }} />
    </Box>
  )
}

const renderEngagementCorrect = (item) => {
  const label = item.engagement_correct !== undefined ? item.engagement_correct ? "Correct" : "Incorrect" : "Missed"
  const backgroundColors = {
    "Correct": 'green',
    "Incorrect": 'red',
    "Missed": '#FFC130',
  }
  return (
    <Box flex={1} justifyContent={"center"} 
          sx={{backgroundColor: backgroundColors[label], borderRadius: borders.borderRadius.md,}}>
      <MDTypography flex={1} variant="h5" color="white" fontWeight="medium" sx={{textAlign: "center"}}>
        {label}
      </MDTypography>
    </Box>
  )
}

const renderUnderstandingCorrect = (item) => {
  const label = item.understanding_correct !== undefined ? item.understanding_correct ? "Correct" : "Incorrect" : "-"
  const backgroundColors = {
    "Correct": 'green',
    "Incorrect": 'red',
    "-": '',
  }
  return (
    <Box flex={1} justifyContent={"center"} 
          sx={{backgroundColor: backgroundColors[label], borderRadius: borders.borderRadius.md,}}>
      <MDTypography flex={1} variant="h5" color={`${label === "-" ? "black" : "white"}`} fontWeight="medium" sx={{textAlign: "center"}}>
        {label}
      </MDTypography>
    </Box>
  )
}

const renderTime = (item) => {
  const time = item.question_time ? item.question_time : "0"
  const formatted = moment.utc(time).format('HH:mm:ss');
  return (
    <Box>
      {formatted}
    </Box>
  )
}

const renderTableRow = (item, setAnchorEl, setOpenPopover, setSelectedItem) => {
  const status =  item.status
  item.profile_picture = renderProfilePicture(item)
  item.reward = renderReward(item)
  item.engagement_correct = renderEngagementCorrect(item)
  item.understanding_correct = renderUnderstandingCorrect(item)
  item.question_time = renderTime(item)
  item.answer = item.answer ? item.answer : "-"
  return item
}

export const StudentAnswersModal = ({
  question = {},
  handleClose,
  showModal,
  setShowModal,
  selectedMeeting,
  qeustionId,
  openModal,
}) => {
  const [datatable, setDatatable] = useState({...dataTableModel});
  const api = useApi();

  const getStudentsAnswers = (meetingId, questionId) => {
    api.meetingStudentsAnswers(meetingId, questionId).handle({
      onSuccess: (res) => {
        const data = res.data
        const tmp = {...dataTableModel}
        tmp.rows =  data.map(e => renderTableRow(e)) || []  //setAnchorEl, setOpenPopover, setSelectedItem
        setDatatable(tmp)
      },
      errorMessage: "Error getting students answers",
    });
  }

  useEffect(() => {
    if(question?.question) {
      getStudentsAnswers(question.meeting_id, question.question);
    }    
  }, [question]);

  return (
    <ModalItem
      scrollable={false}
      open={showModal}
      closeOnClickOutside={false}
      height={{ xs: "90vw", md: "75vh" }}
      width={{ xs: "80vw", md: "80vw" }}
      title={question?.prompt || ""}
      handleClose={() => setShowModal(false)}
    >
      <DataTable 
        table={datatable}
      />
    </ModalItem>
  );
};
