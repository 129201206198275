import React from "react";
import MDBox from "components/MDBox";
import moment from "moment";
import {RealtimeContext} from "services/streaming/provider";
import MDTypography from "components/MDTypography";
import {useParams , useSearchParams} from "react-router-dom";
import { Grid, CircularProgress } from "@mui/material";
import MeetingCard from "../lessons/MeetingCard";
import {Form, FormikProvider, useFormik} from "formik";
import FormikInput from "../../components/FormikInput";
import {StudentsEngagementBars, StudentUnderstandingBars, StudentsEngagement, StudentUnderstanding} from "./charts";
import LessonCourseHome from "./LessonCourseHome";
import defaultImg from "../../assets/images/default-profile.png";
import Box from "@mui/material/Box";
import {useApi} from "../../services/helpers";
import {useState, useEffect} from "react";
import {EmptyResponse} from "../../components/EmptyResponse";

const currentDate = new Date();
const startDateInit = new Date(currentDate);
startDateInit.setDate(currentDate.getDate() - 7);
const endDateInit = currentDate


const TeacherCourseHome = () => {
  const api = useApi();
  const { startCapture } = React.useContext(RealtimeContext);
  const [searchParams] = useSearchParams();
  const instantMeetingId = searchParams.get('instantMeetingId');
  const {courseId} = useParams()
  const [lessons, setLessons] = useState(null)
  const [meetings, setMeetings] = useState([])
  const [students, setStudents] = useState([])
  const [studentStatistics, setStudentStatistics] = useState([])
  const [studentStatisticsLoading, setStudentStatisticsLoading] = useState(false)
  const [generalStatistics, setGeneralStatistics] = useState([])
  const [generalStatisticsLoading, setGeneralStatisticsLoading] = useState(false)   

  const getLessonsByCourse = () => {
    api.getLessonsByCourse(courseId).handle({
      onSuccess: (res) => {
        setLessons(res.data.results)
      },
      errorMessage: "Error getting lessons",
    });
  }

  const getStudentsOfCourse = () => {
    api.getStudentsOfCourse(courseId).handle({
      onSuccess: (res) => {
        setStudents(res.data)
      },
      errorMessage: "Error getting students",
    });
  }

  const getCourseNearestMeetings = (instantMeetingId) => {
    api.getCourseNearestMeetings(courseId, instantMeetingId).handle({
      onSuccess: (res) => {
        setMeetings(res.data)
      },
      errorMessage: "Error getting meetings",
    });
  }

  const getCourseGeneralStatistics = (courseId, data) => {
    setGeneralStatisticsLoading(true)
    api.getCourseGeneralStatistics(courseId, data).handle({
      onSuccess: (res) => {
        setGeneralStatistics(res.data)
      },
      errorMessage: "Error getting general statistics",
      onFinally: () => setGeneralStatisticsLoading(false)
    });
  }

  const getCourseStudentStatistics = (courseId, studentId, data) => {
    setStudentStatisticsLoading(true)
    api.getCourseStudentStatistics(courseId, studentId, data).handle({
      onSuccess: (response) => {
        setStudentStatistics(response.data)
      },
      errorMessage: "Error getting student statistics",
      onFinally: () => setStudentStatisticsLoading(false)
    });
  }

  useEffect(() => {
    getLessonsByCourse()
    getCourseNearestMeetings(instantMeetingId)
    getStudentsOfCourse()   
  }, []);

  useEffect(() => {
    const parsedInstantMeetingId = Number(instantMeetingId)
    if (instantMeetingId && meetings.length > 0) {
      const instantMeetingExists = meetings.find((meeting, midx) =>  meeting.id === parsedInstantMeetingId)
      if (instantMeetingExists) {
        startCapture(parsedInstantMeetingId)
      }
    }
  }, 
  [meetings])

  const initialValues = {
    student: null,
    from_date: moment(startDateInit).format("YYYY-MM-DD"),
    to_date: moment(endDateInit).format("YYYY-MM-DD"),
    from_date_global: moment(startDateInit).format("YYYY-MM-DD"),
    to_date_global: moment(endDateInit).format("YYYY-MM-DD"),
  };

  const formik = useFormik({
    initialValues: initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      const { student = {} , from_date_global, to_date_global, ...data } = values
      if (student?.student_id) {
        getCourseStudentStatistics(courseId, student?.student_id, data)
      } else {
        getCourseGeneralStatistics(courseId, {from_date: from_date_global, to_date: to_date_global})
      }
      
    },
  })

  useEffect(() => {
    // if student
    if (formik.values?.student !== null && formik.values?.from_date !== null && formik.values?.to_date !== null) {
      formik.submitForm();
    } 
    // if global
    if (formik.values?.from_date_global !== null && formik.values?.to_date_global !== null) {
      formik.submitForm();
    }
  }, [formik.values]);

  return (
    <Grid container direction={'column'} width={"85%"}>
      <Grid item container>
        <Grid item xs={12} py={1} gap={1} borderBottom="1px solid rgba(0, 0, 0, 0.25)">

          <MDTypography fontSize={{xs:'24px', md:"30px"}} flex={1} variant="h3" color="primary" fontWeight="regular">
            Next meetings
          </MDTypography>

          <Grid item container direction={'row'} xs={12} my={2}>
            {meetings?.length > 0?
                meetings
                .map(meeting => (
                  <Grid key={meeting.id} item xs={12} md={6} xl={meetings.length > 2 ? 4: 6}>
                    <MeetingCard meeting={meeting}/>
                  </Grid>
                ))
            : <Grid item xs={6} md={12} >
                <EmptyResponse text={"No meetings found"} height={"5vh"} isRow={true}/>
              </Grid>
            }
          </Grid>

        </Grid>

        <Grid item container xs={12} md={12}>
          <FormikProvider value={formik} >
            <Form style={{display:'flex', flexDirection:'column', width:'100vw', margin:"0 10px 0 10px"}}>
              <Grid  item container direction={'row'}
                xs={12} md={12}
                mt={3}
                borderBottom="1px solid rgba(0, 0, 0, 0.25)"
                justifyContent='space-between'
                alignItems={'center'}
                alignContent={'center'}
              >
                <Grid item xs={12} md={8} >
                  <MDTypography flex={1} fontSize={{xs:'24px', md:"30px"}} variant="h3" color="primary" fontWeight="regular" mb={'10px'}>
                    Student statistics
                  </MDTypography>
                </Grid>
                <Grid item xs={11} md={3} alignItems={'center'} minWidth={'250px'} >
                  <FormikInput
                    type={"autocomplete"}
                    placeholder={"Filter Student"}
                    fieldName={"student"}
                    label={"Filter Student"}
                    value={formik.values.student}
                    options={students}
                    accessKey={"name"}
                    onChange={(value) => {
                      formik.setFieldValue('student', value)
                    }}
                  />
                </Grid>
              </Grid>
              <Grid justifyContent={"space-between"} mt={1}>
                {formik.values.student ?
                  // =============== START STUDENT =============== //
                  <Grid container direction="column">
                    <Grid item alignContent="center" container xs={12} alignItems="center" borderLeft="5px solid #6A994E" height="40px" mb={3}>
                      <Grid container item alignItems="center" flexDirection="row" display="flex" xs={6}>
                        {formik.values.student.image ? (
                          <Box component="img" src={formik.values.student.image} alt="profile_picture" width="40px" height="40px" sx={{ objectFit: 'cover' }} borderRadius="50%" ml={1} />
                        ) : (
                          <Box component="img" src={defaultImg} alt="profile_picture" width="40px" height="40px" borderRadius="50%" ml={1} />
                        )}
                        <MDTypography flex={1} color="black" pl={1} fontSize={{ xs: "14px", md: "18px" }} sx={{ wordBreak: 'break-all', lineClamp: 2, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {formik.values.student.name}
                        </MDTypography>
                      </Grid>
                      {/* ======== START DATE FILTERS ======== */}
                      <Grid item xs={6}>
                        <Grid container item display="flex">
                          <Grid container spacing={1}>
                            <Grid item xs={12} lg={6}>
                              <FormikInput
                                type="date"
                                name="from_date"
                              />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <FormikInput
                                type="date"
                                name="to_date"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* ========= END DATE FILTERS ========= */}
                    </Grid>
                  </Grid>
                  // =============== END STUDENT =============== //
                :
                // =============== START GLOBAL TITLE =============== //
                <Grid item display={"flex"} alignItems={"center"}  borderLeft={"5px solid #6A994E"} mb={3} height={"40px"} >
                  <MDTypography flex={1} ml={1} fontSize={{xs:'16px', md:"20px"}} variant="h5" color="primary" fontWeight="regular">
                    Global statistics
                  </MDTypography>
                   {/* ======== START DATE FILTERS ======== */}
                   <MDBox>
                    <Grid container item display="flex">
                      <Grid container spacing={1}>
                        <Grid item xs={4} lg={6}>
                          <FormikInput
                            type="date"
                            name="from_date_global"
                          />
                        </Grid>
                        <Grid item xs={4} lg={6}>
                          <FormikInput
                            type="date"
                            name="to_date_global"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </MDBox>
                  {/* ========= END DATE FILTERS ========= */}
                </Grid>
                // =============== END GLOBAL TITLE =============== //
                }
              </Grid>
              {/* =============== START STATISTICS =============== */}
              <Grid item container xs={12}>
                {/* ===== STUDENT STATISTICS ===== */}
                {formik.values.student ?
                  <MDBox display={"flex"} gap={2} width={"100%"} flexDirection={{xs: "column", lg: "row"}}>
                    {!studentStatisticsLoading && <>
                      <MDBox flex={1}>
                        <StudentsEngagement pb={1} meetings={studentStatistics}/>
                      </MDBox>
                      <MDBox  flex={1}>
                        <StudentUnderstanding pb={1} meetings={studentStatistics}/>
                      </MDBox>
                    </>}
                    {!!studentStatisticsLoading && (
                      <MDBox display={"flex"} width={"100%"} justifyContent={"center"}>
                        <CircularProgress color="primary" size={32} />
                      </MDBox>
                    )}
                  </MDBox>
                : 
                  <MDBox display={"flex"} gap={2} width={"100%"} flexDirection={{xs: "column", lg: "row"}}>
                    {/* ===== GENERAL STATISTICS ===== */}
                    {!generalStatisticsLoading && <>
                      <MDBox flex={1}>
                        <StudentsEngagementBars pb={1} statsData={generalStatistics}/>
                      </MDBox>
                      <MDBox flex={1}>
                        <StudentUnderstandingBars pb={1} statsData={generalStatistics}/>
                      </MDBox>
                    </>}
                    {!!generalStatisticsLoading && (
                      <MDBox display={"flex"} width={"100%"} justifyContent={"center"}>
                        <CircularProgress color="primary" size={32} />
                      </MDBox>
                    )}
                  </MDBox>
                }
              </Grid>
              {/* =============== END STATISTICS =============== */}
            </Form>
          </FormikProvider>
        </Grid>
        {/* =============== START LESSONS =============== */}
        <Grid item container mt={3} display={"flex"} flexDirection={"column"}>
          <MDTypography flex={1} fontSize={{xs:'24px', md:"30px"}} variant="h3" color="primary" fontWeight="regular" borderBottom="1px solid rgba(0, 0, 0, 0.25)">
            Lessons
          </MDTypography>
          {lessons?.length > 0?
            lessons.map(lesson => <LessonCourseHome key={lesson.id} lesson={lesson}/>)
          :
            <EmptyResponse text={"No lessons found"} height={"5vh"}/>
          }
        </Grid>
        {/* ================ END LESSONS ================ */}
      </Grid>
    </Grid>
  );
};

export default TeacherCourseHome;